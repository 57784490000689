import * as Yup from 'yup';

// ----------------------------------------------------------------------

export const BlingFormSchema = Yup.object().shape({
  activatedGroup: Yup.array(),
});

export type TBlingConfigFormSchema = Yup.InferType<typeof BlingFormSchema>;

export const blingConfigFormSchemaDefaultValues: TBlingConfigFormSchema = {
  activatedGroup: [],
};
