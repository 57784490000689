import Stack from '@mui/material/Stack';

import { RHFAutocomplete } from 'src/components/hook-form';

import { integrationCardOptions } from '../utils/card-intergration';

// ----------------------------------------------------------------------

export default function ConfigIntegrationsToolbar() {
  return (
    <Stack
      spacing={2}
      alignItems={{ xs: 'flex-end', md: 'center' }}
      direction={{
        xs: 'column',
        md: 'row',
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
        <RHFAutocomplete
          name="cardIntegration"
          label="Filtro pelo tipo de Integração"
          multiple
          options={integrationCardOptions}
          sx={{
            width: { xs: 1, sm: 335 },
          }}
        />
      </Stack>
    </Stack>
  );
}
