import * as Yup from 'yup';

// ----------------------------------------------------------------------

export const DapicFormSchema = Yup.object().shape({
  integrationToken: Yup.string().required('Preenchimento Obrigatório'),
  companyId: Yup.string().required('Preenchimento Obrigatório'),
  havePdv: Yup.boolean().nullable(),
  haveInvoice: Yup.boolean().nullable(),
});

export type TDapicConfigFormSchema = Yup.InferType<typeof DapicFormSchema>;

export const dapicConfigFormSchemaDefaultValues: TDapicConfigFormSchema = {
  integrationToken: '',
  companyId: '',
  haveInvoice: null,
  havePdv: null,
};
