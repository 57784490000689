import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useBoolean } from 'src/hooks/use-boolean';

import {
  BlingStoreIntegrationType,
  useBlingIntegrationMutation,
  BlingIntegrationMutationVariables,
} from 'src/graphql/generated';

import Image from 'src/components/image';
import { RHFMultiCheckbox } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { BlingFormSchema, blingConfigFormSchemaDefaultValues } from '../schema/bling-form-schema';

// ----------------------------------------------------------------------

type TCurrentData = Pick<BlingStoreIntegrationType, 'connected'>;

type Props = {
  open: boolean;
  onClose: () => void;
  currentData?: TCurrentData | null;
  code?: string | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
};

// ----------------------------------------------------------------------

export function ConfigBlingModal({ open, onClose, currentData, code, refetch }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();
  const [setBlingStoreIntegration] = useBlingIntegrationMutation();
  const enableForm = useBoolean();

  const methods = useForm({
    resolver: yupResolver(BlingFormSchema),
    defaultValues: blingConfigFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClose = () => {
    if (!currentData) enableForm.onTrue();
    else enableForm.onFalse();

    onClose();
    reset();
  };

  const onSubmit = handleSubmit(async (data) => {
    let label = 'Cadastro efetuado com sucesso!';

    const variables: BlingIntegrationMutationVariables = {
      code,
    };

    if (currentData) label = 'Dados atualizados com sucesso!';

    try {
      await setBlingStoreIntegration({ variables });

      enqueueSnackbar(label, { variant: 'success' });

      await refetch();

      enableForm.onFalse();
      onClose();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (!currentData) return;

      reset({
        activatedGroup: [currentData.connected && 'activated'].filter(Boolean),
      });
    };

    updateFields();
  }, [currentData, reset]);

  return (
    <FormProvider methods={methods}>
      <Dialog open={open} onClose={onClose}>
        {!code ? (
          <>
            <Stack alignItems="center" pt={3}>
              <Image src="/assets/images/ic-duotone.png" width={60} />
            </Stack>
            <DialogTitle textAlign="center" fontSize={20}>
              Integração Bling
            </DialogTitle>

            <DialogContent
              sx={{
                pb: 3,
                minWidth: 400,
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                color: 'text.secondary',
              }}
            >
              Para integrar sua conta ao Bling, basta acessar o link <br />
              de convite diretamente pelo painel da sua conta no <br />
              Bling. Caso precise de ajuda, acesse nossa Central de <br />
              Ajuda, onde explicamos detalhadamente como <br />
              localizar e conectar nosso aplicativo à sua conta Bling.
            </DialogContent>
          </>
        ) : (
          <>
            <DialogTitle textAlign="center" fontSize={20}>
              Integração Bling
            </DialogTitle>

            <DialogContent
              sx={{
                minWidth: { xs: 400, md: 400 },
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <RHFMultiCheckbox
                name="activatedGroup"
                options={[{ value: 'activated', label: 'Ativar integração Bling' }]}
                disabled={!enableForm}
              />
            </DialogContent>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <DialogActions>
              <Button variant="outlined" color="inherit" onClick={handleClose}>
                Fechar
              </Button>
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isSubmitting}
                onClick={onSubmit}
              >
                Salvar
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Dialog>
    </FormProvider>
  );
}
