/* eslint-disable react-hooks/exhaustive-deps */
import { find } from 'lodash';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { LoadingButton } from '@mui/lab';
import { Stack, Button } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField, RHFAutocomplete, RHFMaskTextField } from 'src/components/hook-form';

import {
  ConfigFormSchema,
  CURRENCY_TYPES_VALUE,
  configFormSchemaDefaultValues,
} from './config-form-schema';
import {
  useGetStoreQuery,
  StoreCurrencyType,
  useStoreUpdateMutation,
  StoreUpdateMutationVariables,
} from '../../graphql/generated';

// ----------------------------------------------------------------------

type Props = {
  enableForm?: boolean;
  onEnableForm: () => void;
  onDisableForm: () => void;
};

// ----------------------------------------------------------------------

export default function ConfigForm({ enableForm = false, onEnableForm, onDisableForm }: Props) {
  const methods = useForm({
    resolver: yupResolver(ConfigFormSchema),
    defaultValues: configFormSchemaDefaultValues,
  });
  const { data: store, refetch } = useGetStoreQuery();
  const [updateStore] = useStoreUpdateMutation();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, reset, formState } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const variables: StoreUpdateMutationVariables = {
      input: {
        email: data.email,
        name: data.name,
        url: data.site,
        telephone: data.phone,
        currencyType: data.currencyType?.value as StoreCurrencyType,
      },
    };

    try {
      await updateStore({ variables });
      window.localStorage.setItem('store_currency_type', data.currencyType?.value!);

      enqueueSnackbar('Dados atualizados!', { variant: 'success' });

      await refetch();

      onDisableForm();
    } catch (error) {
      console.error(error);

      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o usuário!', { variant: 'error' });
    }
  });

  const updateFields = () => {
    if (store?.getStore) {
      const { email, cnpj, name, telephone, url, currencyType, profileImage } = store.getStore;
      const foundValueType = find(CURRENCY_TYPES_VALUE, ['value', currencyType]);

      reset({
        name,
        email,
        taxDocument: cnpj,
        phone: telephone,
        site: url!,
        profileImage,
        currencyType: foundValueType,
      });
    }
  };

  useEffect(() => {
    updateFields();
  }, [store?.getStore]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ marginBottom: '1rem' }}
            >
              <RHFTextField disabled={!enableForm} name="name" label="Nome da Loja" />
              <RHFTextField disabled={!enableForm} name="email" label="Email da Loja" />
              <RHFMaskTextField
                disabled={!enableForm}
                name="phone"
                label="Telefone/Celular"
                formatMask="TELEFONE_FIXO_CELULAR"
              />
              <RHFMaskTextField
                disabled
                name="taxDocument"
                label="Documento"
                formatMask="FORMAT_IDENTIFIER"
              />
              <RHFTextField disabled={!enableForm} name="site" label="Site da Loja" />
              <RHFAutocomplete
                disabled={!enableForm}
                name="currencyType"
                label="Moeda Padrão"
                options={CURRENCY_TYPES_VALUE}
              />
              <RHFTextField disabled={!enableForm} name="profileImage" label="URL da Imagem de Perfil" />
            </Box>

            {enableForm && (
              <Stack
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                sx={{ mt: 3, gap: 1 }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    onDisableForm();
                    updateFields();
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={formState.isSubmitting}
                >
                  Salvar
                </LoadingButton>
              </Stack>
            )}

            {!enableForm && (
              <Stack
                display="flex"
                flexDirection="row"
                gap="1rem"
                justifyContent="flex-end"
                sx={{ mt: 3 }}
              >
                <Button variant="contained" color="primary" onClick={onEnableForm}>
                  Editar
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
