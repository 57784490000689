import * as Yup from 'yup';

export const emailProviderFormSchema = Yup.object().shape({
  emailToSend: Yup.string(),
  isActive: Yup.array(),
});

export type TZApiConfigFormSchema = Yup.InferType<typeof emailProviderFormSchema>;

export const emailProviderFormSchemaDefaultValues: TZApiConfigFormSchema = {
  emailToSend: '',
  isActive: []
};
