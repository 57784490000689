import * as Yup from 'yup';

// ----------------------------------------------------------------------

export const IntegrationFormSchema = Yup.object().shape({
  cardIntegration: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    })
  ),
});

export type TIntegrationFormSchema = Yup.InferType<typeof IntegrationFormSchema>;

export const integrationFormSchemaDefaultValues: TIntegrationFormSchema = {
  cardIntegration: [],
};
