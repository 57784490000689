// ----------------------------------------------------------------------

// WhatsApp
// Z-API
// SMS
// Infobip

export enum IntegrationCard {
  SYSTEM = 'SYSTEM',
  COMMUNICATION = 'COMMUNICATION'
}

export const integrationCardOptions = [
  {
    label: 'Sistema',
    value: IntegrationCard.SYSTEM
  },
  {
    label: 'Comunicação',
    value: IntegrationCard.COMMUNICATION
  },
];

export const listCardIntegration = [
  {
    label: 'Tray',
    value: 'tray'
  },
  {
    label: 'Omie',
    value: 'omie'
  },
  {
    label: 'Bling',
    value: 'bling'
  },
  {
    label: 'Whatsapp',
    value: 'whatsapp'
  },
  {
    label: 'Zapi',
    value: 'zapi'
  },
  {
    label: 'Infobip',
    value: 'smsInfobip'
  },
  {
    label: 'Dapic',
    value: 'dapic'
  },
];
