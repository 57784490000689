import { useLocales as getLocales } from 'src/locales';

import { fCurrency } from './format-number';

export const Masks = {
  FORMAT_IDENTIFIER,
  TELEFONE_FIXO_CELULAR: (valueToFormat: string) => maskPhone(valueToFormat),
  MONEY(valueToFormat: string) {
    const decimalValueToFormat = parseFloat(valueToFormat) * 0.01;
    return fCurrency(decimalValueToFormat);
  },
  PORCENTAGEM(valueToFormat: string) {
    const decimalValueToFormat = (parseFloat(valueToFormat) / 100).toFixed(2);
    return `${decimalValueToFormat}%`;
  },
  INTEGERS: (valueToFormat: string) => valueToFormat.replace(/\D/g, ''),
};

export function formatedToNumber(formatedValue: string) {
  const numberValue = formatedValue.replace(/\D/g, '');
  return Number(`${numberValue.slice(0, numberValue.length - 2)}.${numberValue.slice(-2)}`);
}

export function maskPhone(value: string) {
  const { currentLang } = getLocales();
  const cleanedValue = value.replace(/\D/g, '');

  if (currentLang.value === 'pt-BR') {
    const brMatch = cleanedValue.match(/(\d{0,2})(\d{0,5})(\d{0,4})/);
    if (!brMatch) return '';
    const [, ddd, part1, part2] = brMatch;
    return `(${ddd}) ${part1}${part2 ? `-${part2}` : ''}`;
  }

  if (currentLang.value === 'en') {
    const usMatch = cleanedValue.match(/^(1?)(\d{0,3})(\d{0,3})(\d{0,4})/);
    if (!usMatch) return '';
    const [, , areaCode, part1, part2] = usMatch;
    return `(${areaCode}) ${part1}${part2 ? `-${part2}` : ''}`;
  }

  return value;
}

export function FORMAT_IDENTIFIER(valueToFormat: string) {
  const cleanedValue = valueToFormat.replace(/\D/g, '');
  const { currentLang } = getLocales(); // Obtenção da língua atual

  // Para o idioma português brasileiro
  if (currentLang.value === 'pt-BR') {
    // Se o valor tem 11 dígitos ou menos, formatar como CPF
    if (cleanedValue.length <= 11) {
      return cleanedValue
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    }
    // Caso contrário, formatar como CNPJ
    return cleanedValue
      .replace(/^(\d{2})(\d)/, '$1.$2')
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
      .replace(/\.(\d{3})(\d)/, '.$1/$2')
      .replace(/(\d{4})(\d{1,2})$/, '$1-$2');
  }

  // Para o idioma inglês (EUA)
  if (currentLang.value === 'en') {
    // ITIN (9XX-XX-XXXX) começa com 9 e segue o formato do SSN
    if (cleanedValue.startsWith('9')) {
      return cleanedValue.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
    }
    // EIN (XX-XXXXXXX) se o valor começar com números diferentes de 9 e tiver 9 dígitos
    if (cleanedValue[0] !== '9' && cleanedValue.length === 9) {
      return cleanedValue.replace(/(\d{2})(\d{7})/, '$1-$2');
    }
    // SSN (XXX-XX-XXXX) para outros casos de 9 dígitos que não são ITIN ou EIN
    return cleanedValue.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }

  // Se o idioma não for reconhecido, retornar o valor sem formatação
  return valueToFormat;
}
