import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

import { TCardIntegration } from '../types/card-integration';
import { IntegrationCard } from '../utils/card-intergration';

// ----------------------------------------------------------------------

export function CardIntegration({
  logoURL,
  heading,
  subheading,
  type,
  isConnected,
  hasData,
  onAction,
}: TCardIntegration) {
  return (
    <Stack component={Card}>
      <Stack sx={{ p: 3, pb: 2, display: 'inline-block', }} flex="1">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Image
            src={logoURL}
            height="48px"
            width="48px"
            loading="lazy"
            sx={{
              '& span.component-image-wrapper img': {
                objectFit: 'contain',
              },
            }}
          />
          <Box
            sx={{
              display: 'inline-block',
              px: 1,
              py: 0.75,
              borderRadius: 1,
              backgroundColor: 'rgba(145, 158, 171, 0.16)',
              fontSize: 14,
              fontWeight: 600,
              lineHeight: 1,
              color: 'text.secondary',
              mb: 1
            }}
          >
            {type === IntegrationCard.SYSTEM ? 'Sistema' : 'Comunicação'}
          </Box>
        </Stack>

        <ListItemText
          sx={{ mb: 1 }}
          primary={heading}
          secondary={subheading}
          primaryTypographyProps={{
            typography: 'subtitle1',
            mt:1
          }}
          secondaryTypographyProps={{
            mt: 1,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />

        {isConnected ? (
          <Stack
            spacing={0.5}
            direction="row"
            alignItems="center"
            sx={{ color: 'success.main', typography: 'caption' }}
          >
            <Iconify width={16} icon="eva:checkmark-fill" />
            Conectado
          </Stack>
        ) : (
          <Stack
            spacing={0.5}
            direction="row"
            alignItems="center"
            sx={{ color: 'error.main', typography: 'caption' }}
          >
            <Iconify width={16} icon="eva:close-fill" />
            Não Conectado
          </Stack>
        )}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack alignItems="flex-end" sx={{ p: 3 }}>
        {hasData ? (
          <Button variant="contained" color="primary" onClick={onAction}>
            Editar
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={onAction}>
            Integrar
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
