import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useBoolean } from 'src/hooks/use-boolean';

import {
  useSetEmailProviderMutation,
  SetEmailProviderMutationVariables,
  EmailProviderStoreIntegrationType,
} from 'src/graphql/generated';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFTextField, RHFMultiCheckbox } from 'src/components/hook-form';

import {
  emailProviderFormSchema,
  emailProviderFormSchemaDefaultValues,
} from '../emailProvider-form-schema';

// ----------------------------------------------------------------------

type TAttributes = 'emailToSend' | 'isActive';

type TCurrentData = Pick<EmailProviderStoreIntegrationType, TAttributes>;

type Props = {
  open: boolean;
  onClose: () => void;
  // currentData?: TCurrentData | null;
  currentData?: TCurrentData | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
};

// ----------------------------------------------------------------------

export function EmailProviderModal({ open, onClose, currentData, refetch }: Props) {
  const enableForm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [saveEmailProvider] = useSetEmailProviderMutation();

  const methods = useForm({
    resolver: yupResolver(emailProviderFormSchema),
    defaultValues: emailProviderFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClose = () => {
    if (!currentData) enableForm.onTrue();
    else enableForm.onFalse();

    onClose();
    reset();
  };

  const onSubmit = handleSubmit(async (data) => {
    let label = 'Cadastro efetuado com sucesso!';

    const variables: SetEmailProviderMutationVariables = {
      input: {
        emailToSend: data.emailToSend,
        isActive: data.isActive?.includes('activated'),
      },
    };

    if (currentData) label = 'Dados atualizados com sucesso!';

    try {
      await saveEmailProvider({ variables });

      enqueueSnackbar(label, { variant: 'success' });

      await refetch();

      enableForm.onFalse();
      onClose();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (!currentData) return;

      reset({
        emailToSend: currentData?.emailToSend,
        isActive: [currentData.isActive && 'activated'].filter(Boolean),
      });
    };

    updateFields();
  }, [currentData, reset]);

  useEffect(() => {
    if (!currentData) {
      enableForm.onTrue();

      return;
    }

    enableForm.onFalse();
    // eslint-disable-next-line
  }, [currentData]);

  return (
    <FormProvider methods={methods}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: { xs: '90%', md: 400 },
          },
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          Integração com E-mail
        </DialogTitle>

        <DialogContent
          sx={{
            maxWidth: { xs: 400, md: 400 },
            minHeight: 120,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            mt: 3,
          }}
        >
          <RHFTextField name="emailToSend" label="E-mail" disabled={!enableForm.value} />

          <RHFMultiCheckbox
            name="isActive"
            options={[{ value: 'activated', label: 'Ativar integração' }]}
            disabled={!enableForm.value}
          />
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Fechar
          </Button>
          {enableForm.value && (
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Salvar
            </LoadingButton>
          )}
          {!enableForm.value && (
            <Button variant="contained" color="primary" onClick={enableForm.onTrue}>
              Editar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
