import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  ExpectedErrorType: { input: any; output: any; }
  GenericScalar: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type AcceptTermsMutation = {
  __typename?: 'AcceptTermsMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum AcceptationTermTermType {
  /** Política de Privacidade */
  PrivacyPolicy = 'PRIVACY_POLICY',
  /** Termo de Uso */
  TermsOfUse = 'TERMS_OF_USE'
}

export type AcceptationTermType = Node & {
  __typename?: 'AcceptationTermType';
  content: Scalars['String']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  termType: AcceptationTermTermType;
  useracceptationSet: Array<UserAcceptationType>;
};

export type AgeColumnChartReturn = {
  __typename?: 'AgeColumnChartReturn';
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<AgeColumnChartSeries>>>;
};

export type AgeColumnChartSeries = {
  __typename?: 'AgeColumnChartSeries';
  data?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type ArchiveAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/**
 * Archive account and revoke refresh tokens.
 *
 * User must be verified and confirm password.
 */
export type ArchiveAccountPayload = {
  __typename?: 'ArchiveAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type BlingStoreIntegrationType = {
  __typename?: 'BlingStoreIntegrationType';
  accessToken: Scalars['String']['output'];
  activated: Scalars['Boolean']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  integrationId: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  refreshToken: Scalars['String']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

export type BoardInput = {
  colaborators: Array<InputMaybe<Scalars['ID']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
};

export type BoardNode = Node & {
  __typename?: 'BoardNode';
  colaborators: CustomUserNodeConnection;
  columnstatusSet: ColumnStatusNodeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: CustomUserNode;
  pk: Scalars['Int']['output'];
};


export type BoardNodeColaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status_Archived?: InputMaybe<Scalars['Boolean']['input']>;
  status_SecondaryEmail?: InputMaybe<Scalars['String']['input']>;
  status_Verified?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  username_Icontains?: InputMaybe<Scalars['String']['input']>;
  username_Istartswith?: InputMaybe<Scalars['String']['input']>;
};


export type BoardNodeColumnstatusSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  board?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type BoardNodeConnection = {
  __typename?: 'BoardNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BoardNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `BoardNode` and its cursor. */
export type BoardNodeEdge = {
  __typename?: 'BoardNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<BoardNode>;
};

export type BoardType = {
  __typename?: 'BoardType';
  colaborators: CustomUserNodeConnection;
  columnstatusSet: ColumnStatusNodeConnection;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: CustomUserNode;
  pk: Scalars['Int']['output'];
};


export type BoardTypeColaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status_Archived?: InputMaybe<Scalars['Boolean']['input']>;
  status_SecondaryEmail?: InputMaybe<Scalars['String']['input']>;
  status_Verified?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  username_Icontains?: InputMaybe<Scalars['String']['input']>;
  username_Istartswith?: InputMaybe<Scalars['String']['input']>;
};


export type BoardTypeColumnstatusSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  board?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignRelayType = Node & {
  __typename?: 'CampaignRelayType';
  buttonLabel?: Maybe<Scalars['String']['output']>;
  buttonUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  daysToTrigger: Scalars['Int']['output'];
  footer?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  messageEmail: Scalars['String']['output'];
  messageSms?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  store?: Maybe<StoreType>;
  subject: Scalars['String']['output'];
  tag: CampaignTag;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  viaEmail: Scalars['Boolean']['output'];
  viaSms: Scalars['Boolean']['output'];
  viaWhatsapp: Scalars['Boolean']['output'];
  whatsappTemplate?: Maybe<WhatsappTemplateType>;
};

export type CampaignRelayTypeConnection = {
  __typename?: 'CampaignRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CampaignRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CampaignRelayType` and its cursor. */
export type CampaignRelayTypeEdge = {
  __typename?: 'CampaignRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CampaignRelayType>;
};

/** An enumeration. */
export enum CampaignTag {
  /** Dias após uma venda */
  DaysAfterPurchase = 'DAYS_AFTER_PURCHASE',
  /** Giftback cancelado */
  GiftbackCancelled = 'GIFTBACK_CANCELLED',
  /** Giftback gerado */
  GiftbackCreated = 'GIFTBACK_CREATED',
  /** Giftback expirará logo */
  GiftbackExpireSoon = 'GIFTBACK_EXPIRE_SOON',
  /** Giftback expira hoje */
  GiftbackExpireToday = 'GIFTBACK_EXPIRE_TODAY',
  /** Parabéns */
  HappyBirthday = 'HAPPY_BIRTHDAY',
  /** Cliente ausente */
  MissingCustomer = 'MISSING_CUSTOMER',
  /** Bem-vindo */
  Welcome = 'WELCOME'
}

export type CampaignType = {
  __typename?: 'CampaignType';
  buttonLabel?: Maybe<Scalars['String']['output']>;
  buttonUrl?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  daysToTrigger: Scalars['Int']['output'];
  footer?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  messageEmail: Scalars['String']['output'];
  messageSms?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  store?: Maybe<StoreType>;
  subject: Scalars['String']['output'];
  tag: CampaignTag;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  viaEmail: Scalars['Boolean']['output'];
  viaSms: Scalars['Boolean']['output'];
  viaWhatsapp: Scalars['Boolean']['output'];
  whatsappTemplate?: Maybe<WhatsappTemplateType>;
};

export type CancelPurchaseInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  justification?: InputMaybe<Scalars['String']['input']>;
  purchaseId?: InputMaybe<Scalars['ID']['input']>;
};

export type CancelPurchaseMutation = {
  __typename?: 'CancelPurchaseMutation';
  purchase?: Maybe<PurchaseType>;
};

/** An enumeration. */
export enum CategoryImportedFrom {
  /** Bling */
  Bling = 'BLING',
  /** Giftback criado separadamente */
  CreateOnlyGiftback = 'CREATE_ONLY_GIFTBACK',
  /** Dapic */
  Dapic = 'DAPIC',
  /** Moego */
  Moego = 'MOEGO',
  /** Omie */
  Omie = 'OMIE',
  /** PDV */
  Pdv = 'PDV',
  /** SGI */
  Sgi = 'SGI',
  /** Planilha */
  Spreadsheet = 'SPREADSHEET',
  /** Sistema Zeki */
  System = 'SYSTEM',
  /** Totem */
  Totem = 'TOTEM',
  /** Tray */
  Tray = 'TRAY',
  /** Webhook */
  Webhook = 'WEBHOOK'
}

export type CategoryRelayType = Node & {
  __typename?: 'CategoryRelayType';
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importedFrom: CategoryImportedFrom;
  integrationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  productSet: ProductRelayTypeConnection;
  store: StoreType;
  subcategories: CategoryRelayTypeConnection;
  subcategory?: Maybe<CategoryRelayType>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CategoryRelayTypeProductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoriesNames?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
};


export type CategoryRelayTypeSubcategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryRelayTypeConnection = {
  __typename?: 'CategoryRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CategoryRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CategoryRelayType` and its cursor. */
export type CategoryRelayTypeEdge = {
  __typename?: 'CategoryRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CategoryRelayType>;
};

export type ChartsConfigType = {
  __typename?: 'ChartsConfigType';
  city: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  gender: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  state: Scalars['Boolean']['output'];
  store: StoreType;
  tags: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  yearRange: Scalars['Boolean']['output'];
};

export type CityType = {
  __typename?: 'CityType';
  id?: Maybe<Scalars['Int']['output']>;
  nome?: Maybe<Scalars['String']['output']>;
};

export type ColumnStatusInput = {
  boardId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['Int']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
};

export type ColumnStatusNode = Node & {
  __typename?: 'ColumnStatusNode';
  board: BoardNode;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integrationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  taskSet: TaskNodeConnection;
};


export type ColumnStatusNodeTaskSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  columnStatus?: InputMaybe<Scalars['ID']['input']>;
  columnStatus_IntegrationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gte?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lte?: InputMaybe<Scalars['Time']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  responsible?: InputMaybe<Scalars['ID']['input']>;
};

export type ColumnStatusNodeConnection = {
  __typename?: 'ColumnStatusNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ColumnStatusNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `ColumnStatusNode` and its cursor. */
export type ColumnStatusNodeEdge = {
  __typename?: 'ColumnStatusNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ColumnStatusNode>;
};

export type ColumnStatusType = {
  __typename?: 'ColumnStatusType';
  board: BoardNode;
  id: Scalars['ID']['output'];
  integrationId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  taskSet: TaskNodeConnection;
};


export type ColumnStatusTypeTaskSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  columnStatus?: InputMaybe<Scalars['ID']['input']>;
  columnStatus_IntegrationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gte?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lte?: InputMaybe<Scalars['Time']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  responsible?: InputMaybe<Scalars['ID']['input']>;
};

export type CreateBoard = {
  __typename?: 'CreateBoard';
  board?: Maybe<BoardType>;
};

export type CreateCampaignInput = {
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  buttonUrl?: InputMaybe<Scalars['String']['input']>;
  daysToTrigger?: InputMaybe<Scalars['Int']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  messageEmail?: InputMaybe<Scalars['String']['input']>;
  messageSms?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  viaEmail?: InputMaybe<Scalars['Boolean']['input']>;
  viaSms?: InputMaybe<Scalars['Boolean']['input']>;
  viaWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCampaignMutation = {
  __typename?: 'CreateCampaignMutation';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateCategoryInput = {
  categories: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateCategoryMutation = {
  __typename?: 'CreateCategoryMutation';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateColumnStatus = {
  __typename?: 'CreateColumnStatus';
  columnStatus?: Maybe<ColumnStatusType>;
};

export type CreateCustomUserMutation = {
  __typename?: 'CreateCustomUserMutation';
  customUser?: Maybe<CustomUserType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateCustomer = {
  __typename?: 'CreateCustomer';
  customer?: Maybe<CustomerType>;
};

export type CreateCustomerInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<CustomerGender>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
  state?: InputMaybe<Scalars['String']['input']>;
  tagsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCustomerTagsInput = {
  tags: Array<InputMaybe<Scalars['String']['input']>>;
};

export type CreateCustomerTagsMutation = {
  __typename?: 'CreateCustomerTagsMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  tagsList?: Maybe<Array<Maybe<CustomerTagsType>>>;
};

export type CreateGiftbackInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  dueInDays?: InputMaybe<Scalars['Decimal']['input']>;
  minValueToRedeem?: InputMaybe<Scalars['Decimal']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreateGiftbackMutation = {
  __typename?: 'CreateGiftbackMutation';
  giftback?: Maybe<GiftbackType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateInstance = {
  __typename?: 'CreateInstance';
  instance?: Maybe<InstanceType>;
};

export type CreateIntegration = {
  __typename?: 'CreateIntegration';
  integration?: Maybe<IntegrationType>;
};

export type CreateLead = {
  __typename?: 'CreateLead';
  lead?: Maybe<LeadType>;
};

export type CreateProductBulkInput = {
  categoriesId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreateProductBulkMutation = {
  __typename?: 'CreateProductBulkMutation';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<ProductType>;
};

export type CreateProductInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreateProductMutation = {
  __typename?: 'CreateProductMutation';
  error?: Maybe<Scalars['String']['output']>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<ProductType>;
};

export type CreatePurchaseInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  discountType?: InputMaybe<Scalars['String']['input']>;
  discountValue?: InputMaybe<Scalars['Decimal']['input']>;
  giftbackCode?: InputMaybe<Scalars['String']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  products?: InputMaybe<Array<InputMaybe<PurchaseProductInput>>>;
  taxes?: InputMaybe<Scalars['Decimal']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type CreatePurchaseMutation = {
  __typename?: 'CreatePurchaseMutation';
  error?: Maybe<Scalars['String']['output']>;
  purchase?: Maybe<PurchaseType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreatePurchaseTotemInput = {
  creditHouseInstallments?: InputMaybe<Scalars['Int']['input']>;
  creditHouseTax?: InputMaybe<Scalars['Decimal']['input']>;
  customerId: Scalars['ID']['input'];
  giftbackCode?: InputMaybe<Scalars['String']['input']>;
  paymentMethod: Scalars['String']['input'];
  value: Scalars['Decimal']['input'];
};

export type CreatePurchaseTotemMutation = {
  __typename?: 'CreatePurchaseTotemMutation';
  error?: Maybe<Scalars['String']['output']>;
  purchase?: Maybe<PurchaseType>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateStoreInput = {
  cnpj?: InputMaybe<Scalars['String']['input']>;
  currencyType?: InputMaybe<StoreCurrencyType>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<StorePlan>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type CreateStoreMutation = {
  __typename?: 'CreateStoreMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  store?: Maybe<StoreType>;
};

export type CreateTask = {
  __typename?: 'CreateTask';
  task?: Maybe<TaskType>;
};

export type CreateTrayStoreIntegration = {
  __typename?: 'CreateTrayStoreIntegration';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type CreateTrayStoreIntegrationInput = {
  apiKey: Scalars['String']['input'];
  code: Scalars['String']['input'];
  connected?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateUserInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  cpf: Scalars['String']['input'];
  email: Scalars['String']['input'];
  gender?: InputMaybe<CustomerGender>;
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  userType?: InputMaybe<PermissionUserType>;
};

export type CreditHouseConfigType = {
  __typename?: 'CreditHouseConfigType';
  credithousegiftbackconfigSet: Array<CreditHouseGiftbackConfigType>;
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
};

/** An enumeration. */
export enum CreditHouseGiftbackConfigGiftbackTypeValue {
  /** R$ */
  Fixed = 'FIXED',
  /** % */
  Percentage = 'PERCENTAGE'
}

export type CreditHouseGiftbackConfigType = {
  __typename?: 'CreditHouseGiftbackConfigType';
  createdAt: Scalars['DateTime']['output'];
  creditHouseConfig: CreditHouseConfigType;
  giftbackTypeValue: CreditHouseGiftbackConfigGiftbackTypeValue;
  giftbackValue: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  loanMaxValue: Scalars['Float']['output'];
  loanMinValue: Scalars['Float']['output'];
  pk: Scalars['Int']['output'];
  taxMaxValue: Scalars['Float']['output'];
  taxMinValue: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CreditHousePurchaseType = {
  __typename?: 'CreditHousePurchaseType';
  id: Scalars['ID']['output'];
  installments: Scalars['Int']['output'];
  pk: Scalars['Int']['output'];
  purchase: PurchaseRelayType;
  tax: Scalars['Float']['output'];
};

/** An enumeration. */
export enum CustomUserGender {
  /** Feminino */
  Female = 'FEMALE',
  /** Masculino */
  Male = 'MALE',
  /** Indefinido */
  NotInformed = 'NOT_INFORMED'
}

export type CustomUserNode = Node & {
  __typename?: 'CustomUserNode';
  acceptedTerm?: Maybe<Scalars['Boolean']['output']>;
  archived?: Maybe<Scalars['Boolean']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  boardSet: BoardNodeConnection;
  colaborators: BoardNodeConnection;
  connectedStore?: Maybe<StoreType>;
  cpf?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dateJoined: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<CustomUserGender>;
  giftbackSet: GiftbackRelayTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  instanceSet: InstanceNodeConnection;
  integrationSet: IntegrationNodeConnection;
  /** Indica que o usuário será tratado como ativo. Ao invés de excluir contas de usuário, desmarque isso. */
  isActive: Scalars['Boolean']['output'];
  /** Indica que usuário consegue acessar este site de administração. */
  isStaff: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  permissions: Array<PermissionType>;
  phone?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  profile: CustomUserProfile;
  purchaseSet: PurchaseRelayTypeConnection;
  redeemedGiftbacks: GiftbackRelayTypeConnection;
  secondaryEmail?: Maybe<Scalars['String']['output']>;
  taskSet: TaskNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
  useracceptationSet: Array<UserAcceptationType>;
  /** Obrigatório. 150 caracteres ou menos. Letras, números e @/./+/-/_ apenas. */
  username: Scalars['String']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
};


export type CustomUserNodeBoardSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserNodeColaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserNodeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type CustomUserNodeGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserNodeInstanceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserNodeIntegrationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserNodePurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserNodeRedeemedGiftbacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserNodeTaskSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  columnStatus?: InputMaybe<Scalars['ID']['input']>;
  columnStatus_IntegrationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gte?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lte?: InputMaybe<Scalars['Time']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  responsible?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomUserNodeConnection = {
  __typename?: 'CustomUserNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomUserNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `CustomUserNode` and its cursor. */
export type CustomUserNodeEdge = {
  __typename?: 'CustomUserNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomUserNode>;
};

/** An enumeration. */
export enum CustomUserProfile {
  /** Admin */
  Admin = 'ADMIN',
  /** Funcionário */
  Employee = 'EMPLOYEE'
}

export type CustomUserRelayType = Node & {
  __typename?: 'CustomUserRelayType';
  birthdate?: Maybe<Scalars['Date']['output']>;
  boardSet: BoardNodeConnection;
  colaborators: BoardNodeConnection;
  connectedStore?: Maybe<StoreType>;
  cpf?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dateJoined: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<CustomUserGender>;
  giftbackSet: GiftbackRelayTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  instanceSet: InstanceNodeConnection;
  integrationSet: IntegrationNodeConnection;
  /** Indica que o usuário será tratado como ativo. Ao invés de excluir contas de usuário, desmarque isso. */
  isActive: Scalars['Boolean']['output'];
  /** Indica que usuário consegue acessar este site de administração. */
  isStaff: Scalars['Boolean']['output'];
  /** Indica que este usuário tem todas as permissões sem atribuí-las explicitamente. */
  isSuperuser: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  permissionInStore?: Maybe<PermissionType>;
  permissions: Array<PermissionType>;
  phone?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  profile: CustomUserProfile;
  purchaseSet: PurchaseRelayTypeConnection;
  redeemedGiftbacks: GiftbackRelayTypeConnection;
  taskSet: TaskNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
  useracceptationSet: Array<UserAcceptationType>;
  /** Obrigatório. 150 caracteres ou menos. Letras, números e @/./+/-/_ apenas. */
  username: Scalars['String']['output'];
};


export type CustomUserRelayTypeBoardSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserRelayTypeColaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserRelayTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type CustomUserRelayTypeGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserRelayTypeInstanceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserRelayTypeIntegrationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserRelayTypePurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserRelayTypeRedeemedGiftbacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserRelayTypeTaskSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  columnStatus?: InputMaybe<Scalars['ID']['input']>;
  columnStatus_IntegrationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gte?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lte?: InputMaybe<Scalars['Time']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  responsible?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomUserRelayTypeConnection = {
  __typename?: 'CustomUserRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomUserRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CustomUserRelayType` and its cursor. */
export type CustomUserRelayTypeEdge = {
  __typename?: 'CustomUserRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomUserRelayType>;
};

export type CustomUserType = {
  __typename?: 'CustomUserType';
  birthdate?: Maybe<Scalars['Date']['output']>;
  boardSet: BoardNodeConnection;
  colaborators: BoardNodeConnection;
  connectedStore?: Maybe<StoreType>;
  cpf?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dateJoined: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<CustomUserGender>;
  giftbackSet: GiftbackRelayTypeConnection;
  id: Scalars['ID']['output'];
  instanceSet: InstanceNodeConnection;
  integrationSet: IntegrationNodeConnection;
  /** Indica que o usuário será tratado como ativo. Ao invés de excluir contas de usuário, desmarque isso. */
  isActive: Scalars['Boolean']['output'];
  /** Indica que usuário consegue acessar este site de administração. */
  isStaff: Scalars['Boolean']['output'];
  /** Indica que este usuário tem todas as permissões sem atribuí-las explicitamente. */
  isSuperuser: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  password: Scalars['String']['output'];
  permissions: Array<PermissionType>;
  phone?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  profile: CustomUserProfile;
  purchaseSet: PurchaseRelayTypeConnection;
  redeemedGiftbacks: GiftbackRelayTypeConnection;
  taskSet: TaskNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
  useracceptationSet: Array<UserAcceptationType>;
  /** Obrigatório. 150 caracteres ou menos. Letras, números e @/./+/-/_ apenas. */
  username: Scalars['String']['output'];
};


export type CustomUserTypeBoardSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserTypeColaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type CustomUserTypeGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserTypeInstanceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserTypeIntegrationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type CustomUserTypePurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserTypeRedeemedGiftbacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomUserTypeTaskSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  columnStatus?: InputMaybe<Scalars['ID']['input']>;
  columnStatus_IntegrationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gte?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lte?: InputMaybe<Scalars['Time']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  responsible?: InputMaybe<Scalars['ID']['input']>;
};

export type CustomerActivitiesTypeRelay = Node & {
  __typename?: 'CustomerActivitiesTypeRelay';
  activity?: Maybe<CustomerActivityActivity>;
  channelEmail: Scalars['Boolean']['output'];
  channelSms: Scalars['Boolean']['output'];
  channelWhatsapp: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerTypeRelay;
  date: Scalars['Date']['output'];
  description: Scalars['String']['output'];
  giftback?: Maybe<GiftbackRelayType>;
  hour: Scalars['Time']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  messageBody?: Maybe<Scalars['String']['output']>;
  messageType?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  purchase?: Maybe<PurchaseRelayType>;
  source: CustomerActivitySource;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<CustomUserNode>;
};

export type CustomerActivitiesTypeRelayConnection = {
  __typename?: 'CustomerActivitiesTypeRelayConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerActivitiesTypeRelayEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CustomerActivitiesTypeRelay` and its cursor. */
export type CustomerActivitiesTypeRelayEdge = {
  __typename?: 'CustomerActivitiesTypeRelayEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerActivitiesTypeRelay>;
};

/** An enumeration. */
export enum CustomerActivityActivity {
  /** Customer */
  Customer = 'CUSTOMER',
  /** Giftback */
  Giftback = 'GIFTBACK',
  /** Purchases */
  Purchase = 'PURCHASE',
  /** User */
  User = 'USER'
}

/** An enumeration. */
export enum CustomerActivitySource {
  /** Bling */
  Bling = 'BLING',
  /** Giftback criado separadamente */
  CreateOnlyGiftback = 'CREATE_ONLY_GIFTBACK',
  /** Dapic */
  Dapic = 'DAPIC',
  /** Moego */
  Moego = 'MOEGO',
  /** Omie */
  Omie = 'OMIE',
  /** PDV */
  Pdv = 'PDV',
  /** SGI */
  Sgi = 'SGI',
  /** Planilha */
  Spreadsheet = 'SPREADSHEET',
  /** Sistema Zeki */
  System = 'SYSTEM',
  /** Totem */
  Totem = 'TOTEM',
  /** Tray */
  Tray = 'TRAY',
  /** Webhook */
  Webhook = 'WEBHOOK'
}

export type CustomerDashboardCardsReturn = {
  __typename?: 'CustomerDashboardCardsReturn';
  averagePurchaseCountPerCustomer?: Maybe<Scalars['Float']['output']>;
  averageValuePerCustomer?: Maybe<Scalars['Float']['output']>;
  totalCustomers?: Maybe<Scalars['Int']['output']>;
};

export type CustomerExcelReportInput = {
  averageTicket?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Gte?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Lte?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  giftback_Status_In?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Name (OR) Email */
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  /** Ordenando */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Phone (Or) Tax document */
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  /** Purchase Date Gte */
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  /** Purchase Date Lte */
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  /** RFM Classification  */
  rfmClassification?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['Float']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerExcelReportType = {
  __typename?: 'CustomerExcelReportType';
  background?: Maybe<Scalars['Boolean']['output']>;
  relatorio?: Maybe<Scalars['String']['output']>;
};

export type CustomerFilterSetInput = {
  averageTicket?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Gte?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Lte?: InputMaybe<Scalars['Float']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  giftback_Status_In?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Name (OR) Email */
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  /** Ordenando */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  /** Phone (Or) Tax document */
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  /** Purchase Date Gte */
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  /** Purchase Date Lte */
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  /** RFM Classification  */
  rfmClassification?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['Float']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};

/** An enumeration. */
export enum CustomerGender {
  /** Feminino */
  Female = 'FEMALE',
  /** Masculino */
  Male = 'MALE',
  /** Indefinido */
  NotInformed = 'NOT_INFORMED'
}

/** An enumeration. */
export enum CustomerImportedFrom {
  /** Bling */
  Bling = 'BLING',
  /** Giftback criado separadamente */
  CreateOnlyGiftback = 'CREATE_ONLY_GIFTBACK',
  /** Dapic */
  Dapic = 'DAPIC',
  /** Moego */
  Moego = 'MOEGO',
  /** Omie */
  Omie = 'OMIE',
  /** PDV */
  Pdv = 'PDV',
  /** SGI */
  Sgi = 'SGI',
  /** Planilha */
  Spreadsheet = 'SPREADSHEET',
  /** Sistema Zeki */
  System = 'SYSTEM',
  /** Totem */
  Totem = 'TOTEM',
  /** Tray */
  Tray = 'TRAY',
  /** Webhook */
  Webhook = 'WEBHOOK'
}

export type CustomerTagsDonutChartReturn = {
  __typename?: 'CustomerTagsDonutChartReturn';
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type CustomerTagsRelayType = Node & {
  __typename?: 'CustomerTagsRelayType';
  createdAt: Scalars['DateTime']['output'];
  customerSet: CustomerTypeRelayConnection;
  date: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  store?: Maybe<StoreType>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerTagsRelayTypeCustomerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  averageTicket?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Gte?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Lte?: InputMaybe<Scalars['Float']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  giftback_Status_In?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  rfmClassification?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerTagsRelayTypeConnection = {
  __typename?: 'CustomerTagsRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerTagsRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CustomerTagsRelayType` and its cursor. */
export type CustomerTagsRelayTypeEdge = {
  __typename?: 'CustomerTagsRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerTagsRelayType>;
};

export type CustomerTagsType = {
  __typename?: 'CustomerTagsType';
  createdAt: Scalars['DateTime']['output'];
  customerSet: CustomerTypeRelayConnection;
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  store?: Maybe<StoreType>;
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerTagsTypeCustomerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  averageTicket?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Gte?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Lte?: InputMaybe<Scalars['Float']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  giftback_Status_In?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  rfmClassification?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CustomerType = {
  __typename?: 'CustomerType';
  averageTicket: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  frequencyClass?: Maybe<Scalars['Int']['output']>;
  frequencyValueAverage?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<CustomerGender>;
  giftbackSet: GiftbackRelayTypeConnection;
  id: Scalars['ID']['output'];
  importedFrom: CustomerImportedFrom;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  lastPurchaseDate?: Maybe<Scalars['Date']['output']>;
  monetaryValueClass?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  purchaseSet: PurchaseRelayTypeConnection;
  recency?: Maybe<Scalars['Int']['output']>;
  recencyClass?: Maybe<Scalars['Int']['output']>;
  rfmAverage?: Maybe<Scalars['Float']['output']>;
  rfmClassification?: Maybe<Scalars['String']['output']>;
  rfmCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  store: StoreType;
  tags: CustomerTagsRelayTypeConnection;
  taxDocument?: Maybe<Scalars['String']['output']>;
  totalMonetaryValue: Scalars['Float']['output'];
  totalPurchasesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type CustomerTypeGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomerTypePurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomerTypeTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerTypeRelay = Node & {
  __typename?: 'CustomerTypeRelay';
  averageTicket: Scalars['Float']['output'];
  city?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  frequencyClass?: Maybe<Scalars['Int']['output']>;
  frequencyValueAverage?: Maybe<Scalars['Float']['output']>;
  gender?: Maybe<CustomerGender>;
  giftbackSet: GiftbackRelayTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importedFrom: CustomerImportedFrom;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  lastPurchaseDate?: Maybe<Scalars['Date']['output']>;
  monetaryValueClass?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  purchaseSet: PurchaseRelayTypeConnection;
  recencyClass?: Maybe<Scalars['Int']['output']>;
  rfmAverage?: Maybe<Scalars['Float']['output']>;
  rfmClassification?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  store: StoreType;
  tags: CustomerTagsRelayTypeConnection;
  taxDocument?: Maybe<Scalars['String']['output']>;
  totalMonetaryValue: Scalars['Float']['output'];
  totalPurchasesCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};


export type CustomerTypeRelayCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type CustomerTypeRelayGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomerTypeRelayPurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type CustomerTypeRelayTagsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerTypeRelayConnection = {
  __typename?: 'CustomerTypeRelayConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerTypeRelayEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `CustomerTypeRelay` and its cursor. */
export type CustomerTypeRelayEdge = {
  __typename?: 'CustomerTypeRelayEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<CustomerTypeRelay>;
};

export type DashboardCustomersByCityResponse = {
  __typename?: 'DashboardCustomersByCityResponse';
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type DeleteAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/**
 * Delete account permanently or make `user.is_active=False`.
 *
 * The behavior is defined on settings.
 * Anyway user refresh tokens are revoked.
 *
 * User must be verified and confirm password.
 */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteBoard = {
  __typename?: 'DeleteBoard';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCampaignInput = {
  campaignId: Scalars['ID']['input'];
};

export type DeleteCampaignMutation = {
  __typename?: 'DeleteCampaignMutation';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCategoryInput = {
  categoryId: Scalars['ID']['input'];
};

export type DeleteCategoryMutation = {
  __typename?: 'DeleteCategoryMutation';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteColumnStatus = {
  __typename?: 'DeleteColumnStatus';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCustomer = {
  __typename?: 'DeleteCustomer';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteCustomerTagsMutation = {
  __typename?: 'DeleteCustomerTagsMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteInstance = {
  __typename?: 'DeleteInstance';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteIntegration = {
  __typename?: 'DeleteIntegration';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteLead = {
  __typename?: 'DeleteLead';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteProductInput = {
  productId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteProductMutation = {
  __typename?: 'DeleteProductMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type DeleteTask = {
  __typename?: 'DeleteTask';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type EmailProviderStoreIntegrationInput = {
  emailToSend?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An enumeration. */
export enum EmailProviderStoreIntegrationProvider {
  /** Mailgun */
  Mailgun = 'MAILGUN',
  /** Resend */
  Resend = 'RESEND'
}

export type EmailProviderStoreIntegrationType = {
  __typename?: 'EmailProviderStoreIntegrationType';
  createdAt: Scalars['DateTime']['output'];
  emailToSend: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  pk: Scalars['Int']['output'];
  provider: EmailProviderStoreIntegrationProvider;
  store: StoreType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FrequencyRfmInput = {
  zoneFiveMax?: InputMaybe<Scalars['Int']['input']>;
  zoneFiveMin?: InputMaybe<Scalars['Int']['input']>;
  zoneFourMax?: InputMaybe<Scalars['Int']['input']>;
  zoneFourMin?: InputMaybe<Scalars['Int']['input']>;
  zoneOneMax?: InputMaybe<Scalars['Int']['input']>;
  zoneOneMin?: InputMaybe<Scalars['Int']['input']>;
  zoneThreeMax?: InputMaybe<Scalars['Int']['input']>;
  zoneThreeMin?: InputMaybe<Scalars['Int']['input']>;
  zoneTwoMax?: InputMaybe<Scalars['Int']['input']>;
  zoneTwoMin?: InputMaybe<Scalars['Int']['input']>;
};

export type FrequencyRfmType = {
  __typename?: 'FrequencyRFMType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  zoneFiveMax: Scalars['Int']['output'];
  zoneFiveMin: Scalars['Int']['output'];
  zoneFourMax: Scalars['Int']['output'];
  zoneFourMin: Scalars['Int']['output'];
  zoneOneMax: Scalars['Int']['output'];
  zoneOneMin: Scalars['Int']['output'];
  zoneThreeMax: Scalars['Int']['output'];
  zoneThreeMin: Scalars['Int']['output'];
  zoneTwoMax: Scalars['Int']['output'];
  zoneTwoMin: Scalars['Int']['output'];
};

export type GenderPieChartReturn = {
  __typename?: 'GenderPieChartReturn';
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type GiftbackAreaChartReturn = {
  __typename?: 'GiftbackAreaChartReturn';
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  kindOfDate?: Maybe<Scalars['String']['output']>;
  series?: Maybe<Array<Maybe<GiftbackAreaChartSeries>>>;
};

export type GiftbackAreaChartSeries = {
  __typename?: 'GiftbackAreaChartSeries';
  data?: Maybe<Array<Maybe<GiftbackAreaChartSeriesData>>>;
  name?: Maybe<Scalars['String']['output']>;
};

export type GiftbackAreaChartSeriesData = {
  __typename?: 'GiftbackAreaChartSeriesData';
  date?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type GiftbackAverageTickets = {
  __typename?: 'GiftbackAverageTickets';
  activeGiftbackAverageTicket?: Maybe<Scalars['Float']['output']>;
  cancelledGiftbackAverageTicket?: Maybe<Scalars['Float']['output']>;
  expiredGiftbackAverageTicket?: Maybe<Scalars['Float']['output']>;
  overdueGiftbackAverageTicket?: Maybe<Scalars['Float']['output']>;
  redeemedGiftbackAverageTicket?: Maybe<Scalars['Float']['output']>;
};

export type GiftbackCardsValues = {
  __typename?: 'GiftbackCardsValues';
  activeGiftbacks?: Maybe<Scalars['Int']['output']>;
  cancelledGiftbacks?: Maybe<Scalars['Int']['output']>;
  expiredGiftbacks?: Maybe<Scalars['Int']['output']>;
  giftbackGains?: Maybe<Scalars['Float']['output']>;
  overdueGiftbacks?: Maybe<Scalars['Int']['output']>;
  redeemedGiftbacks?: Maybe<Scalars['Int']['output']>;
};

export type GiftbackConfigInput = {
  cumulative?: InputMaybe<Scalars['Boolean']['input']>;
  discountLimit?: InputMaybe<Scalars['Decimal']['input']>;
  discountLimitEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['Int']['input']>;
  maximumDiscountPercentage?: InputMaybe<Scalars['Decimal']['input']>;
  minPurchaseValueForCreate?: InputMaybe<Scalars['Decimal']['input']>;
  minPurchaseValueForRedeem?: InputMaybe<Scalars['Decimal']['input']>;
  minimumValueType?: InputMaybe<GiftbackConfigValueType>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
  valueType?: InputMaybe<GiftbackConfigValueType>;
};

/** An enumeration. */
export enum GiftbackConfigMinimumValueType {
  /** Valor Fixo */
  FixedValue = 'FIXED_VALUE',
  /** Porcentagem */
  Percentage = 'PERCENTAGE'
}

export type GiftbackConfigType = {
  __typename?: 'GiftbackConfigType';
  codePrefix: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  cumulative?: Maybe<Scalars['Boolean']['output']>;
  /** Giftback nao pode ser mior que R$ 200,00 */
  discountLimit: Scalars['Float']['output'];
  discountLimitEnabled: Scalars['Boolean']['output'];
  dueDate: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  /** O valor máximo que o cupom pode representar de desconto na próxima compra */
  maximumDiscountPercentage?: Maybe<Scalars['Float']['output']>;
  minPurchaseValueForCreate?: Maybe<Scalars['Float']['output']>;
  minPurchaseValueForRedeem?: Maybe<Scalars['Float']['output']>;
  minimumValueType: GiftbackConfigMinimumValueType;
  pk: Scalars['Int']['output'];
  store?: Maybe<StoreType>;
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['Float']['output']>;
  valueType: GiftbackConfigValueType;
};

/** An enumeration. */
export enum GiftbackConfigValueType {
  /** Valor Fixo */
  FixedValue = 'FIXED_VALUE',
  /** Porcentagem */
  Percentage = 'PERCENTAGE'
}

export type GiftbackExcelReportType = {
  __typename?: 'GiftbackExcelReportType';
  background?: Maybe<Scalars['Boolean']['output']>;
  relatorio?: Maybe<Scalars['String']['output']>;
};

export type GiftbackFilterSetInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  /** Name (OR) Email */
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  /** Ordenando */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

/** An enumeration. */
export enum GiftbackImportedFrom {
  /** Bling */
  Bling = 'BLING',
  /** Giftback criado separadamente */
  CreateOnlyGiftback = 'CREATE_ONLY_GIFTBACK',
  /** Dapic */
  Dapic = 'DAPIC',
  /** Moego */
  Moego = 'MOEGO',
  /** Omie */
  Omie = 'OMIE',
  /** PDV */
  Pdv = 'PDV',
  /** SGI */
  Sgi = 'SGI',
  /** Planilha */
  Spreadsheet = 'SPREADSHEET',
  /** Sistema Zeki */
  System = 'SYSTEM',
  /** Totem */
  Totem = 'TOTEM',
  /** Tray */
  Tray = 'TRAY',
  /** Webhook */
  Webhook = 'WEBHOOK'
}

export type GiftbackRadialBarCharReturn = {
  __typename?: 'GiftbackRadialBarCharReturn';
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type GiftbackRelayType = Node & {
  __typename?: 'GiftbackRelayType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerTypeRelay;
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dueDate: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importedFrom: GiftbackImportedFrom;
  integrationId: Scalars['String']['output'];
  justification: Scalars['String']['output'];
  minValueToRedeem: Scalars['Float']['output'];
  pk: Scalars['Int']['output'];
  purchase?: Maybe<PurchaseRelayType>;
  purchaseUsed?: Maybe<PurchaseRelayType>;
  redeemedAt?: Maybe<Scalars['Date']['output']>;
  redeemedBy?: Maybe<CustomUserNode>;
  status: GiftbackStatus;
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<CustomUserNode>;
  value: Scalars['Float']['output'];
};


export type GiftbackRelayTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};

export type GiftbackRelayTypeConnection = {
  __typename?: 'GiftbackRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<GiftbackRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `GiftbackRelayType` and its cursor. */
export type GiftbackRelayTypeEdge = {
  __typename?: 'GiftbackRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<GiftbackRelayType>;
};

/** An enumeration. */
export enum GiftbackStatus {
  /** Ativo */
  Active = 'ACTIVE',
  /** Cancelado */
  Cancelled = 'CANCELLED',
  /** Expirado */
  Expired = 'EXPIRED',
  /** Vencido */
  Overdue = 'OVERDUE',
  /** Resgatado */
  Redeemed = 'REDEEMED'
}

export type GiftbackType = {
  __typename?: 'GiftbackType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer: CustomerTypeRelay;
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dueDate: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  importedFrom: GiftbackImportedFrom;
  integrationId: Scalars['String']['output'];
  justification: Scalars['String']['output'];
  minValueToRedeem: Scalars['Float']['output'];
  pk: Scalars['Int']['output'];
  purchase?: Maybe<PurchaseRelayType>;
  purchaseUsed?: Maybe<PurchaseRelayType>;
  redeemedAt?: Maybe<Scalars['Date']['output']>;
  redeemedBy?: Maybe<CustomUserNode>;
  status: GiftbackStatus;
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<CustomUserNode>;
  value: Scalars['Float']['output'];
};


export type GiftbackTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};

export type HomePageCards = {
  __typename?: 'HomePageCards';
  purchaseAverageTicket?: Maybe<Scalars['Float']['output']>;
  totalActiveGiftbacks?: Maybe<Scalars['Int']['output']>;
  totalCustomers?: Maybe<Scalars['Int']['output']>;
  totalPurchase?: Maybe<Scalars['Int']['output']>;
  totalValuePurchase?: Maybe<Scalars['Float']['output']>;
  totalValueRedeemedGiftbacks?: Maybe<Scalars['Float']['output']>;
};

export type InstanceInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  isActived?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownerId?: InputMaybe<Scalars['ID']['input']>;
  saveContact?: InputMaybe<Scalars['Boolean']['input']>;
  webhookAfterDisconnect?: InputMaybe<Scalars['String']['input']>;
  webhookAfterReceive?: InputMaybe<Scalars['String']['input']>;
  webhookAfterSend?: InputMaybe<Scalars['String']['input']>;
  zApiId?: InputMaybe<Scalars['String']['input']>;
  zApiToken?: InputMaybe<Scalars['String']['input']>;
};

export type InstanceNode = Node & {
  __typename?: 'InstanceNode';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integrationSet: IntegrationNodeConnection;
  isActived: Scalars['Boolean']['output'];
  leadSet: LeadNodeConnection;
  name: Scalars['String']['output'];
  owner?: Maybe<CustomUserNode>;
  pk: Scalars['Int']['output'];
  saveContact: Scalars['Boolean']['output'];
  webhookAfterDisconnect: Scalars['String']['output'];
  webhookAfterReceive: Scalars['String']['output'];
  webhookAfterSend: Scalars['String']['output'];
  zApiId: Scalars['String']['output'];
  zApiToken: Scalars['String']['output'];
};


export type InstanceNodeIntegrationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type InstanceNodeLeadSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type InstanceNodeConnection = {
  __typename?: 'InstanceNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<InstanceNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `InstanceNode` and its cursor. */
export type InstanceNodeEdge = {
  __typename?: 'InstanceNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<InstanceNode>;
};

export type InstanceType = {
  __typename?: 'InstanceType';
  id: Scalars['ID']['output'];
  integrationSet: IntegrationNodeConnection;
  isActived: Scalars['Boolean']['output'];
  leadSet: LeadNodeConnection;
  name: Scalars['String']['output'];
  owner?: Maybe<CustomUserNode>;
  pk: Scalars['Int']['output'];
  saveContact: Scalars['Boolean']['output'];
  webhookAfterDisconnect: Scalars['String']['output'];
  webhookAfterReceive: Scalars['String']['output'];
  webhookAfterSend: Scalars['String']['output'];
  zApiId: Scalars['String']['output'];
  zApiToken: Scalars['String']['output'];
};


export type InstanceTypeIntegrationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type InstanceTypeLeadSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  instanceId?: InputMaybe<Scalars['ID']['input']>;
  integrationType?: InputMaybe<Scalars['String']['input']>;
  isActived?: InputMaybe<Scalars['Boolean']['input']>;
  methodType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pipefyToken?: InputMaybe<Scalars['String']['input']>;
  triggers?: InputMaybe<Scalars['String']['input']>;
};

export type IntegrationNode = Node & {
  __typename?: 'IntegrationNode';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  instance?: Maybe<InstanceNode>;
  integrationType: Scalars['String']['output'];
  isActived: Scalars['Boolean']['output'];
  methodType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<CustomUserNode>;
  pipefyToken: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  triggers: Scalars['JSONString']['output'];
};

export type IntegrationNodeConnection = {
  __typename?: 'IntegrationNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<IntegrationNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `IntegrationNode` and its cursor. */
export type IntegrationNodeEdge = {
  __typename?: 'IntegrationNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<IntegrationNode>;
};

export type IntegrationType = {
  __typename?: 'IntegrationType';
  id: Scalars['ID']['output'];
  instance?: Maybe<InstanceNode>;
  integrationType: Scalars['String']['output'];
  isActived: Scalars['Boolean']['output'];
  methodType: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner?: Maybe<CustomUserNode>;
  pipefyToken: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  triggers: Scalars['JSONString']['output'];
};

export type LeadInput = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type LeadNode = Node & {
  __typename?: 'LeadNode';
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  instance?: Maybe<InstanceNode>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  task?: Maybe<TaskNode>;
};

export type LeadNodeConnection = {
  __typename?: 'LeadNodeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LeadNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `LeadNode` and its cursor. */
export type LeadNodeEdge = {
  __typename?: 'LeadNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<LeadNode>;
};

export type LeadType = {
  __typename?: 'LeadType';
  id: Scalars['ID']['output'];
  instance?: Maybe<InstanceNode>;
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  task?: Maybe<TaskNode>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptTerms?: Maybe<AcceptTermsMutation>;
  /**
   * Archive account and revoke refresh tokens.
   *
   * User must be verified and confirm password.
   */
  archiveAccount?: Maybe<ArchiveAccountPayload>;
  cancelPurchase?: Maybe<CancelPurchaseMutation>;
  categoryCreate?: Maybe<CreateCategoryMutation>;
  categoryDelete?: Maybe<DeleteCategoryMutation>;
  categoryUpdate?: Maybe<UpdateCategoryMutation>;
  createBoard?: Maybe<CreateBoard>;
  createCampaign?: Maybe<CreateCampaignMutation>;
  createColumnStatus?: Maybe<CreateColumnStatus>;
  createCustomer?: Maybe<CreateCustomer>;
  createInstance?: Maybe<CreateInstance>;
  createIntegration?: Maybe<CreateIntegration>;
  createLead?: Maybe<CreateLead>;
  createTask?: Maybe<CreateTask>;
  createTrayStoreIntegration?: Maybe<CreateTrayStoreIntegration>;
  createUserCustom?: Maybe<CreateCustomUserMutation>;
  /**
   * Delete account permanently or make `user.is_active=False`.
   *
   * The behavior is defined on settings.
   * Anyway user refresh tokens are revoked.
   *
   * User must be verified and confirm password.
   */
  deleteAccount?: Maybe<DeleteAccountPayload>;
  deleteBoard?: Maybe<DeleteBoard>;
  deleteCampaign?: Maybe<DeleteCampaignMutation>;
  deleteColumnStatus?: Maybe<DeleteColumnStatus>;
  deleteCustomer?: Maybe<DeleteCustomer>;
  deleteInstance?: Maybe<DeleteInstance>;
  deleteIntegration?: Maybe<DeleteIntegration>;
  deleteLead?: Maybe<DeleteLead>;
  deleteTask?: Maybe<DeleteTask>;
  giftbackCancel?: Maybe<UpdateCancelGiftbackMutation>;
  giftbackCreate?: Maybe<CreateGiftbackMutation>;
  giftbackRedeem?: Maybe<UpdateRedeemGiftbackMutation>;
  /**
   * Change account password when user knows the old password.
   *
   * A new token and refresh token are sent. User must be verified.
   */
  passwordChange?: Maybe<PasswordChangePayload>;
  /**
   * Change user password without old password.
   *
   * Receive the token that was sent by email.
   *
   * If token and new passwords are valid, update
   * user password and in case of using refresh
   * tokens, revoke all of them.
   *
   * Also, if user has not been verified yet, verify it.
   */
  passwordReset?: Maybe<PasswordResetPayload>;
  productBulkCreate?: Maybe<CreateProductBulkMutation>;
  productCreate?: Maybe<CreateProductMutation>;
  productDelete?: Maybe<DeleteProductMutation>;
  productUpdate?: Maybe<UpdateProductMutation>;
  purchaseCreate?: Maybe<CreatePurchaseMutation>;
  purchaseTotemCreate?: Maybe<CreatePurchaseTotemMutation>;
  redefinePassword?: Maybe<RedefinePasswordMutation>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  refreshToken?: Maybe<RefreshTokenPayload>;
  /**
   * Register user with fields defined in the settings.
   *
   * If the email field of the user model is part of the
   * registration fields (default), check if there is
   * no user with that email or as a secondary email.
   *
   * If it exists, it does not register the user,
   * even if the email field is not defined as unique
   * (default of the default django user model).
   *
   * When creating the user, it also creates a `UserStatus`
   * related to that user, making it possible to track
   * if the user is archived, verified and has a secondary
   * email.
   *
   * Send account verification email.
   *
   * If allowed to not verified users login, return token.
   */
  register?: Maybe<RegisterPayload>;
  /**
   * Sends activation email.
   *
   * It is called resend because theoretically
   * the first activation email was sent when
   * the user registered.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  resendActivationEmail?: Maybe<ResendActivationEmailPayload>;
  saveEmailProvider?: Maybe<SaveEmailProviderStoreIntegrationMutation>;
  saveGiftbackConfig?: Maybe<SaveGiftbackConfigMutation>;
  saveRfmConfig?: Maybe<SaveRfmMutation>;
  saveWebpicStoreIntegration?: Maybe<SaveWebpicStoreIntegrationMutation>;
  saveWhatsapp?: Maybe<SaveWhatsappConfigMutation>;
  saveZApi?: Maybe<SaveZApiIntegrationMutation>;
  /**
   * Send password reset email.
   *
   * For non verified users, send an activation
   * email instead.
   *
   * Accepts both primary and secondary email.
   *
   * If there is no user with the requested email,
   * a successful response is returned.
   */
  sendPasswordResetEmail?: Maybe<SendPasswordResetEmailPayload>;
  setActiveCustomer?: Maybe<SetActiveCustomer>;
  setBlingStoreIntegration?: Maybe<SetBlingStoreIntegration>;
  setOmieStoreIntegration?: Maybe<SetOmieStoreIntegration>;
  setPaymentMethod?: Maybe<SetPaymentMethod>;
  setSmsBaratoIntegration?: Maybe<SetSmsBaratoIntegration>;
  setSmsInfoBipIntegration?: Maybe<SetSmsInfoBipIntegration>;
  storeCreate?: Maybe<CreateStoreMutation>;
  storeUpdate?: Maybe<UpdateStoreMutation>;
  tagDelete?: Maybe<DeleteCustomerTagsMutation>;
  tagUpdate?: Maybe<UpdateCustomerTagsMutation>;
  tagsCreate?: Maybe<CreateCustomerTagsMutation>;
  /**
   * Obtain JSON web token for given user.
   *
   * Allow to perform login with different fields,
   * and secondary email if set. The fields are
   * defined on settings.
   *
   * Not verified users can login by default. This
   * can be changes on settings.
   *
   * If user is archived, make it unarchive and
   * return `unarchiving=True` on output.
   */
  tokenAuth?: Maybe<ObtainJsonWebTokenPayload>;
  /**
   * Update user model fields, defined on settings.
   *
   * User must be verified.
   */
  updateAccount?: Maybe<UpdateAccountPayload>;
  updateBoard?: Maybe<UpdateBoard>;
  updateCampaign?: Maybe<UpdateCampaignMutation>;
  updateColumnStatus?: Maybe<UpdateColumnStatus>;
  updateCustomer?: Maybe<UpdateCustomer>;
  updateInstance?: Maybe<UpdateInstance>;
  updateIntegration?: Maybe<UpdateIntegration>;
  updateLead?: Maybe<UpdateLead>;
  updateTask?: Maybe<UpdateTask>;
  updateUserConnectedStore?: Maybe<UpdateUserConnectedStoreMutation>;
  updateUserCustom?: Maybe<UpdateCustomUserMutation>;
  /**
   * Verify user account.
   *
   * Receive the token that was sent by email.
   * If the token is valid, make the user verified
   * by making the `user.status.verified` field true.
   */
  verifyAccount?: Maybe<VerifyAccountPayload>;
  /** Same as `grapgql_jwt` implementation, with standard output. */
  verifyToken?: Maybe<VerifyTokenPayload>;
};


export type MutationAcceptTermsArgs = {
  termsId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type MutationArchiveAccountArgs = {
  input: ArchiveAccountInput;
};


export type MutationCancelPurchaseArgs = {
  purchaseInput?: InputMaybe<CancelPurchaseInput>;
};


export type MutationCategoryCreateArgs = {
  categoryInput: CreateCategoryInput;
};


export type MutationCategoryDeleteArgs = {
  categoryInput: DeleteCategoryInput;
};


export type MutationCategoryUpdateArgs = {
  categoryInput: UpdateCategoryInput;
};


export type MutationCreateBoardArgs = {
  boardInput?: InputMaybe<BoardInput>;
};


export type MutationCreateCampaignArgs = {
  campaignInput: CreateCampaignInput;
};


export type MutationCreateColumnStatusArgs = {
  columnStatusInput?: InputMaybe<ColumnStatusInput>;
};


export type MutationCreateCustomerArgs = {
  input?: InputMaybe<CreateCustomerInput>;
};


export type MutationCreateInstanceArgs = {
  instanceInput?: InputMaybe<InstanceInput>;
};


export type MutationCreateIntegrationArgs = {
  integrationInput?: InputMaybe<IntegrationInput>;
};


export type MutationCreateLeadArgs = {
  leadInput?: InputMaybe<LeadInput>;
};


export type MutationCreateTaskArgs = {
  taskInput?: InputMaybe<TaskInput>;
};


export type MutationCreateTrayStoreIntegrationArgs = {
  trayInput: CreateTrayStoreIntegrationInput;
};


export type MutationCreateUserCustomArgs = {
  userInput: CreateUserInput;
};


export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


export type MutationDeleteBoardArgs = {
  boardId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteCampaignArgs = {
  campaignInput: DeleteCampaignInput;
};


export type MutationDeleteColumnStatusArgs = {
  integrationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteCustomerArgs = {
  customerId: Scalars['ID']['input'];
};


export type MutationDeleteInstanceArgs = {
  instanceId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteIntegrationArgs = {
  integrationId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteLeadArgs = {
  leadId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteTaskArgs = {
  taskId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationGiftbackCancelArgs = {
  giftbackInput: UpdateCancelGiftbackInput;
};


export type MutationGiftbackCreateArgs = {
  giftbackInput: CreateGiftbackInput;
};


export type MutationGiftbackRedeemArgs = {
  giftbackInput: UpdateRedeemGiftbackInput;
};


export type MutationPasswordChangeArgs = {
  input: PasswordChangeInput;
};


export type MutationPasswordResetArgs = {
  input: PasswordResetInput;
};


export type MutationProductBulkCreateArgs = {
  productsInput?: InputMaybe<Array<InputMaybe<CreateProductBulkInput>>>;
};


export type MutationProductCreateArgs = {
  productInput: CreateProductInput;
};


export type MutationProductDeleteArgs = {
  productInput: DeleteProductInput;
};


export type MutationProductUpdateArgs = {
  productInput: UpdateProductInput;
};


export type MutationPurchaseCreateArgs = {
  purchaseInput: CreatePurchaseInput;
};


export type MutationPurchaseTotemCreateArgs = {
  purchaseInput: CreatePurchaseTotemInput;
};


export type MutationRedefinePasswordArgs = {
  input?: InputMaybe<RedefinePasswordInput>;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationResendActivationEmailArgs = {
  input: ResendActivationEmailInput;
};


export type MutationSaveEmailProviderArgs = {
  input?: InputMaybe<EmailProviderStoreIntegrationInput>;
};


export type MutationSaveGiftbackConfigArgs = {
  input?: InputMaybe<GiftbackConfigInput>;
};


export type MutationSaveRfmConfigArgs = {
  frequencyInput?: InputMaybe<FrequencyRfmInput>;
  recencyInput?: InputMaybe<RecencyRfmInput>;
  valueInput?: InputMaybe<ValueRfmInput>;
};


export type MutationSaveWebpicStoreIntegrationArgs = {
  input: SaveWebpicStoreIntegrationInput;
};


export type MutationSaveWhatsappArgs = {
  input?: InputMaybe<WhatsappConfigInput>;
};


export type MutationSaveZApiArgs = {
  input?: InputMaybe<ZApiIntegrationInput>;
};


export type MutationSendPasswordResetEmailArgs = {
  input: SendPasswordResetEmailInput;
};


export type MutationSetActiveCustomerArgs = {
  input: SetActiveCustomerInput;
};


export type MutationSetBlingStoreIntegrationArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetOmieStoreIntegrationArgs = {
  input: SetOmieStoreIntegrationInput;
};


export type MutationSetPaymentMethodArgs = {
  inputs?: InputMaybe<Array<InputMaybe<SetPaymentMethodInput>>>;
};


export type MutationSetSmsBaratoIntegrationArgs = {
  input: SetSmsBaratoIntegrationInput;
};


export type MutationSetSmsInfoBipIntegrationArgs = {
  input: SetSmsInfoBipIntegrationInput;
};


export type MutationStoreCreateArgs = {
  input: CreateStoreInput;
};


export type MutationStoreUpdateArgs = {
  input?: InputMaybe<UpdateStoreInput>;
};


export type MutationTagDeleteArgs = {
  tagId: Scalars['ID']['input'];
};


export type MutationTagUpdateArgs = {
  tagInput: UpdateCustomerTagsInput;
};


export type MutationTagsCreateArgs = {
  tagInput: CreateCustomerTagsInput;
};


export type MutationTokenAuthArgs = {
  input: ObtainJsonWebTokenInput;
};


export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


export type MutationUpdateBoardArgs = {
  boardId?: InputMaybe<Scalars['ID']['input']>;
  boardInput?: InputMaybe<BoardInput>;
};


export type MutationUpdateCampaignArgs = {
  campaignInput: UpdateCampaignInput;
};


export type MutationUpdateColumnStatusArgs = {
  columnStatusId?: InputMaybe<Scalars['ID']['input']>;
  columnStatusInput?: InputMaybe<ColumnStatusInput>;
};


export type MutationUpdateCustomerArgs = {
  input?: InputMaybe<UpdateCustomerInput>;
};


export type MutationUpdateInstanceArgs = {
  instanceInput?: InputMaybe<InstanceInput>;
};


export type MutationUpdateIntegrationArgs = {
  integrationInput?: InputMaybe<IntegrationInput>;
};


export type MutationUpdateLeadArgs = {
  leadId?: InputMaybe<Scalars['ID']['input']>;
  leadInput?: InputMaybe<LeadInput>;
};


export type MutationUpdateTaskArgs = {
  taskInput?: InputMaybe<TaskInput>;
};


export type MutationUpdateUserConnectedStoreArgs = {
  storeId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateUserCustomArgs = {
  input?: InputMaybe<UpdateUserInput>;
};


export type MutationVerifyAccountArgs = {
  input: VerifyAccountInput;
};


export type MutationVerifyTokenArgs = {
  input: VerifyTokenInput;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export type ObtainJsonWebTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
};

/**
 * Obtain JSON web token for given user.
 *
 * Allow to perform login with different fields,
 * and secondary email if set. The fields are
 * defined on settings.
 *
 * Not verified users can login by default. This
 * can be changes on settings.
 *
 * If user is archived, make it unarchive and
 * return `unarchiving=True` on output.
 */
export type ObtainJsonWebTokenPayload = {
  __typename?: 'ObtainJSONWebTokenPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  unarchiving?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<UserNode>;
};

export type OmieStoreIntegrationType = {
  __typename?: 'OmieStoreIntegrationType';
  activated: Scalars['Boolean']['output'];
  appKey: Scalars['String']['output'];
  appSecret: Scalars['String']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  nfce: Scalars['Boolean']['output'];
  nfe: Scalars['Boolean']['output'];
  nfse: Scalars['Boolean']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PasswordChangeInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

/**
 * Change account password when user knows the old password.
 *
 * A new token and refresh token are sent. User must be verified.
 */
export type PasswordChangePayload = {
  __typename?: 'PasswordChangePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type PasswordResetInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/**
 * Change user password without old password.
 *
 * Receive the token that was sent by email.
 *
 * If token and new passwords are valid, update
 * user password and in case of using refresh
 * tokens, revoke all of them.
 *
 * Also, if user has not been verified yet, verify it.
 */
export type PasswordResetPayload = {
  __typename?: 'PasswordResetPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** An enumeration. */
export enum PaymentMethodAdjustmentType {
  /** Decréscimo */
  Decrease = 'DECREASE',
  /** Acréscimo */
  Increase = 'INCREASE'
}

export type PaymentMethodType = {
  __typename?: 'PaymentMethodType';
  adjustmentType: PaymentMethodAdjustmentType;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
  valueType: PaymentMethodValueType;
};

/** An enumeration. */
export enum PaymentMethodValueType {
  /** Fixo */
  Fixed = 'FIXED',
  /** Porcentagem */
  Percentage = 'PERCENTAGE'
}

export type PermissionType = {
  __typename?: 'PermissionType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  user: CustomUserNode;
  userType?: Maybe<PermissionUserType>;
};

/** An enumeration. */
export enum PermissionUserType {
  /** Admin */
  Admin = 'ADMIN',
  /** Funcionário */
  Employee = 'EMPLOYEE'
}

export type ProductExcelReportType = {
  __typename?: 'ProductExcelReportType';
  background?: Maybe<Scalars['Boolean']['output']>;
  relatorio?: Maybe<Scalars['String']['output']>;
};

export type ProductFilterSetInput = {
  categoriesNames?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
};

/** An enumeration. */
export enum ProductImportedFrom {
  /** Bling */
  Bling = 'BLING',
  /** Dapic */
  Dapic = 'DAPIC',
  /** Moego */
  Moego = 'MOEGO',
  /** Omie */
  Omie = 'OMIE',
  /** Planilha */
  Spreadsheet = 'SPREADSHEET',
  /** Sistema Zeki */
  System = 'SYSTEM',
  /** Tray */
  Tray = 'TRAY'
}

export type ProductListItemsType = {
  __typename?: 'ProductListItemsType';
  productAbcClassification?: Maybe<Scalars['String']['output']>;
  productAccumulatedPercentage?: Maybe<Scalars['Float']['output']>;
  productIndividualPercentage?: Maybe<Scalars['Float']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productPk?: Maybe<Scalars['ID']['output']>;
  productQuantity?: Maybe<Scalars['Int']['output']>;
  productTotalValue?: Maybe<Scalars['Float']['output']>;
};

export type ProductListTableType = {
  __typename?: 'ProductListTableType';
  groups?: Maybe<Array<Maybe<ProductsCurveAbcColumnGroupsData>>>;
  productsList?: Maybe<Array<Maybe<ProductListItemsType>>>;
  total?: Maybe<Scalars['Float']['output']>;
};

export type ProductRelayType = Node & {
  __typename?: 'ProductRelayType';
  categories: CategoryRelayTypeConnection;
  costValue: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importedFrom: ProductImportedFrom;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActivated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  promotionalValue: Scalars['Float']['output'];
  purchaseproductSet: PurchaseProductRelayTypeConnection;
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};


export type ProductRelayTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type ProductRelayTypePurchaseproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product_Id?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductRelayTypeConnection = {
  __typename?: 'ProductRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<ProductRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `ProductRelayType` and its cursor. */
export type ProductRelayTypeEdge = {
  __typename?: 'ProductRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<ProductRelayType>;
};

export type ProductType = {
  __typename?: 'ProductType';
  categories: CategoryRelayTypeConnection;
  costValue: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  date: Scalars['Date']['output'];
  id: Scalars['ID']['output'];
  importedFrom: ProductImportedFrom;
  integrationId?: Maybe<Scalars['String']['output']>;
  isActivated: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  promotionalValue: Scalars['Float']['output'];
  purchaseproductSet: PurchaseProductRelayTypeConnection;
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};


export type ProductTypeCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type ProductTypePurchaseproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product_Id?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

export type ProductsCurveAbcColumnGroupsData = {
  __typename?: 'ProductsCurveAbcColumnGroupsData';
  cols?: Maybe<Scalars['Int']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type PurchaseCardsValues = {
  __typename?: 'PurchaseCardsValues';
  purchaseAverageTicket?: Maybe<Scalars['Float']['output']>;
  purchasesCount?: Maybe<Scalars['Int']['output']>;
  totalValue?: Maybe<Scalars['Float']['output']>;
};

export type PurchaseColumnChartReturn = {
  __typename?: 'PurchaseColumnChartReturn';
  kindOfDate?: Maybe<Scalars['String']['output']>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<PurchaseColumnChartSeries>>>;
};

export type PurchaseColumnChartSeries = {
  __typename?: 'PurchaseColumnChartSeries';
  data?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum PurchaseDiscountType {
  /** Fixo */
  Fixed = 'FIXED',
  /** Porcentagem */
  Percentage = 'PERCENTAGE'
}

export type PurchaseExcelReportType = {
  __typename?: 'PurchaseExcelReportType';
  background?: Maybe<Scalars['Boolean']['output']>;
  relatorio?: Maybe<Scalars['String']['output']>;
};

export type PurchaseFilterSetInput = {
  /** Approved or Canceled Sales */
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Created and/or Redeemed Giftbacks in Purchases */
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  /** Name (OR) Email */
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  /** Ordenando */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  /** Filter by Product IDs */
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['Float']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['Float']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Username (OR) Email */
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

/** An enumeration. */
export enum PurchaseImportedFrom {
  /** Bling */
  Bling = 'BLING',
  /** Giftback criado separadamente */
  CreateOnlyGiftback = 'CREATE_ONLY_GIFTBACK',
  /** Dapic */
  Dapic = 'DAPIC',
  /** Moego */
  Moego = 'MOEGO',
  /** Omie */
  Omie = 'OMIE',
  /** PDV */
  Pdv = 'PDV',
  /** SGI */
  Sgi = 'SGI',
  /** Planilha */
  Spreadsheet = 'SPREADSHEET',
  /** Sistema Zeki */
  System = 'SYSTEM',
  /** Totem */
  Totem = 'TOTEM',
  /** Tray */
  Tray = 'TRAY',
  /** Webhook */
  Webhook = 'WEBHOOK'
}

export type PurchaseProductFilterSetInput = {
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  /** Ordenando */
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product_Id?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

export type PurchaseProductInput = {
  productId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
  value: Scalars['Decimal']['input'];
};

export type PurchaseProductRelayType = Node & {
  __typename?: 'PurchaseProductRelayType';
  createdAt: Scalars['DateTime']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  product: ProductRelayType;
  purchase: PurchaseRelayType;
  quantity: Scalars['Int']['output'];
  subtotal: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  value: Scalars['Float']['output'];
};

export type PurchaseProductRelayTypeConnection = {
  __typename?: 'PurchaseProductRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PurchaseProductRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PurchaseProductRelayType` and its cursor. */
export type PurchaseProductRelayTypeEdge = {
  __typename?: 'PurchaseProductRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PurchaseProductRelayType>;
};

export type PurchaseRelayType = Node & {
  __typename?: 'PurchaseRelayType';
  additionalValues: Scalars['Float']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credithousepurchase?: Maybe<CreditHousePurchaseType>;
  customer: CustomerTypeRelay;
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  date?: Maybe<Scalars['Date']['output']>;
  discountType: PurchaseDiscountType;
  discountValue: Scalars['Float']['output'];
  giftback?: Maybe<GiftbackRelayType>;
  giftbackRedeemed?: Maybe<GiftbackRelayType>;
  giftbackRedeemedValue: Scalars['Float']['output'];
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  importedFrom: PurchaseImportedFrom;
  integrationId?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  justification: Scalars['String']['output'];
  nfeKey?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  purchaseproductSet: PurchaseProductRelayTypeConnection;
  sellerName?: Maybe<Scalars['String']['output']>;
  shipmentValue: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<CustomUserNode>;
  value: Scalars['Float']['output'];
};


export type PurchaseRelayTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type PurchaseRelayTypePurchaseproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product_Id?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

export type PurchaseRelayTypeConnection = {
  __typename?: 'PurchaseRelayTypeConnection';
  edgeCount?: Maybe<Scalars['Int']['output']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PurchaseRelayTypeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** A Relay edge containing a `PurchaseRelayType` and its cursor. */
export type PurchaseRelayTypeEdge = {
  __typename?: 'PurchaseRelayTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<PurchaseRelayType>;
};

export type PurchaseType = {
  __typename?: 'PurchaseType';
  additionalValues: Scalars['Float']['output'];
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  canceledBy?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  credithousepurchase?: Maybe<CreditHousePurchaseType>;
  customer: CustomerTypeRelay;
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  date?: Maybe<Scalars['Date']['output']>;
  discountType: PurchaseDiscountType;
  discountValue: Scalars['Float']['output'];
  giftback?: Maybe<GiftbackRelayType>;
  giftbackRedeemed?: Maybe<GiftbackRelayType>;
  giftbackRedeemedValue: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  importedFrom: PurchaseImportedFrom;
  integrationId?: Maybe<Scalars['String']['output']>;
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  justification: Scalars['String']['output'];
  nfeKey?: Maybe<Scalars['String']['output']>;
  paymentMethod: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  purchaseproductSet: PurchaseProductRelayTypeConnection;
  sellerName?: Maybe<Scalars['String']['output']>;
  shipmentValue: Scalars['Float']['output'];
  status: Scalars['String']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<CustomUserNode>;
  value: Scalars['Float']['output'];
};


export type PurchaseTypeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type PurchaseTypePurchaseproductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product_Id?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

export type Query = {
  __typename?: 'Query';
  allCustomerActivitiesRelay?: Maybe<CustomerActivitiesTypeRelayConnection>;
  allCustomersRelay?: Maybe<CustomerTypeRelayConnection>;
  allGiftbacksRelay?: Maybe<GiftbackRelayTypeConnection>;
  allProductsRelay?: Maybe<ProductRelayTypeConnection>;
  allPurchasesProductsRelay?: Maybe<PurchaseProductRelayTypeConnection>;
  allPurchasesRelay?: Maybe<PurchaseRelayTypeConnection>;
  allStateCities?: Maybe<Array<Maybe<CityType>>>;
  allStates?: Maybe<Array<Maybe<StateType>>>;
  allTerms?: Maybe<Array<Maybe<AcceptationTermType>>>;
  allUsersRelay?: Maybe<CustomUserRelayTypeConnection>;
  boardDetail?: Maybe<BoardType>;
  boardsFilter?: Maybe<BoardNodeConnection>;
  categoryList?: Maybe<CategoryRelayTypeConnection>;
  columnStatusFilter?: Maybe<ColumnStatusNodeConnection>;
  customerExcelReport?: Maybe<CustomerExcelReportType>;
  customerTagsList?: Maybe<CustomerTagsRelayTypeConnection>;
  dashboardAgeColumnChartValues?: Maybe<AgeColumnChartReturn>;
  dashboardCustomerCardsValues?: Maybe<CustomerDashboardCardsReturn>;
  dashboardCustomerTagsValues?: Maybe<CustomerTagsDonutChartReturn>;
  dashboardCustomersByCity?: Maybe<DashboardCustomersByCityResponse>;
  dashboardGenderPieChartValues?: Maybe<GenderPieChartReturn>;
  dashboardGiftbackAreaChartValues?: Maybe<GiftbackAreaChartReturn>;
  dashboardGiftbackAverageTicket?: Maybe<GiftbackAverageTickets>;
  dashboardGiftbackCardsValues?: Maybe<GiftbackCardsValues>;
  dashboardGiftbacksRadialBarChartValues?: Maybe<GiftbackRadialBarCharReturn>;
  dashboardProductsListValues?: Maybe<ProductListTableType>;
  dashboardRfmChartValues?: Maybe<Array<Maybe<RfmChartReturn>>>;
  dashboardSalesCardsValues?: Maybe<PurchaseCardsValues>;
  dashboardSalesPeriodColumnChartValues?: Maybe<PurchaseColumnChartReturn>;
  dashboardUfBarChartValues?: Maybe<UfBarChartReturn>;
  getCustomer?: Maybe<CustomerType>;
  getEmailProvider?: Maybe<EmailProviderStoreIntegrationType>;
  getGiftback?: Maybe<GiftbackType>;
  getGiftbackByCode?: Maybe<GiftbackType>;
  getGiftbackByCodeCpfEmailPhone?: Maybe<GiftbackType>;
  getGiftbackConfig?: Maybe<GiftbackConfigType>;
  getProduct?: Maybe<ProductType>;
  getPurchase?: Maybe<PurchaseType>;
  getRfmFrequency?: Maybe<FrequencyRfmType>;
  getRfmRecency?: Maybe<RecencyRfmType>;
  getRfmValue?: Maybe<ValueRfmType>;
  getStore?: Maybe<StoreType>;
  getUser?: Maybe<CustomUserType>;
  getWhatsappIntegration?: Maybe<WhatsappConfigType>;
  getZApiIntegration?: Maybe<ZApiIntegrationType>;
  giftbackExcelReport?: Maybe<GiftbackExcelReportType>;
  homepageCardsValues?: Maybe<HomePageCards>;
  homepageTopCustomers?: Maybe<Array<Maybe<TopCustomersTypeField>>>;
  homepageTopProducts?: Maybe<Array<Maybe<TopProductsTypeField>>>;
  instanceDetail?: Maybe<InstanceType>;
  instancesFilter?: Maybe<InstanceNodeConnection>;
  integrationDetail?: Maybe<IntegrationType>;
  integrationsFilter?: Maybe<IntegrationNodeConnection>;
  leadDetail?: Maybe<LeadType>;
  leadsFilter?: Maybe<LeadNodeConnection>;
  listCampaigns?: Maybe<CampaignRelayTypeConnection>;
  listPaymentMethod?: Maybe<Array<Maybe<PaymentMethodType>>>;
  me?: Maybe<CustomUserNode>;
  productExcelReport?: Maybe<ProductExcelReportType>;
  purchaseExcelReport?: Maybe<PurchaseExcelReportType>;
  readCampaign?: Maybe<CampaignType>;
  taskDetail?: Maybe<TaskType>;
  tasksFilter?: Maybe<TaskNodeConnection>;
  user?: Maybe<CustomUserNode>;
  users?: Maybe<CustomUserNodeConnection>;
};


export type QueryAllCustomerActivitiesRelayArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryAllCustomersRelayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  averageTicket?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Gte?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Lte?: InputMaybe<Scalars['Float']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  giftback_Status_In?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  rfmClassification?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryAllGiftbacksRelayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryAllProductsRelayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoriesNames?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
};


export type QueryAllPurchasesProductsRelayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  product_Id?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryAllPurchasesRelayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type QueryAllStateCitiesArgs = {
  uf?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAllUsersRelayArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  cpf_Icontains?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  permissions_UserType?: InputMaybe<Scalars['String']['input']>;
  permissions_UserType_Icontains?: InputMaybe<Scalars['String']['input']>;
  permissions_UserType_In?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type QueryBoardDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBoardsFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCategoryListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type QueryColumnStatusFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCustomerExcelReportArgs = {
  input?: InputMaybe<CustomerExcelReportInput>;
};


export type QueryCustomerTagsListArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDashboardAgeColumnChartValuesArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryDashboardCustomerCardsValuesArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryDashboardCustomerTagsValuesArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryDashboardCustomersByCityArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryDashboardGenderPieChartValuesArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryDashboardGiftbackAreaChartValuesArgs = {
  input?: InputMaybe<GiftbackFilterSetInput>;
};


export type QueryDashboardGiftbackAverageTicketArgs = {
  input?: InputMaybe<GiftbackFilterSetInput>;
};


export type QueryDashboardGiftbackCardsValuesArgs = {
  input?: InputMaybe<GiftbackFilterSetInput>;
};


export type QueryDashboardGiftbacksRadialBarChartValuesArgs = {
  input?: InputMaybe<GiftbackFilterSetInput>;
};


export type QueryDashboardProductsListValuesArgs = {
  classification?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<PurchaseProductFilterSetInput>;
};


export type QueryDashboardRfmChartValuesArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryDashboardSalesCardsValuesArgs = {
  input?: InputMaybe<PurchaseFilterSetInput>;
};


export type QueryDashboardSalesPeriodColumnChartValuesArgs = {
  input?: InputMaybe<PurchaseFilterSetInput>;
};


export type QueryDashboardUfBarChartValuesArgs = {
  input?: InputMaybe<CustomerFilterSetInput>;
};


export type QueryGetCustomerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetGiftbackArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetGiftbackByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetGiftbackByCodeCpfEmailPhoneArgs = {
  input: Scalars['String']['input'];
};


export type QueryGetProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPurchaseArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGiftbackExcelReportArgs = {
  input?: InputMaybe<GiftbackFilterSetInput>;
};


export type QueryInstanceDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInstancesFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIntegrationDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryIntegrationsFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLeadDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLeadsFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryListCampaignsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tag_Icontains?: InputMaybe<Scalars['String']['input']>;
  titleOrMessage?: InputMaybe<Scalars['String']['input']>;
  viaEmail?: InputMaybe<Scalars['Boolean']['input']>;
  viaSms?: InputMaybe<Scalars['Boolean']['input']>;
  viaWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryProductExcelReportArgs = {
  input?: InputMaybe<ProductFilterSetInput>;
};


export type QueryPurchaseExcelReportArgs = {
  input?: InputMaybe<PurchaseFilterSetInput>;
};


export type QueryReadCampaignArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskDetailArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTasksFilterArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status_Archived?: InputMaybe<Scalars['Boolean']['input']>;
  status_SecondaryEmail?: InputMaybe<Scalars['String']['input']>;
  status_Verified?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  username_Icontains?: InputMaybe<Scalars['String']['input']>;
  username_Istartswith?: InputMaybe<Scalars['String']['input']>;
};

export type RecencyRfmInput = {
  zoneFiveMax?: InputMaybe<Scalars['Int']['input']>;
  zoneFiveMin?: InputMaybe<Scalars['Int']['input']>;
  zoneFourMax?: InputMaybe<Scalars['Int']['input']>;
  zoneFourMin?: InputMaybe<Scalars['Int']['input']>;
  zoneOneMax?: InputMaybe<Scalars['Int']['input']>;
  zoneOneMin?: InputMaybe<Scalars['Int']['input']>;
  zoneThreeMax?: InputMaybe<Scalars['Int']['input']>;
  zoneThreeMin?: InputMaybe<Scalars['Int']['input']>;
  zoneTwoMax?: InputMaybe<Scalars['Int']['input']>;
  zoneTwoMin?: InputMaybe<Scalars['Int']['input']>;
};

export type RecencyRfmType = {
  __typename?: 'RecencyRFMType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  zoneFiveMax: Scalars['Int']['output'];
  zoneFiveMin: Scalars['Int']['output'];
  zoneFourMax: Scalars['Int']['output'];
  zoneFourMin: Scalars['Int']['output'];
  zoneOneMax: Scalars['Int']['output'];
  zoneOneMin: Scalars['Int']['output'];
  zoneThreeMax: Scalars['Int']['output'];
  zoneThreeMin: Scalars['Int']['output'];
  zoneTwoMax: Scalars['Int']['output'];
  zoneTwoMin: Scalars['Int']['output'];
};

export type RedefinePasswordInput = {
  newPassword: Scalars['String']['input'];
  newPasswordConfirm: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type RedefinePasswordMutation = {
  __typename?: 'RedefinePasswordMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type RefreshTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  payload?: Maybe<Scalars['GenericScalar']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type RegisterInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cpf?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  gender?: InputMaybe<Scalars['String']['input']>;
  isStaff?: InputMaybe<Scalars['String']['input']>;
  isSuperuser?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password1: Scalars['String']['input'];
  password2: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

/**
 * Register user with fields defined in the settings.
 *
 * If the email field of the user model is part of the
 * registration fields (default), check if there is
 * no user with that email or as a secondary email.
 *
 * If it exists, it does not register the user,
 * even if the email field is not defined as unique
 * (default of the default django user model).
 *
 * When creating the user, it also creates a `UserStatus`
 * related to that user, making it possible to track
 * if the user is archived, verified and has a secondary
 * email.
 *
 * Send account verification email.
 *
 * If allowed to not verified users login, return token.
 */
export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type ResendActivationEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/**
 * Sends activation email.
 *
 * It is called resend because theoretically
 * the first activation email was sent when
 * the user registered.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type ResendActivationEmailPayload = {
  __typename?: 'ResendActivationEmailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type RfmChartReturn = {
  __typename?: 'RfmChartReturn';
  x?: Maybe<Scalars['String']['output']>;
  y?: Maybe<Scalars['Int']['output']>;
};

export type SmsBaratoIntegrationType = {
  __typename?: 'SMSBaratoIntegrationType';
  activated: Scalars['Boolean']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SmsInfoBipIntegrationType = {
  __typename?: 'SMSInfoBipIntegrationType';
  activated: Scalars['Boolean']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

export type SaveEmailProviderStoreIntegrationMutation = {
  __typename?: 'SaveEmailProviderStoreIntegrationMutation';
  emailProvider?: Maybe<EmailProviderStoreIntegrationType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SaveGiftbackConfigMutation = {
  __typename?: 'SaveGiftbackConfigMutation';
  giftback?: Maybe<GiftbackConfigType>;
};

export type SaveRfmMutation = {
  __typename?: 'SaveRFMMutation';
  frequency?: Maybe<FrequencyRfmType>;
  recency?: Maybe<RecencyRfmType>;
  value?: Maybe<ValueRfmType>;
};

export type SaveWebpicStoreIntegrationInput = {
  companyId: Scalars['String']['input'];
  connected?: InputMaybe<Scalars['Boolean']['input']>;
  haveInvoice?: InputMaybe<Scalars['Boolean']['input']>;
  havePdv?: InputMaybe<Scalars['Boolean']['input']>;
  integrationToken: Scalars['String']['input'];
};

export type SaveWebpicStoreIntegrationMutation = {
  __typename?: 'SaveWebpicStoreIntegrationMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  webpicIntegration?: Maybe<WebpicStoreIntegrationType>;
};

export type SaveWhatsappConfigMutation = {
  __typename?: 'SaveWhatsappConfigMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  whatsappIntegration?: Maybe<WhatsappConfigType>;
};

export type SaveZApiIntegrationMutation = {
  __typename?: 'SaveZApiIntegrationMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  zApiIntegration?: Maybe<ZApiIntegrationType>;
};

export type SendPasswordResetEmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/**
 * Send password reset email.
 *
 * For non verified users, send an activation
 * email instead.
 *
 * Accepts both primary and secondary email.
 *
 * If there is no user with the requested email,
 * a successful response is returned.
 */
export type SendPasswordResetEmailPayload = {
  __typename?: 'SendPasswordResetEmailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SetActiveCustomer = {
  __typename?: 'SetActiveCustomer';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SetActiveCustomerInput = {
  customerId: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type SetBlingStoreIntegration = {
  __typename?: 'SetBlingStoreIntegration';
  blingIntegration?: Maybe<BlingStoreIntegrationType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type SetOmieStoreIntegration = {
  __typename?: 'SetOmieStoreIntegration';
  ok?: Maybe<Scalars['Boolean']['output']>;
  omieIntegration?: Maybe<OmieStoreIntegrationType>;
};

export type SetOmieStoreIntegrationInput = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  appKey: Scalars['String']['input'];
  appSecret: Scalars['String']['input'];
  nfce?: InputMaybe<Scalars['Boolean']['input']>;
  nfe?: InputMaybe<Scalars['Boolean']['input']>;
  nfse?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetPaymentMethod = {
  __typename?: 'SetPaymentMethod';
  ok?: Maybe<Scalars['Boolean']['output']>;
  paymentMethod?: Maybe<PaymentMethodType>;
};

export type SetPaymentMethodInput = {
  adjustmentType?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Decimal']['input']>;
  valueType?: InputMaybe<Scalars['String']['input']>;
};

export type SetSmsBaratoIntegration = {
  __typename?: 'SetSMSBaratoIntegration';
  ok?: Maybe<Scalars['Boolean']['output']>;
  smsBaratoIntegration?: Maybe<SmsBaratoIntegrationType>;
};

export type SetSmsBaratoIntegrationInput = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SetSmsInfoBipIntegration = {
  __typename?: 'SetSMSInfoBipIntegration';
  ok?: Maybe<Scalars['Boolean']['output']>;
  smsInfoBipIntegration?: Maybe<SmsInfoBipIntegrationType>;
};

export type SetSmsInfoBipIntegrationInput = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
};

export type StateType = {
  __typename?: 'StateType';
  id?: Maybe<Scalars['Int']['output']>;
  nome?: Maybe<Scalars['String']['output']>;
  sigla?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum StoreCurrencyType {
  /** Dolar */
  Dolar = 'DOLAR',
  /** Real */
  Real = 'REAL'
}

/** An enumeration. */
export enum StorePlan {
  /** Free */
  Free = 'FREE',
  /** Pro */
  Pro = 'PRO'
}

export type StoreType = {
  __typename?: 'StoreType';
  blingstoreintegration?: Maybe<BlingStoreIntegrationType>;
  campaignSet: CampaignRelayTypeConnection;
  categorySet: CategoryRelayTypeConnection;
  chartsconfig?: Maybe<ChartsConfigType>;
  cnpj: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  credithouseconfig?: Maybe<CreditHouseConfigType>;
  currencyType: StoreCurrencyType;
  customerSet: CustomerTypeRelayConnection;
  customertagsSet: CustomerTagsRelayTypeConnection;
  customuserSet: CustomUserNodeConnection;
  email: Scalars['String']['output'];
  emailproviderstoreintegration?: Maybe<EmailProviderStoreIntegrationType>;
  frequencyrfm?: Maybe<FrequencyRfmType>;
  giftbackSet: GiftbackRelayTypeConnection;
  giftbackconfig?: Maybe<GiftbackConfigType>;
  id: Scalars['ID']['output'];
  integrationToken?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notificationTime: Scalars['Time']['output'];
  omiestoreintegration?: Maybe<OmieStoreIntegrationType>;
  paymentMethods: Array<PaymentMethodType>;
  permissionSet: Array<PermissionType>;
  plan: StorePlan;
  productSet: ProductRelayTypeConnection;
  profileImage?: Maybe<Scalars['String']['output']>;
  purchaseSet: PurchaseRelayTypeConnection;
  recencyrfm?: Maybe<RecencyRfmType>;
  smsbaratointegration?: Maybe<SmsBaratoIntegrationType>;
  smsinfobipintegration?: Maybe<SmsInfoBipIntegrationType>;
  telephone: Scalars['String']['output'];
  traystoreintegration?: Maybe<TrayStoreIntegrationType>;
  updatedAt: Scalars['DateTime']['output'];
  url?: Maybe<Scalars['String']['output']>;
  valuerfm?: Maybe<ValueRfmType>;
  webpicstoreintegration?: Maybe<WebpicStoreIntegrationType>;
  whatsappconfig?: Maybe<WhatsappConfigType>;
  whatsapptemplateSet: Array<WhatsappTemplateType>;
  zapiintegration?: Maybe<ZApiIntegrationType>;
};


export type StoreTypeCampaignSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  tag_Icontains?: InputMaybe<Scalars['String']['input']>;
  titleOrMessage?: InputMaybe<Scalars['String']['input']>;
  viaEmail?: InputMaybe<Scalars['Boolean']['input']>;
  viaSms?: InputMaybe<Scalars['Boolean']['input']>;
  viaWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
};


export type StoreTypeCategorySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type StoreTypeCustomerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  averageTicket?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Gte?: InputMaybe<Scalars['Float']['input']>;
  averageTicket_Lte?: InputMaybe<Scalars['Float']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  city_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  giftback_Status_In?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  rfmClassification?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  state_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
};


export type StoreTypeCustomertagsSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
};


export type StoreTypeCustomuserSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  status_Archived?: InputMaybe<Scalars['Boolean']['input']>;
  status_SecondaryEmail?: InputMaybe<Scalars['String']['input']>;
  status_Verified?: InputMaybe<Scalars['Boolean']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  username_Icontains?: InputMaybe<Scalars['String']['input']>;
  username_Istartswith?: InputMaybe<Scalars['String']['input']>;
};


export type StoreTypeGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type StoreTypeProductSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  categoriesNames?: InputMaybe<Scalars['String']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchaseproduct_Purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
};


export type StoreTypePurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};

export type TaskInput = {
  columnStatusIntegrationId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  responsibleId?: InputMaybe<Scalars['ID']['input']>;
};

export type TaskNode = Node & {
  __typename?: 'TaskNode';
  columnStatus: ColumnStatusNode;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  dueTime?: Maybe<Scalars['Time']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  integrationId: Scalars['String']['output'];
  leadSet: LeadNodeConnection;
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  responsible?: Maybe<CustomUserNode>;
};


export type TaskNodeLeadSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TaskNodeConnection = {
  __typename?: 'TaskNodeConnection';
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<TaskNodeEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
};

/** A Relay edge containing a `TaskNode` and its cursor. */
export type TaskNodeEdge = {
  __typename?: 'TaskNodeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node?: Maybe<TaskNode>;
};

export type TaskType = {
  __typename?: 'TaskType';
  columnStatus: ColumnStatusNode;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Date']['output']>;
  dueTime?: Maybe<Scalars['Time']['output']>;
  id: Scalars['ID']['output'];
  integrationId: Scalars['String']['output'];
  leadSet: LeadNodeConnection;
  name: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  responsible?: Maybe<CustomUserNode>;
};


export type TaskTypeLeadSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type TopCustomersTypeField = {
  __typename?: 'TopCustomersTypeField';
  customerGender?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerPk?: Maybe<Scalars['ID']['output']>;
  purchaseTotalQuantity?: Maybe<Scalars['Int']['output']>;
  purchaseTotalValue?: Maybe<Scalars['Float']['output']>;
};

export type TopProductsTypeField = {
  __typename?: 'TopProductsTypeField';
  productName?: Maybe<Scalars['String']['output']>;
  productPk?: Maybe<Scalars['ID']['output']>;
  totalSold?: Maybe<Scalars['Int']['output']>;
  totalValue?: Maybe<Scalars['Float']['output']>;
};

export type TrayStoreIntegrationType = {
  __typename?: 'TrayStoreIntegrationType';
  acceptedStatusToImport: Scalars['String']['output'];
  apiKey: Scalars['String']['output'];
  authResponse: Scalars['JSONString']['output'];
  code: Scalars['String']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  integrationId: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

export type UfBarChartReturn = {
  __typename?: 'UfBarChartReturn';
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  series?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type UpdateAccountInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  cpf?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Update user model fields, defined on settings.
 *
 * User must be verified.
 */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateBoard = {
  __typename?: 'UpdateBoard';
  board?: Maybe<BoardType>;
};

export type UpdateCampaignInput = {
  buttonLabel?: InputMaybe<Scalars['String']['input']>;
  buttonUrl?: InputMaybe<Scalars['String']['input']>;
  campaignId: Scalars['ID']['input'];
  daysToTrigger?: InputMaybe<Scalars['Int']['input']>;
  footer?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  messageEmail?: InputMaybe<Scalars['String']['input']>;
  messageSms?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  tag?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  viaEmail?: InputMaybe<Scalars['Boolean']['input']>;
  viaSms?: InputMaybe<Scalars['Boolean']['input']>;
  viaWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateCampaignMutation = {
  __typename?: 'UpdateCampaignMutation';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCancelGiftbackInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  giftbackId?: InputMaybe<Scalars['ID']['input']>;
  justification?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCancelGiftbackMutation = {
  __typename?: 'UpdateCancelGiftbackMutation';
  giftback?: Maybe<GiftbackType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCategoryInput = {
  categoryId: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCategoryMutation = {
  __typename?: 'UpdateCategoryMutation';
  error?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateColumnStatus = {
  __typename?: 'UpdateColumnStatus';
  columnStatus?: Maybe<ColumnStatusType>;
};

export type UpdateCustomUserMutation = {
  __typename?: 'UpdateCustomUserMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateCustomer = {
  __typename?: 'UpdateCustomer';
  customer?: Maybe<CustomerType>;
};

export type UpdateCustomerInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<CustomerGender>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  tagsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCustomerTagsInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  tagId: Scalars['ID']['input'];
};

export type UpdateCustomerTagsMutation = {
  __typename?: 'UpdateCustomerTagsMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  tag?: Maybe<CustomerTagsType>;
};

export type UpdateInstance = {
  __typename?: 'UpdateInstance';
  instance?: Maybe<InstanceType>;
};

export type UpdateIntegration = {
  __typename?: 'UpdateIntegration';
  integration?: Maybe<IntegrationType>;
};

export type UpdateLead = {
  __typename?: 'UpdateLead';
  lead?: Maybe<LeadType>;
};

export type UpdateProductInput = {
  categoriesId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  value?: InputMaybe<Scalars['Decimal']['input']>;
};

export type UpdateProductMutation = {
  __typename?: 'UpdateProductMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  product?: Maybe<ProductType>;
};

export type UpdateRedeemGiftbackInput = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  giftbackId?: InputMaybe<Scalars['ID']['input']>;
};

export type UpdateRedeemGiftbackMutation = {
  __typename?: 'UpdateRedeemGiftbackMutation';
  giftback?: Maybe<GiftbackType>;
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateStoreInput = {
  currencyType?: InputMaybe<StoreCurrencyType>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  plan?: InputMaybe<StorePlan>;
  telephone?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateStoreMutation = {
  __typename?: 'UpdateStoreMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
  store?: Maybe<StoreType>;
};

export type UpdateTask = {
  __typename?: 'UpdateTask';
  task?: Maybe<TaskType>;
};

export type UpdateUserConnectedStoreMutation = {
  __typename?: 'UpdateUserConnectedStoreMutation';
  ok?: Maybe<Scalars['Boolean']['output']>;
};

export type UpdateUserInput = {
  birthdate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<CustomerGender>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
  userType?: InputMaybe<PermissionUserType>;
};

export type UserAcceptationType = {
  __typename?: 'UserAcceptationType';
  acceptationTerm: AcceptationTermType;
  acceptedAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  user: CustomUserNode;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  archived?: Maybe<Scalars['Boolean']['output']>;
  birthdate?: Maybe<Scalars['Date']['output']>;
  boardSet: BoardNodeConnection;
  colaborators: BoardNodeConnection;
  connectedStore?: Maybe<StoreType>;
  cpf?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customeractivitySet: CustomerActivitiesTypeRelayConnection;
  dateJoined: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<CustomUserGender>;
  giftbackSet: GiftbackRelayTypeConnection;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  instanceSet: InstanceNodeConnection;
  integrationSet: IntegrationNodeConnection;
  /** Indica que o usuário será tratado como ativo. Ao invés de excluir contas de usuário, desmarque isso. */
  isActive: Scalars['Boolean']['output'];
  /** Indica que usuário consegue acessar este site de administração. */
  isStaff: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  permissions: Array<PermissionType>;
  phone?: Maybe<Scalars['String']['output']>;
  pk?: Maybe<Scalars['Int']['output']>;
  profile: CustomUserProfile;
  purchaseSet: PurchaseRelayTypeConnection;
  redeemedGiftbacks: GiftbackRelayTypeConnection;
  secondaryEmail?: Maybe<Scalars['String']['output']>;
  taskSet: TaskNodeConnection;
  updatedAt: Scalars['DateTime']['output'];
  useracceptationSet: Array<UserAcceptationType>;
  /** Obrigatório. 150 caracteres ou menos. Letras, números e @/./+/-/_ apenas. */
  username: Scalars['String']['output'];
  verified?: Maybe<Scalars['Boolean']['output']>;
};


export type UserNodeBoardSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type UserNodeColaboratorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type UserNodeCustomeractivitySetArgs = {
  activity?: InputMaybe<Scalars['String']['input']>;
  activity_In?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  giftback_Id?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  includesCampaignMessage?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase_Id?: InputMaybe<Scalars['ID']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  user_Email?: InputMaybe<Scalars['String']['input']>;
  user_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  user_Id?: InputMaybe<Scalars['ID']['input']>;
};


export type UserNodeGiftbackSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type UserNodeInstanceSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type UserNodeIntegrationSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type UserNodePurchaseSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Phone?: InputMaybe<Scalars['String']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['Date']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  integrationId?: InputMaybe<Scalars['String']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  productIds?: InputMaybe<Scalars['String']['input']>;
  purchaseproduct_Product_Id?: InputMaybe<Scalars['ID']['input']>;
  sellerName?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  storeId?: InputMaybe<Scalars['ID']['input']>;
  store_Id?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type UserNodeRedeemedGiftbacksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  customer_Email?: InputMaybe<Scalars['String']['input']>;
  customer_Email_Icontains?: InputMaybe<Scalars['String']['input']>;
  customer_Name?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  purchase_Date?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  purchase_In?: InputMaybe<Scalars['ID']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  status_In?: InputMaybe<Scalars['String']['input']>;
  store?: InputMaybe<Scalars['ID']['input']>;
  store_Name?: InputMaybe<Scalars['String']['input']>;
  store_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
};


export type UserNodeTaskSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  columnStatus?: InputMaybe<Scalars['ID']['input']>;
  columnStatus_IntegrationId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lt?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  dueTime?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Gte?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lt?: InputMaybe<Scalars['Time']['input']>;
  dueTime_Lte?: InputMaybe<Scalars['Time']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  responsible?: InputMaybe<Scalars['ID']['input']>;
};

export type ValueRfmInput = {
  zoneFiveMax?: InputMaybe<Scalars['Decimal']['input']>;
  zoneFiveMin?: InputMaybe<Scalars['Decimal']['input']>;
  zoneFourMax?: InputMaybe<Scalars['Decimal']['input']>;
  zoneFourMin?: InputMaybe<Scalars['Decimal']['input']>;
  zoneOneMax?: InputMaybe<Scalars['Decimal']['input']>;
  zoneOneMin?: InputMaybe<Scalars['Decimal']['input']>;
  zoneThreeMax?: InputMaybe<Scalars['Decimal']['input']>;
  zoneThreeMin?: InputMaybe<Scalars['Decimal']['input']>;
  zoneTwoMax?: InputMaybe<Scalars['Decimal']['input']>;
  zoneTwoMin?: InputMaybe<Scalars['Decimal']['input']>;
};

export type ValueRfmType = {
  __typename?: 'ValueRFMType';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
  zoneFiveMax: Scalars['Float']['output'];
  zoneFiveMin: Scalars['Float']['output'];
  zoneFourMax: Scalars['Float']['output'];
  zoneFourMin: Scalars['Float']['output'];
  zoneOneMax: Scalars['Float']['output'];
  zoneOneMin: Scalars['Float']['output'];
  zoneThreeMax: Scalars['Float']['output'];
  zoneThreeMin: Scalars['Float']['output'];
  zoneTwoMax: Scalars['Float']['output'];
  zoneTwoMin: Scalars['Float']['output'];
};

export type VerifyAccountInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/**
 * Verify user account.
 *
 * Receive the token that was sent by email.
 * If the token is valid, make the user verified
 * by making the `user.status.verified` field true.
 */
export type VerifyAccountPayload = {
  __typename?: 'VerifyAccountPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type VerifyTokenInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
};

/** Same as `grapgql_jwt` implementation, with standard output. */
export type VerifyTokenPayload = {
  __typename?: 'VerifyTokenPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Scalars['ExpectedErrorType']['output']>;
  payload?: Maybe<Scalars['GenericScalar']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type WebpicStoreIntegrationType = {
  __typename?: 'WebpicStoreIntegrationType';
  accessToken: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiresIn?: Maybe<Scalars['Date']['output']>;
  haveInvoice: Scalars['Boolean']['output'];
  havePdv: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  integrationToken: Scalars['String']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  updatedAt: Scalars['DateTime']['output'];
};

export type WhatsappConfigInput = {
  activated?: InputMaybe<Scalars['Boolean']['input']>;
  phoneNumberId?: InputMaybe<Scalars['String']['input']>;
  whatsappAccessToken?: InputMaybe<Scalars['String']['input']>;
  whatsappAppBusinessId?: InputMaybe<Scalars['String']['input']>;
  whatsappBusinessAccountId?: InputMaybe<Scalars['String']['input']>;
};

export type WhatsappConfigType = {
  __typename?: 'WhatsappConfigType';
  activated: Scalars['Boolean']['output'];
  connected: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  facebookAppSecret?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  phoneNumberId?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  store?: Maybe<StoreType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  webhookCallbackUrl?: Maybe<Scalars['String']['output']>;
  webhookVerificationToken?: Maybe<Scalars['String']['output']>;
  whatsappAccessToken?: Maybe<Scalars['String']['output']>;
  whatsappAppBusinessId: Scalars['String']['output'];
  whatsappBusinessAccountId: Scalars['String']['output'];
  whatsappPhoneNumber?: Maybe<Scalars['String']['output']>;
};

/** An enumeration. */
export enum WhatsappTemplateCategory {
  /** Autenticação */
  Authentication = 'AUTHENTICATION',
  /** Marketing */
  Marketing = 'MARKETING',
  /** Utilidade */
  Utility = 'UTILITY'
}

/** An enumeration. */
export enum WhatsappTemplateStatus {
  /** Aprovado */
  Approved = 'APPROVED',
  /** Em Análise */
  Pending = 'PENDING',
  /** Rejeitado */
  Rejected = 'REJECTED'
}

export type WhatsappTemplateType = {
  __typename?: 'WhatsappTemplateType';
  allowCategoryChange: Scalars['Boolean']['output'];
  campaign?: Maybe<CampaignRelayType>;
  category?: Maybe<WhatsappTemplateCategory>;
  components?: Maybe<Scalars['JSONString']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  language: Scalars['String']['output'];
  name: Scalars['String']['output'];
  newQualityScore?: Maybe<Scalars['String']['output']>;
  pk: Scalars['Int']['output'];
  previousQualityScore?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<WhatsappTemplateStatus>;
  store?: Maybe<StoreType>;
  templateId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ZApiIntegrationInput = {
  token?: InputMaybe<Scalars['String']['input']>;
  zApiId?: InputMaybe<Scalars['String']['input']>;
  zApiToken?: InputMaybe<Scalars['String']['input']>;
};

export type ZApiIntegrationType = {
  __typename?: 'ZApiIntegrationType';
  connected: Scalars['Boolean']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  pk: Scalars['Int']['output'];
  store: StoreType;
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zApiId: Scalars['String']['output'];
  zApiToken: Scalars['String']['output'];
};

export type CreateNewCampaignMutationVariables = Exact<{
  campaignInput: CreateCampaignInput;
}>;


export type CreateNewCampaignMutation = { __typename?: 'Mutation', createCampaign?: { __typename?: 'CreateCampaignMutation', success?: boolean | null, error?: string | null } | null };

export type GetCampaignDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCampaignDetailsQuery = { __typename?: 'Query', readCampaign?: { __typename?: 'CampaignType', pk: number, title: string, messageSms?: string | null, message: string, daysToTrigger: number, tag: CampaignTag, buttonLabel?: string | null, buttonUrl?: string | null, createdAt: any, updatedAt: any, footer?: string | null, viaWhatsapp: boolean, viaSms: boolean, viaEmail: boolean, whatsappTemplate?: { __typename?: 'WhatsappTemplateType', pk: number, category?: WhatsappTemplateCategory | null, status?: WhatsappTemplateStatus | null } | null } | null };

export type ListCampaignsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  titleOrMessage?: InputMaybe<Scalars['String']['input']>;
  viaWhatsapp?: InputMaybe<Scalars['Boolean']['input']>;
  viaSms?: InputMaybe<Scalars['Boolean']['input']>;
  viaEmail?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt_Gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_Lte?: InputMaybe<Scalars['DateTime']['input']>;
  tag_Icontains?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListCampaignsQuery = { __typename?: 'Query', listCampaigns?: { __typename?: 'CampaignRelayTypeConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CampaignRelayTypeEdge', node?: { __typename?: 'CampaignRelayType', pk: number, id: string, tag: CampaignTag, title: string, messageSms?: string | null, message: string, buttonLabel?: string | null, buttonUrl?: string | null, createdAt: any, updatedAt: any, viaWhatsapp: boolean, viaSms: boolean, viaEmail: boolean } | null } | null> } | null };

export type EditCampaignMutationVariables = Exact<{
  campaignInput: UpdateCampaignInput;
}>;


export type EditCampaignMutation = { __typename?: 'Mutation', updateCampaign?: { __typename?: 'UpdateCampaignMutation', success?: boolean | null, error?: string | null } | null };

export type GetAllRfmQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRfmQuery = { __typename?: 'Query', frequency?: { __typename?: 'FrequencyRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null, recency?: { __typename?: 'RecencyRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null, value?: { __typename?: 'ValueRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null };

export type GetGiftbackConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGiftbackConfigQuery = { __typename?: 'Query', getGiftbackConfig?: { __typename?: 'GiftbackConfigType', id: string, value?: number | null, valueType: GiftbackConfigValueType, dueDate: number, cumulative?: boolean | null, minPurchaseValueForCreate?: number | null, minPurchaseValueForRedeem?: number | null, minimumValueType: GiftbackConfigMinimumValueType, maximumDiscountPercentage?: number | null, discountLimitEnabled: boolean, discountLimit: number, pk: number } | null };

export type GetWhatsappIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWhatsappIntegrationQuery = { __typename?: 'Query', getWhatsappIntegration?: { __typename?: 'WhatsappConfigType', id: string, whatsappAppBusinessId: string, whatsappBusinessAccountId: string, whatsappAccessToken?: string | null, phoneNumberId?: string | null, activated: boolean, connected: boolean } | null };

export type GetRfmFrequencyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRfmFrequencyQuery = { __typename?: 'Query', getRfmFrequency?: { __typename?: 'FrequencyRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null };

export type GetRfmRecencyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRfmRecencyQuery = { __typename?: 'Query', getRfmRecency?: { __typename?: 'RecencyRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null };

export type GetRfmValueQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRfmValueQuery = { __typename?: 'Query', getRfmValue?: { __typename?: 'ValueRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null };

export type GetStoreIntegrationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreIntegrationsQuery = { __typename?: 'Query', infoIntegration?: { __typename?: 'StoreType', traystoreintegration?: { __typename?: 'TrayStoreIntegrationType', integrationId: string, code: string, apiKey: string, connected: boolean } | null, whatsappconfig?: { __typename?: 'WhatsappConfigType', id: string, connected: boolean, activated: boolean, whatsappAccessToken?: string | null, whatsappPhoneNumber?: string | null, phoneNumberId?: string | null, whatsappAppBusinessId: string, whatsappBusinessAccountId: string } | null, omiestoreintegration?: { __typename?: 'OmieStoreIntegrationType', id: string, appKey: string, appSecret: string, connected: boolean, activated: boolean, nfce: boolean, nfe: boolean, nfse: boolean } | null, smsinfobipintegration?: { __typename?: 'SMSInfoBipIntegrationType', id: string, connected: boolean, activated: boolean } | null, blingstoreintegration?: { __typename?: 'BlingStoreIntegrationType', id: string, connected: boolean, activated: boolean } | null, webpicstoreintegration?: { __typename?: 'WebpicStoreIntegrationType', id: string, integrationToken: string, companyId: string, havePdv: boolean, haveInvoice: boolean, connected: boolean } | null, emailproviderstoreintegration?: { __typename?: 'EmailProviderStoreIntegrationType', emailToSend: string, provider: EmailProviderStoreIntegrationProvider, isActive: boolean } | null } | null };

export type GetZapiIntegrationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetZapiIntegrationQuery = { __typename?: 'Query', getZApiIntegration?: { __typename?: 'ZApiIntegrationType', zApiId: string, zApiToken: string, token?: string | null, pk: number, connected: boolean } | null };

export type BlingIntegrationMutationVariables = Exact<{
  code?: InputMaybe<Scalars['String']['input']>;
}>;


export type BlingIntegrationMutation = { __typename?: 'Mutation', setBlingStoreIntegration?: { __typename?: 'SetBlingStoreIntegration', ok?: boolean | null, blingIntegration?: { __typename?: 'BlingStoreIntegrationType', pk: number } | null } | null };

export type UpdateGiftbackConfigMutationVariables = Exact<{
  input?: InputMaybe<GiftbackConfigInput>;
}>;


export type UpdateGiftbackConfigMutation = { __typename?: 'Mutation', saveGiftbackConfig?: { __typename?: 'SaveGiftbackConfigMutation', giftback?: { __typename?: 'GiftbackConfigType', id: string, value?: number | null, valueType: GiftbackConfigValueType, dueDate: number, cumulative?: boolean | null, minPurchaseValueForCreate?: number | null, minPurchaseValueForRedeem?: number | null, minimumValueType: GiftbackConfigMinimumValueType, maximumDiscountPercentage?: number | null, pk: number } | null } | null };

export type OmieIntegrationMutationVariables = Exact<{
  input: SetOmieStoreIntegrationInput;
}>;


export type OmieIntegrationMutation = { __typename?: 'Mutation', setOmieStoreIntegration?: { __typename?: 'SetOmieStoreIntegration', ok?: boolean | null, omieIntegration?: { __typename?: 'OmieStoreIntegrationType', appKey: string, appSecret: string, connected: boolean, activated: boolean, nfce: boolean, nfe: boolean, nfse: boolean } | null } | null };

export type SmsInfobipIntegrationMutationVariables = Exact<{
  input: SetSmsInfoBipIntegrationInput;
}>;


export type SmsInfobipIntegrationMutation = { __typename?: 'Mutation', setSmsInfoBipIntegration?: { __typename?: 'SetSMSInfoBipIntegration', ok?: boolean | null, smsInfoBipIntegration?: { __typename?: 'SMSInfoBipIntegrationType', id: string, connected: boolean, activated: boolean, store: { __typename?: 'StoreType', name: string } } | null } | null };

export type CreateTrayStoreIntegrationMutationVariables = Exact<{
  trayInput: CreateTrayStoreIntegrationInput;
}>;


export type CreateTrayStoreIntegrationMutation = { __typename?: 'Mutation', createTrayStoreIntegration?: { __typename?: 'CreateTrayStoreIntegration', success?: boolean | null, error?: string | null } | null };

export type CreateWebPicIntegrationMutationVariables = Exact<{
  input: SaveWebpicStoreIntegrationInput;
}>;


export type CreateWebPicIntegrationMutation = { __typename?: 'Mutation', saveWebpicStoreIntegration?: { __typename?: 'SaveWebpicStoreIntegrationMutation', ok?: boolean | null } | null };

export type SaveWhatsappIntegrationMutationVariables = Exact<{
  input?: InputMaybe<WhatsappConfigInput>;
}>;


export type SaveWhatsappIntegrationMutation = { __typename?: 'Mutation', saveWhatsapp?: { __typename?: 'SaveWhatsappConfigMutation', ok?: boolean | null, whatsappIntegration?: { __typename?: 'WhatsappConfigType', id: string, whatsappAccessToken?: string | null, phoneNumberId?: string | null, whatsappAppBusinessId: string, whatsappBusinessAccountId: string, connected: boolean, activated: boolean, pk: number } | null } | null };

export type SetEmailProviderMutationVariables = Exact<{
  input?: InputMaybe<EmailProviderStoreIntegrationInput>;
}>;


export type SetEmailProviderMutation = { __typename?: 'Mutation', saveEmailProvider?: { __typename?: 'SaveEmailProviderStoreIntegrationMutation', ok?: boolean | null, emailProvider?: { __typename?: 'EmailProviderStoreIntegrationType', emailToSend: string, provider: EmailProviderStoreIntegrationProvider, isActive: boolean } | null } | null };

export type UpdateRfmConfigMutationVariables = Exact<{
  recency: RecencyRfmInput;
  frequency: FrequencyRfmInput;
  value: ValueRfmInput;
}>;


export type UpdateRfmConfigMutation = { __typename?: 'Mutation', saveRfmConfig?: { __typename?: 'SaveRFMMutation', recency?: { __typename?: 'RecencyRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null, frequency?: { __typename?: 'FrequencyRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null, value?: { __typename?: 'ValueRFMType', id: string, zoneOneMin: number, zoneOneMax: number, zoneTwoMin: number, zoneTwoMax: number, zoneThreeMin: number, zoneThreeMax: number, zoneFourMin: number, zoneFourMax: number, zoneFiveMin: number, zoneFiveMax: number } | null } | null };

export type SaveZapiIntegrationMutationVariables = Exact<{
  input: ZApiIntegrationInput;
}>;


export type SaveZapiIntegrationMutation = { __typename?: 'Mutation', saveZApi?: { __typename?: 'SaveZApiIntegrationMutation', ok?: boolean | null, zApiIntegration?: { __typename?: 'ZApiIntegrationType', zApiId: string, zApiToken: string, token?: string | null, connected: boolean, pk: number } | null } | null };

export type CreateCustomerMutationVariables = Exact<{
  input: CreateCustomerInput;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer?: { __typename?: 'CreateCustomer', customer?: { __typename?: 'CustomerType', id: string, name?: string | null, taxDocument?: string | null } | null } | null };

export type CustomerExcelReportQueryVariables = Exact<{
  input?: InputMaybe<CustomerExcelReportInput>;
}>;


export type CustomerExcelReportQuery = { __typename?: 'Query', customerExcelReport?: { __typename?: 'CustomerExcelReportType', relatorio?: string | null } | null };

export type GetAllStateCitiesQueryVariables = Exact<{
  uf: Scalars['String']['input'];
}>;


export type GetAllStateCitiesQuery = { __typename?: 'Query', allStateCities?: Array<{ __typename?: 'CityType', id?: number | null, nome?: string | null } | null> | null };

export type GetAllStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllStatesQuery = { __typename?: 'Query', allStates?: Array<{ __typename?: 'StateType', id?: number | null, sigla?: string | null, nome?: string | null } | null> | null };

export type GetCustomerDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCustomerDetailsQuery = { __typename?: 'Query', getCustomer?: { __typename?: 'CustomerType', id: string, name?: string | null, email?: string | null, phone?: string | null, taxDocument?: string | null, importedFrom: CustomerImportedFrom, gender?: CustomerGender | null, state?: string | null, city?: string | null, rfmClassification?: string | null, averageTicket: number, integrationId?: string | null, dateOfBirth?: any | null, isActive: boolean, notes: string, tags: { __typename?: 'CustomerTagsRelayTypeConnection', edges: Array<{ __typename?: 'CustomerTagsRelayTypeEdge', node?: { __typename?: 'CustomerTagsRelayType', name: string, pk: number } | null } | null> }, giftbackSet: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null, edges: Array<{ __typename?: 'GiftbackRelayTypeEdge', node?: { __typename?: 'GiftbackRelayType', value: number, dueDate: any, code: string, pk: number } | null } | null> } } | null };

export type ListCustomerGiftbacksQueryVariables = Exact<{
  customer?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ListCustomerGiftbacksQuery = { __typename?: 'Query', allGiftbacksRelay?: { __typename?: 'GiftbackRelayTypeConnection', edges: Array<{ __typename?: 'GiftbackRelayTypeEdge', node?: { __typename?: 'GiftbackRelayType', pk: number, code: string, value: number, dueDate: any, status: GiftbackStatus, createdAt: any, updatedAt: any, customeractivitySet: { __typename?: 'CustomerActivitiesTypeRelayConnection', edges: Array<{ __typename?: 'CustomerActivitiesTypeRelayEdge', node?: { __typename?: 'CustomerActivitiesTypeRelay', activity?: CustomerActivityActivity | null, title?: string | null, description: string, pk: number, createdAt: any, updatedAt: any } | null } | null> } } | null } | null> } | null };

export type ListCustomerActivitiesQueryVariables = Exact<{
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ListCustomerActivitiesQuery = { __typename?: 'Query', allCustomerActivitiesRelay?: { __typename?: 'CustomerActivitiesTypeRelayConnection', edges: Array<{ __typename?: 'CustomerActivitiesTypeRelayEdge', node?: { __typename?: 'CustomerActivitiesTypeRelay', activity?: CustomerActivityActivity | null, pk: number, title?: string | null, description: string, updatedAt: any, user?: { __typename?: 'CustomUserNode', pk?: number | null, email: string } | null, giftback?: { __typename?: 'GiftbackRelayType', value: number, dueDate: any, pk: number, status: GiftbackStatus, createdAt: any, updatedAt: any, customeractivitySet: { __typename?: 'CustomerActivitiesTypeRelayConnection', edges: Array<{ __typename?: 'CustomerActivitiesTypeRelayEdge', node?: { __typename?: 'CustomerActivitiesTypeRelay', title?: string | null, description: string, createdAt: any } | null } | null> } } | null, purchase?: { __typename?: 'PurchaseRelayType', pk: number, value: number, date?: any | null, canceledBy?: string | null, canceledAt?: any | null, createdAt: any, updatedAt: any } | null } | null } | null> } | null };

export type ListCustomerQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth_Gte?: InputMaybe<Scalars['Date']['input']>;
  dateOfBirth_Lte?: InputMaybe<Scalars['Date']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  rfmClassification_In?: InputMaybe<Scalars['String']['input']>;
  purchase_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  giftback_Status?: InputMaybe<Scalars['String']['input']>;
  createdAt_Date_Gte?: InputMaybe<Scalars['Date']['input']>;
  createdAt_Date_Lte?: InputMaybe<Scalars['Date']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  tags_Name_In?: InputMaybe<Scalars['String']['input']>;
  gender_In?: InputMaybe<Scalars['String']['input']>;
  notes_Icontains?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type ListCustomerQuery = { __typename?: 'Query', giftbackAll?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null } | null, giftbackActive?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null } | null, giftbackExpired?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null } | null, giftbackRedeemed?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null } | null, giftbackCancelled?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null } | null, giftbackOverdue?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null } | null, allCustomersRelay?: { __typename?: 'CustomerTypeRelayConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CustomerTypeRelayEdge', node?: { __typename?: 'CustomerTypeRelay', id: string, pk: number, rfmClassification?: string | null, name?: string | null, email?: string | null, phone?: string | null, dateOfBirth?: any | null, importedFrom: CustomerImportedFrom, averageTicket: number, giftbackSet: { __typename?: 'GiftbackRelayTypeConnection', edges: Array<{ __typename?: 'GiftbackRelayTypeEdge', node?: { __typename?: 'GiftbackRelayType', pk: number, status: GiftbackStatus } | null } | null> }, purchaseSet: { __typename?: 'PurchaseRelayTypeConnection', totalCount?: number | null }, tags: { __typename?: 'CustomerTagsRelayTypeConnection', edges: Array<{ __typename?: 'CustomerTagsRelayTypeEdge', node?: { __typename?: 'CustomerTagsRelayType', name: string, pk: number } | null } | null> } } | null } | null> } | null };

export type SetActiveCustomerMutationVariables = Exact<{
  input: SetActiveCustomerInput;
}>;


export type SetActiveCustomerMutation = { __typename?: 'Mutation', setActiveCustomer?: { __typename?: 'SetActiveCustomer', ok?: boolean | null } | null };

export type UpdateCustomerMutationVariables = Exact<{
  input: UpdateCustomerInput;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer?: { __typename?: 'UpdateCustomer', customer?: { __typename?: 'CustomerType', id: string, name?: string | null, email?: string | null, phone?: string | null, taxDocument?: string | null, gender?: CustomerGender | null, state?: string | null, city?: string | null, dateOfBirth?: any | null } | null } | null };

export type CreateCustomerNewTagsMutationVariables = Exact<{
  tagInput: CreateCustomerTagsInput;
}>;


export type CreateCustomerNewTagsMutation = { __typename?: 'Mutation', tagsCreate?: { __typename?: 'CreateCustomerTagsMutation', ok?: boolean | null } | null };

export type GetAllCustomerTagsQueryVariables = Exact<{
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllCustomerTagsQuery = { __typename?: 'Query', customerTagsList?: { __typename?: 'CustomerTagsRelayTypeConnection', edges: Array<{ __typename?: 'CustomerTagsRelayTypeEdge', node?: { __typename?: 'CustomerTagsRelayType', name: string, pk: number } | null } | null> } | null };

export type DashboardAgeRangeQueriesQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type DashboardAgeRangeQueriesQuery = { __typename?: 'Query', dashboardAgeColumnChartValues?: { __typename?: 'AgeColumnChartReturn', categories?: Array<string | null> | null, series?: Array<{ __typename?: 'AgeColumnChartSeries', name?: string | null, data?: Array<number | null> | null } | null> | null } | null };

export type DashboardCustomerGenderDataQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type DashboardCustomerGenderDataQuery = { __typename?: 'Query', dashboardGenderPieChartValues?: { __typename?: 'GenderPieChartReturn', total?: number | null, labels?: Array<string | null> | null, series?: Array<number | null> | null } | null };

export type DashboardCustomerQueriesQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type DashboardCustomerQueriesQuery = { __typename?: 'Query', cardsData?: { __typename?: 'CustomerDashboardCardsReturn', totalCustomers?: number | null, averageValuePerCustomer?: number | null, averagePurchaseCountPerCustomer?: number | null } | null, rfmData?: Array<{ __typename?: 'RfmChartReturn', x?: string | null, y?: number | null } | null> | null };

export type DashboardCustomerTagsQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type DashboardCustomerTagsQuery = { __typename?: 'Query', dashboardCustomerTagsValues?: { __typename?: 'CustomerTagsDonutChartReturn', series?: Array<number | null> | null, labels?: Array<string | null> | null } | null };

export type DashboardCustomersByCityChartsQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type DashboardCustomersByCityChartsQuery = { __typename?: 'Query', dashboardCustomersByCity?: { __typename?: 'DashboardCustomersByCityResponse', total?: number | null, categories?: Array<string | null> | null, series?: Array<number | null> | null } | null };

export type DashboardCustomersByUfChartsQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type DashboardCustomersByUfChartsQuery = { __typename?: 'Query', dashboardUfBarChartValues?: { __typename?: 'UfBarChartReturn', total?: number | null, categories?: Array<string | null> | null, series?: Array<number | null> | null } | null };

export type DashboardGiftbackQueriesQueryVariables = Exact<{
  input?: InputMaybe<GiftbackFilterSetInput>;
}>;


export type DashboardGiftbackQueriesQuery = { __typename?: 'Query', cardsData?: { __typename?: 'GiftbackCardsValues', activeGiftbacks?: number | null, redeemedGiftbacks?: number | null, expiredGiftbacks?: number | null, giftbackGains?: number | null, cancelledGiftbacks?: number | null, overdueGiftbacks?: number | null } | null, averageTicketData?: { __typename?: 'GiftbackAverageTickets', activeGiftbackAverageTicket?: number | null, redeemedGiftbackAverageTicket?: number | null, expiredGiftbackAverageTicket?: number | null, cancelledGiftbackAverageTicket?: number | null, overdueGiftbackAverageTicket?: number | null } | null, radialChartData?: { __typename?: 'GiftbackRadialBarCharReturn', series?: Array<number | null> | null, labels?: Array<string | null> | null, total?: number | null } | null, areaChartData?: { __typename?: 'GiftbackAreaChartReturn', kindOfDate?: string | null, categories?: Array<string | null> | null, series?: Array<{ __typename?: 'GiftbackAreaChartSeries', name?: string | null, data?: Array<{ __typename?: 'GiftbackAreaChartSeriesData', value?: number | null, date?: string | null } | null> | null } | null> | null } | null };

export type DashboardGiftbackResumeQueryVariables = Exact<{
  input?: InputMaybe<GiftbackFilterSetInput>;
}>;


export type DashboardGiftbackResumeQuery = { __typename?: 'Query', dashboardGiftbacksRadialBarChartValues?: { __typename?: 'GiftbackRadialBarCharReturn', series?: Array<number | null> | null, labels?: Array<string | null> | null, total?: number | null } | null };

export type DashboardSalesQueriesQueryVariables = Exact<{
  input?: InputMaybe<PurchaseFilterSetInput>;
  productInput?: InputMaybe<PurchaseProductFilterSetInput>;
}>;


export type DashboardSalesQueriesQuery = { __typename?: 'Query', cardsData?: { __typename?: 'PurchaseCardsValues', purchasesCount?: number | null, totalValue?: number | null, purchaseAverageTicket?: number | null } | null, periodData?: { __typename?: 'PurchaseColumnChartReturn', kindOfDate?: string | null, labels?: Array<string | null> | null, series?: Array<{ __typename?: 'PurchaseColumnChartSeries', name?: string | null, type?: string | null, data?: Array<number | null> | null } | null> | null } | null, abcData?: { __typename?: 'ProductListTableType', total?: number | null, groups?: Array<{ __typename?: 'ProductsCurveAbcColumnGroupsData', title?: string | null, cols?: number | null } | null> | null, productsList?: Array<{ __typename?: 'ProductListItemsType', productName?: string | null, productQuantity?: number | null, productTotalValue?: number | null, productIndividualPercentage?: number | null, productAccumulatedPercentage?: number | null, productAbcClassification?: string | null, productPk?: string | null } | null> | null } | null };

export type CancelGiftbackMutationVariables = Exact<{
  giftbackInput: UpdateCancelGiftbackInput;
}>;


export type CancelGiftbackMutation = { __typename?: 'Mutation', giftbackCancel?: { __typename?: 'UpdateCancelGiftbackMutation', ok?: boolean | null } | null };

export type CreateGiftbackManualMutationVariables = Exact<{
  giftbackInput: CreateGiftbackInput;
}>;


export type CreateGiftbackManualMutation = { __typename?: 'Mutation', giftbackCreate?: { __typename?: 'CreateGiftbackMutation', ok?: boolean | null, giftback?: { __typename?: 'GiftbackType', pk: number } | null } | null };

export type GiftbackExcelReportQueryVariables = Exact<{
  input?: InputMaybe<GiftbackFilterSetInput>;
}>;


export type GiftbackExcelReportQuery = { __typename?: 'Query', giftbackExcelReport?: { __typename?: 'GiftbackExcelReportType', relatorio?: string | null } | null };

export type GetGiftbackQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetGiftbackQuery = { __typename?: 'Query', giftback?: { __typename?: 'GiftbackType', code: string, value: number, minValueToRedeem: number, dueDate: any, id: string, pk: number, status: GiftbackStatus, redeemedAt?: any | null, importedFrom: GiftbackImportedFrom, justification: string, redeemedBy?: { __typename?: 'CustomUserNode', pk?: number | null, name?: string | null, email: string } | null, customer: { __typename?: 'CustomerTypeRelay', pk: number, name?: string | null, email?: string | null, phone?: string | null }, purchaseUsed?: { __typename?: 'PurchaseRelayType', pk: number, date?: any | null, value: number } | null, purchase?: { __typename?: 'PurchaseRelayType', pk: number, date?: any | null, value: number } | null } | null, historic?: { __typename?: 'CustomerActivitiesTypeRelayConnection', edges: Array<{ __typename?: 'CustomerActivitiesTypeRelayEdge', node?: { __typename?: 'CustomerActivitiesTypeRelay', title?: string | null, description: string, updatedAt: any, pk: number } | null } | null> } | null };

export type ListGiftbacksQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
  dueDate_Gte?: InputMaybe<Scalars['Date']['input']>;
  dueDate_Lte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Lte?: InputMaybe<Scalars['Date']['input']>;
  redeemedAt_Gte?: InputMaybe<Scalars['Date']['input']>;
  purchase?: InputMaybe<Scalars['ID']['input']>;
  customer?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  customer_Name_Icontains?: InputMaybe<Scalars['String']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListGiftbacksQuery = { __typename?: 'Query', giftbackAll?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null } | null, giftbackActive?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null } | null, giftbackExpired?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null } | null, giftbackRedeemed?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null } | null, giftbackCancelled?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null } | null, giftbackOverdue?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null } | null, allGiftbacksRelay?: { __typename?: 'GiftbackRelayTypeConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'GiftbackRelayTypeEdge', node?: { __typename?: 'GiftbackRelayType', id: string, pk: number, code: string, status: GiftbackStatus, value: number, dueDate: any, minValueToRedeem: number, importedFrom: GiftbackImportedFrom, customer: { __typename?: 'CustomerTypeRelay', name?: string | null, email?: string | null }, purchase?: { __typename?: 'PurchaseRelayType', pk: number } | null, customeractivitySet: { __typename?: 'CustomerActivitiesTypeRelayConnection', edges: Array<{ __typename?: 'CustomerActivitiesTypeRelayEdge', node?: { __typename?: 'CustomerActivitiesTypeRelay', activity?: CustomerActivityActivity | null, title?: string | null, description: string, pk: number } | null } | null> } } | null } | null> } | null };

export type RedeemGiftbackMutationVariables = Exact<{
  giftbackInput: UpdateRedeemGiftbackInput;
}>;


export type RedeemGiftbackMutation = { __typename?: 'Mutation', giftbackRedeem?: { __typename?: 'UpdateRedeemGiftbackMutation', ok?: boolean | null, giftback?: { __typename?: 'GiftbackType', redeemedAt?: any | null } | null } | null };

export type GetHomeCardsValuesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomeCardsValuesQuery = { __typename?: 'Query', homepageCardsValues?: { __typename?: 'HomePageCards', totalCustomers?: number | null, totalPurchase?: number | null, totalActiveGiftbacks?: number | null, purchaseAverageTicket?: number | null, totalValuePurchase?: number | null, totalValueRedeemedGiftbacks?: number | null } | null };

export type HomepageTopCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type HomepageTopCustomersQuery = { __typename?: 'Query', homepageTopCustomers?: Array<{ __typename?: 'TopCustomersTypeField', customerName?: string | null, customerGender?: string | null, purchaseTotalQuantity?: number | null, purchaseTotalValue?: number | null, customerPk?: string | null } | null> | null };

export type HomepageTopProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type HomepageTopProductsQuery = { __typename?: 'Query', homepageTopProducts?: Array<{ __typename?: 'TopProductsTypeField', productName?: string | null, totalValue?: number | null, totalSold?: number | null, productPk?: string | null } | null> | null };

export type GetRfmChartValuesQueryVariables = Exact<{
  input?: InputMaybe<CustomerFilterSetInput>;
}>;


export type GetRfmChartValuesQuery = { __typename?: 'Query', rfmData?: Array<{ __typename?: 'RfmChartReturn', x?: string | null, y?: number | null } | null> | null };

export type CreatePurchasePdvMutationVariables = Exact<{
  purchaseInput: CreatePurchaseInput;
}>;


export type CreatePurchasePdvMutation = { __typename?: 'Mutation', purchaseCreate?: { __typename?: 'CreatePurchaseMutation', success?: boolean | null, error?: string | null, purchase?: { __typename?: 'PurchaseType', pk: number } | null } | null };

export type GetCustomerStepQueryVariables = Exact<{
  phone?: InputMaybe<Scalars['String']['input']>;
  phoneOrTaxDocument?: InputMaybe<Scalars['String']['input']>;
  taxDocument?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
}>;


export type GetCustomerStepQuery = { __typename?: 'Query', allCustomersRelay?: { __typename?: 'CustomerTypeRelayConnection', edges: Array<{ __typename?: 'CustomerTypeRelayEdge', node?: { __typename?: 'CustomerTypeRelay', pk: number, name?: string | null, email?: string | null, phone?: string | null, taxDocument?: string | null, gender?: CustomerGender | null, state?: string | null, city?: string | null, id: string, dateOfBirth?: any | null, isActive: boolean, tags: { __typename?: 'CustomerTagsRelayTypeConnection', edges: Array<{ __typename?: 'CustomerTagsRelayTypeEdge', node?: { __typename?: 'CustomerTagsRelayType', pk: number, name: string } | null } | null> }, giftbackSet: { __typename?: 'GiftbackRelayTypeConnection', edges: Array<{ __typename?: 'GiftbackRelayTypeEdge', node?: { __typename?: 'GiftbackRelayType', value: number, code: string, dueDate: any, minValueToRedeem: number, status: GiftbackStatus, store: { __typename?: 'StoreType', id: string, giftbackconfig?: { __typename?: 'GiftbackConfigType', minPurchaseValueForRedeem?: number | null, minimumValueType: GiftbackConfigMinimumValueType, maximumDiscountPercentage?: number | null } | null } } | null } | null> } } | null } | null> } | null };

export type ProductCreateMutationVariables = Exact<{
  productsInput?: InputMaybe<Array<InputMaybe<CreateProductBulkInput>> | InputMaybe<CreateProductBulkInput>>;
}>;


export type ProductCreateMutation = { __typename?: 'Mutation', productBulkCreate?: { __typename?: 'CreateProductBulkMutation', ok?: boolean | null, error?: string | null, product?: { __typename?: 'ProductType', id: string, name: string } | null } | null };

export type CategoryListQueryVariables = Exact<{
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
}>;


export type CategoryListQuery = { __typename?: 'Query', categoryList?: { __typename?: 'CategoryRelayTypeConnection', edges: Array<{ __typename?: 'CategoryRelayTypeEdge', node?: { __typename?: 'CategoryRelayType', id: string, pk: number, name: string } | null } | null> } | null };

export type GetAllProductsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAllProductsQuery = { __typename?: 'Query', allProductsRelay?: { __typename?: 'ProductRelayTypeConnection', edges: Array<{ __typename?: 'ProductRelayTypeEdge', node?: { __typename?: 'ProductRelayType', pk: number, name: string, value: number } | null } | null> } | null };

export type GetProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetProductQuery = { __typename?: 'Query', getProduct?: { __typename?: 'ProductType', id: string, name: string, value: number, integrationId?: string | null, importedFrom: ProductImportedFrom, categories: { __typename?: 'CategoryRelayTypeConnection', edges: Array<{ __typename?: 'CategoryRelayTypeEdge', node?: { __typename?: 'CategoryRelayType', id: string, pk: number, name: string } | null } | null> } } | null };

export type GetReportProductQueryVariables = Exact<{
  input: ProductFilterSetInput;
}>;


export type GetReportProductQuery = { __typename?: 'Query', productExcelReport?: { __typename?: 'ProductExcelReportType', relatorio?: string | null, background?: boolean | null } | null };

export type ListAllProductsQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  categoriesNames?: InputMaybe<Scalars['String']['input']>;
  importedFrom?: InputMaybe<Scalars['String']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListAllProductsQuery = { __typename?: 'Query', allProductsRelay?: { __typename?: 'ProductRelayTypeConnection', edgeCount?: number | null, totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'ProductRelayTypeEdge', node?: { __typename?: 'ProductRelayType', pk: number, name: string, value: number, categories: { __typename?: 'CategoryRelayTypeConnection', edges: Array<{ __typename?: 'CategoryRelayTypeEdge', node?: { __typename?: 'CategoryRelayType', name: string } | null } | null> } } | null } | null> } | null };

export type ProductUpdateMutationVariables = Exact<{
  productInput: UpdateProductInput;
}>;


export type ProductUpdateMutation = { __typename?: 'Mutation', productUpdate?: { __typename?: 'UpdateProductMutation', ok?: boolean | null, product?: { __typename?: 'ProductType', id: string, value: number } | null } | null };

export type AcceptTermsMutationMutationVariables = Exact<{
  termsId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>> | InputMaybe<Scalars['ID']['input']>>;
}>;


export type AcceptTermsMutationMutation = { __typename?: 'Mutation', acceptTerms?: { __typename?: 'AcceptTermsMutation', ok?: boolean | null } | null };

export type GetConnectedStoreChartsConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConnectedStoreChartsConfigQuery = { __typename?: 'Query', me?: { __typename?: 'CustomUserNode', connectedStore?: { __typename?: 'StoreType', customerSet: { __typename?: 'CustomerTypeRelayConnection', edgeCount?: number | null }, chartsconfig?: { __typename?: 'ChartsConfigType', tags: boolean, gender: boolean, yearRange: boolean, city: boolean, state: boolean } | null } | null } | null };

export type GetProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileQuery = { __typename?: 'Query', me?: { __typename?: 'CustomUserNode', email: string, firstName: string, lastName: string, pk?: number | null, name?: string | null, gender?: CustomUserGender | null, acceptedTerm?: boolean | null, connectedStore?: { __typename?: 'StoreType', id: string, name: string, plan: StorePlan, isActive: boolean, currencyType: StoreCurrencyType, profileImage?: string | null } | null, permissions: Array<{ __typename?: 'PermissionType', id: string, userType?: PermissionUserType | null, store: { __typename?: 'StoreType', name: string, id: string, plan: StorePlan, isActive: boolean, profileImage?: string | null } }> } | null };

export type NotAcceptedTermsQueryVariables = Exact<{ [key: string]: never; }>;


export type NotAcceptedTermsQuery = { __typename?: 'Query', allTerms?: Array<{ __typename?: 'AcceptationTermType', pk: number, termType: AcceptationTermTermType, content: string } | null> | null };

export type UpdateMyConnectedStoreMutationVariables = Exact<{
  storeId: Scalars['ID']['input'];
}>;


export type UpdateMyConnectedStoreMutation = { __typename?: 'Mutation', updateUserConnectedStore?: { __typename?: 'UpdateUserConnectedStoreMutation', ok?: boolean | null } | null };

export type CancelPurchaseActionMutationVariables = Exact<{
  purchaseInput: CancelPurchaseInput;
}>;


export type CancelPurchaseActionMutation = { __typename?: 'Mutation', cancelPurchase?: { __typename?: 'CancelPurchaseMutation', purchase?: { __typename?: 'PurchaseType', id: string, value: number, canceledBy?: string | null } | null } | null };

export type GetDetailPurchaseQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDetailPurchaseQuery = { __typename?: 'Query', getPurchase?: { __typename?: 'PurchaseType', integrationId?: string | null, canceledBy?: string | null, canceledAt?: any | null, date?: any | null, value: number, paymentMethod: string, importedFrom: PurchaseImportedFrom, discountType: PurchaseDiscountType, discountValue: number, sellerName?: string | null, justification: string, pk: number, giftbackRedeemedValue: number, store: { __typename?: 'StoreType', name: string }, user?: { __typename?: 'CustomUserNode', name?: string | null } | null, customer: { __typename?: 'CustomerTypeRelay', pk: number, name?: string | null, email?: string | null, phone?: string | null, taxDocument?: string | null, state?: string | null, city?: string | null }, purchaseproductSet: { __typename?: 'PurchaseProductRelayTypeConnection', edges: Array<{ __typename?: 'PurchaseProductRelayTypeEdge', node?: { __typename?: 'PurchaseProductRelayType', quantity: number, subtotal: number, value: number, product: { __typename?: 'ProductRelayType', pk: number, name: string } } | null } | null> }, giftback?: { __typename?: 'GiftbackRelayType', pk: number, status: GiftbackStatus, value: number, dueDate: any, code: string } | null, giftbackRedeemed?: { __typename?: 'GiftbackRelayType', pk: number, code: string, id: string } | null, credithousepurchase?: { __typename?: 'CreditHousePurchaseType', installments: number, tax: number, pk: number } | null } | null };

export type ListPurchasesPageQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  customer_Phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Float']['input']>;
  integrationId_In?: InputMaybe<Scalars['String']['input']>;
  usernameOrEmailUser?: InputMaybe<Scalars['String']['input']>;
  nameOrEmailCustomer?: InputMaybe<Scalars['String']['input']>;
  value_Gte?: InputMaybe<Scalars['Float']['input']>;
  value_Lte?: InputMaybe<Scalars['Float']['input']>;
  date_Gte?: InputMaybe<Scalars['Date']['input']>;
  date_Lte?: InputMaybe<Scalars['Date']['input']>;
  importedFrom_In?: InputMaybe<Scalars['String']['input']>;
  approvedOrCanceledSales?: InputMaybe<Scalars['String']['input']>;
  sellerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  createdRedeemedGiftback?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListPurchasesPageQuery = { __typename?: 'Query', purchaseAll?: { __typename?: 'PurchaseRelayTypeConnection', totalCount?: number | null } | null, purchaseApproved?: { __typename?: 'PurchaseRelayTypeConnection', totalCount?: number | null } | null, purchaseCanceled?: { __typename?: 'PurchaseRelayTypeConnection', totalCount?: number | null } | null, allPurchasesRelay?: { __typename?: 'PurchaseRelayTypeConnection', edgeCount?: number | null, totalCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'PurchaseRelayTypeEdge', node?: { __typename?: 'PurchaseRelayType', id: string, pk: number, value: number, date?: any | null, canceledBy?: string | null, canceledAt?: any | null, customer: { __typename?: 'CustomerTypeRelay', pk: number, name?: string | null, email?: string | null }, purchaseproductSet: { __typename?: 'PurchaseProductRelayTypeConnection', edges: Array<{ __typename?: 'PurchaseProductRelayTypeEdge', node?: { __typename?: 'PurchaseProductRelayType', quantity: number } | null } | null> } } | null } | null> } | null };

export type ListAllPurchasesQueryVariables = Exact<{
  customer_Id?: InputMaybe<Scalars['ID']['input']>;
}>;


export type ListAllPurchasesQuery = { __typename?: 'Query', allPurchasesRelay?: { __typename?: 'PurchaseRelayTypeConnection', edges: Array<{ __typename?: 'PurchaseRelayTypeEdge', node?: { __typename?: 'PurchaseRelayType', pk: number, value: number, date?: any | null, importedFrom: PurchaseImportedFrom, canceledBy?: string | null, canceledAt?: any | null, store: { __typename?: 'StoreType', name: string }, customeractivitySet: { __typename?: 'CustomerActivitiesTypeRelayConnection', edges: Array<{ __typename?: 'CustomerActivitiesTypeRelayEdge', node?: { __typename?: 'CustomerActivitiesTypeRelay', activity?: CustomerActivityActivity | null, title?: string | null, description: string, pk: number } | null } | null> } } | null } | null> } | null };

export type PurchaseExcelReportQueryVariables = Exact<{
  input?: InputMaybe<PurchaseFilterSetInput>;
}>;


export type PurchaseExcelReportQuery = { __typename?: 'Query', purchaseExcelReport?: { __typename?: 'PurchaseExcelReportType', relatorio?: string | null, background?: boolean | null } | null };

export type GetStoreQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStoreQuery = { __typename?: 'Query', getStore?: { __typename?: 'StoreType', id: string, name: string, telephone: string, email: string, cnpj: string, plan: StorePlan, isActive: boolean, url?: string | null, profileImage?: string | null, currencyType: StoreCurrencyType } | null };

export type StoreUpdateMutationVariables = Exact<{
  input?: InputMaybe<UpdateStoreInput>;
}>;


export type StoreUpdateMutation = { __typename?: 'Mutation', storeUpdate?: { __typename?: 'UpdateStoreMutation', ok?: boolean | null, store?: { __typename?: 'StoreType', id: string, name: string, telephone: string, email: string, cnpj: string, plan: StorePlan, isActive: boolean } | null } | null };

export type CreatePurchaseViaTotemMutationVariables = Exact<{
  purchaseInput: CreatePurchaseTotemInput;
}>;


export type CreatePurchaseViaTotemMutation = { __typename?: 'Mutation', purchaseTotemCreate?: { __typename?: 'CreatePurchaseTotemMutation', success?: boolean | null, error?: string | null } | null };

export type GetCreditHouseGiftbackConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditHouseGiftbackConfigQuery = { __typename?: 'Query', getStore?: { __typename?: 'StoreType', credithouseconfig?: { __typename?: 'CreditHouseConfigType', credithousegiftbackconfigSet: Array<{ __typename?: 'CreditHouseGiftbackConfigType', id: string, pk: number, loanMinValue: number, loanMaxValue: number, taxMinValue: number, taxMaxValue: number, giftbackTypeValue: CreditHouseGiftbackConfigGiftbackTypeValue, giftbackValue: number }> } | null } | null };

export type GetDetailGiftbackQueryVariables = Exact<{
  input: Scalars['String']['input'];
}>;


export type GetDetailGiftbackQuery = { __typename?: 'Query', getGiftbackByCodeCpfEmailPhone?: { __typename?: 'GiftbackType', pk: number, code: string, status: GiftbackStatus, dueDate: any, value: number, minValueToRedeem: number, customer: { __typename?: 'CustomerTypeRelay', id: string, pk: number }, store: { __typename?: 'StoreType', giftbackconfig?: { __typename?: 'GiftbackConfigType', minPurchaseValueForRedeem?: number | null, minimumValueType: GiftbackConfigMinimumValueType, maximumDiscountPercentage?: number | null } | null } } | null };

export type CreateUserMutationVariables = Exact<{
  userInput: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUserCustom?: { __typename?: 'CreateCustomUserMutation', ok?: boolean | null, customUser?: { __typename?: 'CustomUserType', id: string, name?: string | null } | null } | null };

export type UpdateUserCustomMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserInput>;
}>;


export type UpdateUserCustomMutation = { __typename?: 'Mutation', updateUserCustom?: { __typename?: 'UpdateCustomUserMutation', ok?: boolean | null } | null };

export type ForgotEmailMutationVariables = Exact<{
  input: SendPasswordResetEmailInput;
}>;


export type ForgotEmailMutation = { __typename?: 'Mutation', sendPasswordResetEmail?: { __typename?: 'SendPasswordResetEmailPayload', success?: boolean | null, errors?: any | null } | null };

export type ListUserQueryVariables = Exact<{
  before?: InputMaybe<Scalars['String']['input']>;
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  nameOrEmail?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  cpf_Icontains?: InputMaybe<Scalars['String']['input']>;
  phone_Icontains?: InputMaybe<Scalars['String']['input']>;
  permissions_UserType_In?: InputMaybe<Scalars['String']['input']>;
}>;


export type ListUserQuery = { __typename?: 'Query', usersAll?: { __typename?: 'CustomUserRelayTypeConnection', totalCount?: number | null } | null, userActive?: { __typename?: 'CustomUserRelayTypeConnection', totalCount?: number | null } | null, userInactive?: { __typename?: 'CustomUserRelayTypeConnection', totalCount?: number | null } | null, allUsersRelay?: { __typename?: 'CustomUserRelayTypeConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'CustomUserRelayTypeEdge', node?: { __typename?: 'CustomUserRelayType', id: string, pk: number, cpf?: string | null, name?: string | null, email: string, phone?: string | null, isActive: boolean, permissionInStore?: { __typename?: 'PermissionType', userType?: PermissionUserType | null } | null, permissions: Array<{ __typename?: 'PermissionType', userType?: PermissionUserType | null, store: { __typename?: 'StoreType', id: string, name: string } }> } | null } | null> } | null };

export type PasswordResetMutationVariables = Exact<{
  input: PasswordResetInput;
}>;


export type PasswordResetMutation = { __typename?: 'Mutation', passwordReset?: { __typename?: 'PasswordResetPayload', success?: boolean | null, errors?: any | null } | null };

export type UpdateUserPasswordMutationVariables = Exact<{
  input: RedefinePasswordInput;
}>;


export type UpdateUserPasswordMutation = { __typename?: 'Mutation', redefinePassword?: { __typename?: 'RedefinePasswordMutation', ok?: boolean | null } | null };

export type GetUserDetailsQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetUserDetailsQuery = { __typename?: 'Query', getUser?: { __typename?: 'CustomUserType', id: string, pk: number, username: string, email: string, name?: string | null, cpf?: string | null, gender?: CustomUserGender | null, phone?: string | null, birthdate?: any | null, isActive: boolean, connectedStore?: { __typename?: 'StoreType', id: string } | null, permissions: Array<{ __typename?: 'PermissionType', userType?: PermissionUserType | null, store: { __typename?: 'StoreType', id: string } }> } | null };

export type LoginMutationVariables = Exact<{
  input: ObtainJsonWebTokenInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', tokenAuth?: { __typename?: 'ObtainJSONWebTokenPayload', success?: boolean | null, errors?: any | null, unarchiving?: boolean | null, token?: string | null, user?: { __typename?: 'UserNode', id: string, username: string } | null } | null };


export const CreateNewCampaignDocument = gql`
    mutation CreateNewCampaign($campaignInput: CreateCampaignInput!) {
  createCampaign(campaignInput: $campaignInput) {
    success
    error
  }
}
    `;
export type CreateNewCampaignMutationFn = Apollo.MutationFunction<CreateNewCampaignMutation, CreateNewCampaignMutationVariables>;

/**
 * __useCreateNewCampaignMutation__
 *
 * To run a mutation, you first call `useCreateNewCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewCampaignMutation, { data, loading, error }] = useCreateNewCampaignMutation({
 *   variables: {
 *      campaignInput: // value for 'campaignInput'
 *   },
 * });
 */
export function useCreateNewCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewCampaignMutation, CreateNewCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewCampaignMutation, CreateNewCampaignMutationVariables>(CreateNewCampaignDocument, options);
      }
export type CreateNewCampaignMutationHookResult = ReturnType<typeof useCreateNewCampaignMutation>;
export type CreateNewCampaignMutationResult = Apollo.MutationResult<CreateNewCampaignMutation>;
export type CreateNewCampaignMutationOptions = Apollo.BaseMutationOptions<CreateNewCampaignMutation, CreateNewCampaignMutationVariables>;
export const GetCampaignDetailsDocument = gql`
    query GetCampaignDetails($id: ID!) {
  readCampaign(id: $id) {
    pk
    title
    messageSms
    message
    daysToTrigger
    tag
    buttonLabel
    buttonUrl
    createdAt
    updatedAt
    footer
    viaWhatsapp
    viaSms
    viaEmail
    whatsappTemplate {
      pk
      category
      status
    }
  }
}
    `;

/**
 * __useGetCampaignDetailsQuery__
 *
 * To run a query within a React component, call `useGetCampaignDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCampaignDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>(GetCampaignDetailsDocument, options);
      }
export function useGetCampaignDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>(GetCampaignDetailsDocument, options);
        }
export function useGetCampaignDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>(GetCampaignDetailsDocument, options);
        }
export type GetCampaignDetailsQueryHookResult = ReturnType<typeof useGetCampaignDetailsQuery>;
export type GetCampaignDetailsLazyQueryHookResult = ReturnType<typeof useGetCampaignDetailsLazyQuery>;
export type GetCampaignDetailsSuspenseQueryHookResult = ReturnType<typeof useGetCampaignDetailsSuspenseQuery>;
export type GetCampaignDetailsQueryResult = Apollo.QueryResult<GetCampaignDetailsQuery, GetCampaignDetailsQueryVariables>;
export const ListCampaignsDocument = gql`
    query ListCampaigns($before: String, $after: String, $first: Int = 12, $last: Int, $titleOrMessage: String, $viaWhatsapp: Boolean, $viaSms: Boolean, $viaEmail: Boolean, $createdAt_Gte: DateTime, $createdAt_Lte: DateTime, $tag_Icontains: String) {
  listCampaigns(
    before: $before
    after: $after
    first: $first
    last: $last
    titleOrMessage: $titleOrMessage
    viaWhatsapp: $viaWhatsapp
    viaSms: $viaSms
    viaEmail: $viaEmail
    createdAt_Gte: $createdAt_Gte
    createdAt_Lte: $createdAt_Lte
    tag_Icontains: $tag_Icontains
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        pk
        id
        tag
        title
        messageSms
        message
        buttonLabel
        buttonUrl
        createdAt
        updatedAt
        viaWhatsapp
        viaSms
        viaEmail
      }
    }
  }
}
    `;

/**
 * __useListCampaignsQuery__
 *
 * To run a query within a React component, call `useListCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      titleOrMessage: // value for 'titleOrMessage'
 *      viaWhatsapp: // value for 'viaWhatsapp'
 *      viaSms: // value for 'viaSms'
 *      viaEmail: // value for 'viaEmail'
 *      createdAt_Gte: // value for 'createdAt_Gte'
 *      createdAt_Lte: // value for 'createdAt_Lte'
 *      tag_Icontains: // value for 'tag_Icontains'
 *   },
 * });
 */
export function useListCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<ListCampaignsQuery, ListCampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCampaignsQuery, ListCampaignsQueryVariables>(ListCampaignsDocument, options);
      }
export function useListCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCampaignsQuery, ListCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCampaignsQuery, ListCampaignsQueryVariables>(ListCampaignsDocument, options);
        }
export function useListCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCampaignsQuery, ListCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCampaignsQuery, ListCampaignsQueryVariables>(ListCampaignsDocument, options);
        }
export type ListCampaignsQueryHookResult = ReturnType<typeof useListCampaignsQuery>;
export type ListCampaignsLazyQueryHookResult = ReturnType<typeof useListCampaignsLazyQuery>;
export type ListCampaignsSuspenseQueryHookResult = ReturnType<typeof useListCampaignsSuspenseQuery>;
export type ListCampaignsQueryResult = Apollo.QueryResult<ListCampaignsQuery, ListCampaignsQueryVariables>;
export const EditCampaignDocument = gql`
    mutation EditCampaign($campaignInput: UpdateCampaignInput!) {
  updateCampaign(campaignInput: $campaignInput) {
    success
    error
  }
}
    `;
export type EditCampaignMutationFn = Apollo.MutationFunction<EditCampaignMutation, EditCampaignMutationVariables>;

/**
 * __useEditCampaignMutation__
 *
 * To run a mutation, you first call `useEditCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCampaignMutation, { data, loading, error }] = useEditCampaignMutation({
 *   variables: {
 *      campaignInput: // value for 'campaignInput'
 *   },
 * });
 */
export function useEditCampaignMutation(baseOptions?: Apollo.MutationHookOptions<EditCampaignMutation, EditCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCampaignMutation, EditCampaignMutationVariables>(EditCampaignDocument, options);
      }
export type EditCampaignMutationHookResult = ReturnType<typeof useEditCampaignMutation>;
export type EditCampaignMutationResult = Apollo.MutationResult<EditCampaignMutation>;
export type EditCampaignMutationOptions = Apollo.BaseMutationOptions<EditCampaignMutation, EditCampaignMutationVariables>;
export const GetAllRfmDocument = gql`
    query getAllRFM {
  frequency: getRfmFrequency {
    id
    zoneOneMin
    zoneOneMax
    zoneTwoMin
    zoneTwoMax
    zoneThreeMin
    zoneThreeMax
    zoneFourMin
    zoneFourMax
    zoneFiveMin
    zoneFiveMax
  }
  recency: getRfmRecency {
    id
    zoneOneMin
    zoneOneMax
    zoneTwoMin
    zoneTwoMax
    zoneThreeMin
    zoneThreeMax
    zoneFourMin
    zoneFourMax
    zoneFiveMin
    zoneFiveMax
  }
  value: getRfmValue {
    id
    zoneOneMin
    zoneOneMax
    zoneTwoMin
    zoneTwoMax
    zoneThreeMin
    zoneThreeMax
    zoneFourMin
    zoneFourMax
    zoneFiveMin
    zoneFiveMax
  }
}
    `;

/**
 * __useGetAllRfmQuery__
 *
 * To run a query within a React component, call `useGetAllRfmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRfmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRfmQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRfmQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRfmQuery, GetAllRfmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRfmQuery, GetAllRfmQueryVariables>(GetAllRfmDocument, options);
      }
export function useGetAllRfmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRfmQuery, GetAllRfmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRfmQuery, GetAllRfmQueryVariables>(GetAllRfmDocument, options);
        }
export function useGetAllRfmSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllRfmQuery, GetAllRfmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllRfmQuery, GetAllRfmQueryVariables>(GetAllRfmDocument, options);
        }
export type GetAllRfmQueryHookResult = ReturnType<typeof useGetAllRfmQuery>;
export type GetAllRfmLazyQueryHookResult = ReturnType<typeof useGetAllRfmLazyQuery>;
export type GetAllRfmSuspenseQueryHookResult = ReturnType<typeof useGetAllRfmSuspenseQuery>;
export type GetAllRfmQueryResult = Apollo.QueryResult<GetAllRfmQuery, GetAllRfmQueryVariables>;
export const GetGiftbackConfigDocument = gql`
    query GetGiftbackConfig {
  getGiftbackConfig {
    id
    value
    valueType
    dueDate
    cumulative
    minPurchaseValueForCreate
    minPurchaseValueForRedeem
    minimumValueType
    maximumDiscountPercentage
    discountLimitEnabled
    discountLimit
    pk
  }
}
    `;

/**
 * __useGetGiftbackConfigQuery__
 *
 * To run a query within a React component, call `useGetGiftbackConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftbackConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftbackConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGiftbackConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>(GetGiftbackConfigDocument, options);
      }
export function useGetGiftbackConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>(GetGiftbackConfigDocument, options);
        }
export function useGetGiftbackConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>(GetGiftbackConfigDocument, options);
        }
export type GetGiftbackConfigQueryHookResult = ReturnType<typeof useGetGiftbackConfigQuery>;
export type GetGiftbackConfigLazyQueryHookResult = ReturnType<typeof useGetGiftbackConfigLazyQuery>;
export type GetGiftbackConfigSuspenseQueryHookResult = ReturnType<typeof useGetGiftbackConfigSuspenseQuery>;
export type GetGiftbackConfigQueryResult = Apollo.QueryResult<GetGiftbackConfigQuery, GetGiftbackConfigQueryVariables>;
export const GetWhatsappIntegrationDocument = gql`
    query GetWhatsappIntegration {
  getWhatsappIntegration {
    id
    whatsappAppBusinessId
    whatsappBusinessAccountId
    whatsappAccessToken
    phoneNumberId
    activated
    connected
  }
}
    `;

/**
 * __useGetWhatsappIntegrationQuery__
 *
 * To run a query within a React component, call `useGetWhatsappIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhatsappIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhatsappIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWhatsappIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>(GetWhatsappIntegrationDocument, options);
      }
export function useGetWhatsappIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>(GetWhatsappIntegrationDocument, options);
        }
export function useGetWhatsappIntegrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>(GetWhatsappIntegrationDocument, options);
        }
export type GetWhatsappIntegrationQueryHookResult = ReturnType<typeof useGetWhatsappIntegrationQuery>;
export type GetWhatsappIntegrationLazyQueryHookResult = ReturnType<typeof useGetWhatsappIntegrationLazyQuery>;
export type GetWhatsappIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetWhatsappIntegrationSuspenseQuery>;
export type GetWhatsappIntegrationQueryResult = Apollo.QueryResult<GetWhatsappIntegrationQuery, GetWhatsappIntegrationQueryVariables>;
export const GetRfmFrequencyDocument = gql`
    query GetRFMFrequency {
  getRfmFrequency {
    id
    zoneOneMin
    zoneOneMax
    zoneTwoMin
    zoneTwoMax
    zoneThreeMin
    zoneThreeMax
    zoneFourMin
    zoneFourMax
    zoneFiveMin
    zoneFiveMax
  }
}
    `;

/**
 * __useGetRfmFrequencyQuery__
 *
 * To run a query within a React component, call `useGetRfmFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfmFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfmFrequencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRfmFrequencyQuery(baseOptions?: Apollo.QueryHookOptions<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>(GetRfmFrequencyDocument, options);
      }
export function useGetRfmFrequencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>(GetRfmFrequencyDocument, options);
        }
export function useGetRfmFrequencySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>(GetRfmFrequencyDocument, options);
        }
export type GetRfmFrequencyQueryHookResult = ReturnType<typeof useGetRfmFrequencyQuery>;
export type GetRfmFrequencyLazyQueryHookResult = ReturnType<typeof useGetRfmFrequencyLazyQuery>;
export type GetRfmFrequencySuspenseQueryHookResult = ReturnType<typeof useGetRfmFrequencySuspenseQuery>;
export type GetRfmFrequencyQueryResult = Apollo.QueryResult<GetRfmFrequencyQuery, GetRfmFrequencyQueryVariables>;
export const GetRfmRecencyDocument = gql`
    query GetRFMRecency {
  getRfmRecency {
    id
    zoneOneMin
    zoneOneMax
    zoneTwoMin
    zoneTwoMax
    zoneThreeMin
    zoneThreeMax
    zoneFourMin
    zoneFourMax
    zoneFiveMin
    zoneFiveMax
  }
}
    `;

/**
 * __useGetRfmRecencyQuery__
 *
 * To run a query within a React component, call `useGetRfmRecencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfmRecencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfmRecencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRfmRecencyQuery(baseOptions?: Apollo.QueryHookOptions<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>(GetRfmRecencyDocument, options);
      }
export function useGetRfmRecencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>(GetRfmRecencyDocument, options);
        }
export function useGetRfmRecencySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>(GetRfmRecencyDocument, options);
        }
export type GetRfmRecencyQueryHookResult = ReturnType<typeof useGetRfmRecencyQuery>;
export type GetRfmRecencyLazyQueryHookResult = ReturnType<typeof useGetRfmRecencyLazyQuery>;
export type GetRfmRecencySuspenseQueryHookResult = ReturnType<typeof useGetRfmRecencySuspenseQuery>;
export type GetRfmRecencyQueryResult = Apollo.QueryResult<GetRfmRecencyQuery, GetRfmRecencyQueryVariables>;
export const GetRfmValueDocument = gql`
    query GetRFMValue {
  getRfmValue {
    id
    zoneOneMin
    zoneOneMax
    zoneTwoMin
    zoneTwoMax
    zoneThreeMin
    zoneThreeMax
    zoneFourMin
    zoneFourMax
    zoneFiveMin
    zoneFiveMax
  }
}
    `;

/**
 * __useGetRfmValueQuery__
 *
 * To run a query within a React component, call `useGetRfmValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfmValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfmValueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRfmValueQuery(baseOptions?: Apollo.QueryHookOptions<GetRfmValueQuery, GetRfmValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRfmValueQuery, GetRfmValueQueryVariables>(GetRfmValueDocument, options);
      }
export function useGetRfmValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRfmValueQuery, GetRfmValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRfmValueQuery, GetRfmValueQueryVariables>(GetRfmValueDocument, options);
        }
export function useGetRfmValueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRfmValueQuery, GetRfmValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRfmValueQuery, GetRfmValueQueryVariables>(GetRfmValueDocument, options);
        }
export type GetRfmValueQueryHookResult = ReturnType<typeof useGetRfmValueQuery>;
export type GetRfmValueLazyQueryHookResult = ReturnType<typeof useGetRfmValueLazyQuery>;
export type GetRfmValueSuspenseQueryHookResult = ReturnType<typeof useGetRfmValueSuspenseQuery>;
export type GetRfmValueQueryResult = Apollo.QueryResult<GetRfmValueQuery, GetRfmValueQueryVariables>;
export const GetStoreIntegrationsDocument = gql`
    query GetStoreIntegrations {
  infoIntegration: getStore {
    traystoreintegration {
      integrationId
      code
      apiKey
      connected
    }
    whatsappconfig {
      id
      connected
      activated
      whatsappAccessToken
      whatsappPhoneNumber
      phoneNumberId
      whatsappAppBusinessId
      whatsappBusinessAccountId
    }
    omiestoreintegration {
      id
      appKey
      appSecret
      connected
      activated
      nfce
      nfe
      nfse
    }
    smsinfobipintegration {
      id
      connected
      activated
    }
    blingstoreintegration {
      id
      connected
      activated
    }
    webpicstoreintegration {
      id
      integrationToken
      companyId
      havePdv
      haveInvoice
      connected
    }
    emailproviderstoreintegration {
      emailToSend
      provider
      isActive
    }
  }
}
    `;

/**
 * __useGetStoreIntegrationsQuery__
 *
 * To run a query within a React component, call `useGetStoreIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreIntegrationsQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>(GetStoreIntegrationsDocument, options);
      }
export function useGetStoreIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>(GetStoreIntegrationsDocument, options);
        }
export function useGetStoreIntegrationsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>(GetStoreIntegrationsDocument, options);
        }
export type GetStoreIntegrationsQueryHookResult = ReturnType<typeof useGetStoreIntegrationsQuery>;
export type GetStoreIntegrationsLazyQueryHookResult = ReturnType<typeof useGetStoreIntegrationsLazyQuery>;
export type GetStoreIntegrationsSuspenseQueryHookResult = ReturnType<typeof useGetStoreIntegrationsSuspenseQuery>;
export type GetStoreIntegrationsQueryResult = Apollo.QueryResult<GetStoreIntegrationsQuery, GetStoreIntegrationsQueryVariables>;
export const GetZapiIntegrationDocument = gql`
    query GetZapiIntegration {
  getZApiIntegration {
    zApiId
    zApiToken
    token
    pk
    connected
  }
}
    `;

/**
 * __useGetZapiIntegrationQuery__
 *
 * To run a query within a React component, call `useGetZapiIntegrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZapiIntegrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZapiIntegrationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetZapiIntegrationQuery(baseOptions?: Apollo.QueryHookOptions<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>(GetZapiIntegrationDocument, options);
      }
export function useGetZapiIntegrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>(GetZapiIntegrationDocument, options);
        }
export function useGetZapiIntegrationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>(GetZapiIntegrationDocument, options);
        }
export type GetZapiIntegrationQueryHookResult = ReturnType<typeof useGetZapiIntegrationQuery>;
export type GetZapiIntegrationLazyQueryHookResult = ReturnType<typeof useGetZapiIntegrationLazyQuery>;
export type GetZapiIntegrationSuspenseQueryHookResult = ReturnType<typeof useGetZapiIntegrationSuspenseQuery>;
export type GetZapiIntegrationQueryResult = Apollo.QueryResult<GetZapiIntegrationQuery, GetZapiIntegrationQueryVariables>;
export const BlingIntegrationDocument = gql`
    mutation BlingIntegration($code: String) {
  setBlingStoreIntegration(code: $code) {
    ok
    blingIntegration {
      pk
    }
  }
}
    `;
export type BlingIntegrationMutationFn = Apollo.MutationFunction<BlingIntegrationMutation, BlingIntegrationMutationVariables>;

/**
 * __useBlingIntegrationMutation__
 *
 * To run a mutation, you first call `useBlingIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlingIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blingIntegrationMutation, { data, loading, error }] = useBlingIntegrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useBlingIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<BlingIntegrationMutation, BlingIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BlingIntegrationMutation, BlingIntegrationMutationVariables>(BlingIntegrationDocument, options);
      }
export type BlingIntegrationMutationHookResult = ReturnType<typeof useBlingIntegrationMutation>;
export type BlingIntegrationMutationResult = Apollo.MutationResult<BlingIntegrationMutation>;
export type BlingIntegrationMutationOptions = Apollo.BaseMutationOptions<BlingIntegrationMutation, BlingIntegrationMutationVariables>;
export const UpdateGiftbackConfigDocument = gql`
    mutation UpdateGiftbackConfig($input: GiftbackConfigInput) {
  saveGiftbackConfig(input: $input) {
    giftback {
      id
      value
      valueType
      dueDate
      cumulative
      minPurchaseValueForCreate
      minPurchaseValueForRedeem
      minimumValueType
      maximumDiscountPercentage
      pk
    }
  }
}
    `;
export type UpdateGiftbackConfigMutationFn = Apollo.MutationFunction<UpdateGiftbackConfigMutation, UpdateGiftbackConfigMutationVariables>;

/**
 * __useUpdateGiftbackConfigMutation__
 *
 * To run a mutation, you first call `useUpdateGiftbackConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGiftbackConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGiftbackConfigMutation, { data, loading, error }] = useUpdateGiftbackConfigMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGiftbackConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGiftbackConfigMutation, UpdateGiftbackConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGiftbackConfigMutation, UpdateGiftbackConfigMutationVariables>(UpdateGiftbackConfigDocument, options);
      }
export type UpdateGiftbackConfigMutationHookResult = ReturnType<typeof useUpdateGiftbackConfigMutation>;
export type UpdateGiftbackConfigMutationResult = Apollo.MutationResult<UpdateGiftbackConfigMutation>;
export type UpdateGiftbackConfigMutationOptions = Apollo.BaseMutationOptions<UpdateGiftbackConfigMutation, UpdateGiftbackConfigMutationVariables>;
export const OmieIntegrationDocument = gql`
    mutation OmieIntegration($input: SetOmieStoreIntegrationInput!) {
  setOmieStoreIntegration(input: $input) {
    ok
    omieIntegration {
      appKey
      appSecret
      connected
      activated
      nfce
      nfe
      nfse
    }
  }
}
    `;
export type OmieIntegrationMutationFn = Apollo.MutationFunction<OmieIntegrationMutation, OmieIntegrationMutationVariables>;

/**
 * __useOmieIntegrationMutation__
 *
 * To run a mutation, you first call `useOmieIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOmieIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [omieIntegrationMutation, { data, loading, error }] = useOmieIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOmieIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<OmieIntegrationMutation, OmieIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OmieIntegrationMutation, OmieIntegrationMutationVariables>(OmieIntegrationDocument, options);
      }
export type OmieIntegrationMutationHookResult = ReturnType<typeof useOmieIntegrationMutation>;
export type OmieIntegrationMutationResult = Apollo.MutationResult<OmieIntegrationMutation>;
export type OmieIntegrationMutationOptions = Apollo.BaseMutationOptions<OmieIntegrationMutation, OmieIntegrationMutationVariables>;
export const SmsInfobipIntegrationDocument = gql`
    mutation SMSInfobipIntegration($input: SetSMSInfoBipIntegrationInput!) {
  setSmsInfoBipIntegration(input: $input) {
    ok
    smsInfoBipIntegration {
      id
      connected
      activated
      store {
        name
      }
    }
  }
}
    `;
export type SmsInfobipIntegrationMutationFn = Apollo.MutationFunction<SmsInfobipIntegrationMutation, SmsInfobipIntegrationMutationVariables>;

/**
 * __useSmsInfobipIntegrationMutation__
 *
 * To run a mutation, you first call `useSmsInfobipIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmsInfobipIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smsInfobipIntegrationMutation, { data, loading, error }] = useSmsInfobipIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSmsInfobipIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<SmsInfobipIntegrationMutation, SmsInfobipIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SmsInfobipIntegrationMutation, SmsInfobipIntegrationMutationVariables>(SmsInfobipIntegrationDocument, options);
      }
export type SmsInfobipIntegrationMutationHookResult = ReturnType<typeof useSmsInfobipIntegrationMutation>;
export type SmsInfobipIntegrationMutationResult = Apollo.MutationResult<SmsInfobipIntegrationMutation>;
export type SmsInfobipIntegrationMutationOptions = Apollo.BaseMutationOptions<SmsInfobipIntegrationMutation, SmsInfobipIntegrationMutationVariables>;
export const CreateTrayStoreIntegrationDocument = gql`
    mutation createTrayStoreIntegration($trayInput: CreateTrayStoreIntegrationInput!) {
  createTrayStoreIntegration(trayInput: $trayInput) {
    success
    error
  }
}
    `;
export type CreateTrayStoreIntegrationMutationFn = Apollo.MutationFunction<CreateTrayStoreIntegrationMutation, CreateTrayStoreIntegrationMutationVariables>;

/**
 * __useCreateTrayStoreIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateTrayStoreIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrayStoreIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrayStoreIntegrationMutation, { data, loading, error }] = useCreateTrayStoreIntegrationMutation({
 *   variables: {
 *      trayInput: // value for 'trayInput'
 *   },
 * });
 */
export function useCreateTrayStoreIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrayStoreIntegrationMutation, CreateTrayStoreIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrayStoreIntegrationMutation, CreateTrayStoreIntegrationMutationVariables>(CreateTrayStoreIntegrationDocument, options);
      }
export type CreateTrayStoreIntegrationMutationHookResult = ReturnType<typeof useCreateTrayStoreIntegrationMutation>;
export type CreateTrayStoreIntegrationMutationResult = Apollo.MutationResult<CreateTrayStoreIntegrationMutation>;
export type CreateTrayStoreIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateTrayStoreIntegrationMutation, CreateTrayStoreIntegrationMutationVariables>;
export const CreateWebPicIntegrationDocument = gql`
    mutation CreateWebPicIntegration($input: SaveWebpicStoreIntegrationInput!) {
  saveWebpicStoreIntegration(input: $input) {
    ok
  }
}
    `;
export type CreateWebPicIntegrationMutationFn = Apollo.MutationFunction<CreateWebPicIntegrationMutation, CreateWebPicIntegrationMutationVariables>;

/**
 * __useCreateWebPicIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateWebPicIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWebPicIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWebPicIntegrationMutation, { data, loading, error }] = useCreateWebPicIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWebPicIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebPicIntegrationMutation, CreateWebPicIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebPicIntegrationMutation, CreateWebPicIntegrationMutationVariables>(CreateWebPicIntegrationDocument, options);
      }
export type CreateWebPicIntegrationMutationHookResult = ReturnType<typeof useCreateWebPicIntegrationMutation>;
export type CreateWebPicIntegrationMutationResult = Apollo.MutationResult<CreateWebPicIntegrationMutation>;
export type CreateWebPicIntegrationMutationOptions = Apollo.BaseMutationOptions<CreateWebPicIntegrationMutation, CreateWebPicIntegrationMutationVariables>;
export const SaveWhatsappIntegrationDocument = gql`
    mutation SaveWhatsappIntegration($input: WhatsappConfigInput) {
  saveWhatsapp(input: $input) {
    whatsappIntegration {
      id
      whatsappAccessToken
      phoneNumberId
      whatsappAppBusinessId
      whatsappBusinessAccountId
      connected
      activated
      pk
    }
    ok
  }
}
    `;
export type SaveWhatsappIntegrationMutationFn = Apollo.MutationFunction<SaveWhatsappIntegrationMutation, SaveWhatsappIntegrationMutationVariables>;

/**
 * __useSaveWhatsappIntegrationMutation__
 *
 * To run a mutation, you first call `useSaveWhatsappIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveWhatsappIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveWhatsappIntegrationMutation, { data, loading, error }] = useSaveWhatsappIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveWhatsappIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<SaveWhatsappIntegrationMutation, SaveWhatsappIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveWhatsappIntegrationMutation, SaveWhatsappIntegrationMutationVariables>(SaveWhatsappIntegrationDocument, options);
      }
export type SaveWhatsappIntegrationMutationHookResult = ReturnType<typeof useSaveWhatsappIntegrationMutation>;
export type SaveWhatsappIntegrationMutationResult = Apollo.MutationResult<SaveWhatsappIntegrationMutation>;
export type SaveWhatsappIntegrationMutationOptions = Apollo.BaseMutationOptions<SaveWhatsappIntegrationMutation, SaveWhatsappIntegrationMutationVariables>;
export const SetEmailProviderDocument = gql`
    mutation SetEmailProvider($input: EmailProviderStoreIntegrationInput) {
  saveEmailProvider(input: $input) {
    ok
    emailProvider {
      emailToSend
      provider
      isActive
    }
  }
}
    `;
export type SetEmailProviderMutationFn = Apollo.MutationFunction<SetEmailProviderMutation, SetEmailProviderMutationVariables>;

/**
 * __useSetEmailProviderMutation__
 *
 * To run a mutation, you first call `useSetEmailProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEmailProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEmailProviderMutation, { data, loading, error }] = useSetEmailProviderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetEmailProviderMutation(baseOptions?: Apollo.MutationHookOptions<SetEmailProviderMutation, SetEmailProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetEmailProviderMutation, SetEmailProviderMutationVariables>(SetEmailProviderDocument, options);
      }
export type SetEmailProviderMutationHookResult = ReturnType<typeof useSetEmailProviderMutation>;
export type SetEmailProviderMutationResult = Apollo.MutationResult<SetEmailProviderMutation>;
export type SetEmailProviderMutationOptions = Apollo.BaseMutationOptions<SetEmailProviderMutation, SetEmailProviderMutationVariables>;
export const UpdateRfmConfigDocument = gql`
    mutation UpdateRFMConfig($recency: RecencyRFMInput!, $frequency: FrequencyRFMInput!, $value: ValueRFMInput!) {
  saveRfmConfig(
    valueInput: $value
    recencyInput: $recency
    frequencyInput: $frequency
  ) {
    recency {
      id
      zoneOneMin
      zoneOneMax
      zoneTwoMin
      zoneTwoMax
      zoneThreeMin
      zoneThreeMax
      zoneFourMin
      zoneFourMax
      zoneFiveMin
      zoneFiveMax
    }
    frequency {
      id
      zoneOneMin
      zoneOneMax
      zoneTwoMin
      zoneTwoMax
      zoneThreeMin
      zoneThreeMax
      zoneFourMin
      zoneFourMax
      zoneFiveMin
      zoneFiveMax
    }
    value {
      id
      zoneOneMin
      zoneOneMax
      zoneTwoMin
      zoneTwoMax
      zoneThreeMin
      zoneThreeMax
      zoneFourMin
      zoneFourMax
      zoneFiveMin
      zoneFiveMax
    }
  }
}
    `;
export type UpdateRfmConfigMutationFn = Apollo.MutationFunction<UpdateRfmConfigMutation, UpdateRfmConfigMutationVariables>;

/**
 * __useUpdateRfmConfigMutation__
 *
 * To run a mutation, you first call `useUpdateRfmConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfmConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfmConfigMutation, { data, loading, error }] = useUpdateRfmConfigMutation({
 *   variables: {
 *      recency: // value for 'recency'
 *      frequency: // value for 'frequency'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateRfmConfigMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRfmConfigMutation, UpdateRfmConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRfmConfigMutation, UpdateRfmConfigMutationVariables>(UpdateRfmConfigDocument, options);
      }
export type UpdateRfmConfigMutationHookResult = ReturnType<typeof useUpdateRfmConfigMutation>;
export type UpdateRfmConfigMutationResult = Apollo.MutationResult<UpdateRfmConfigMutation>;
export type UpdateRfmConfigMutationOptions = Apollo.BaseMutationOptions<UpdateRfmConfigMutation, UpdateRfmConfigMutationVariables>;
export const SaveZapiIntegrationDocument = gql`
    mutation SaveZapiIntegration($input: ZApiIntegrationInput!) {
  saveZApi(input: $input) {
    zApiIntegration {
      zApiId
      zApiToken
      token
      connected
      pk
    }
    ok
  }
}
    `;
export type SaveZapiIntegrationMutationFn = Apollo.MutationFunction<SaveZapiIntegrationMutation, SaveZapiIntegrationMutationVariables>;

/**
 * __useSaveZapiIntegrationMutation__
 *
 * To run a mutation, you first call `useSaveZapiIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveZapiIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveZapiIntegrationMutation, { data, loading, error }] = useSaveZapiIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveZapiIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<SaveZapiIntegrationMutation, SaveZapiIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveZapiIntegrationMutation, SaveZapiIntegrationMutationVariables>(SaveZapiIntegrationDocument, options);
      }
export type SaveZapiIntegrationMutationHookResult = ReturnType<typeof useSaveZapiIntegrationMutation>;
export type SaveZapiIntegrationMutationResult = Apollo.MutationResult<SaveZapiIntegrationMutation>;
export type SaveZapiIntegrationMutationOptions = Apollo.BaseMutationOptions<SaveZapiIntegrationMutation, SaveZapiIntegrationMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($input: CreateCustomerInput!) {
  createCustomer(input: $input) {
    customer {
      id
      name
      taxDocument
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CustomerExcelReportDocument = gql`
    query CustomerExcelReport($input: CustomerExcelReportInput) {
  customerExcelReport(input: $input) {
    relatorio
  }
}
    `;

/**
 * __useCustomerExcelReportQuery__
 *
 * To run a query within a React component, call `useCustomerExcelReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerExcelReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerExcelReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCustomerExcelReportQuery(baseOptions?: Apollo.QueryHookOptions<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>(CustomerExcelReportDocument, options);
      }
export function useCustomerExcelReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>(CustomerExcelReportDocument, options);
        }
export function useCustomerExcelReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>(CustomerExcelReportDocument, options);
        }
export type CustomerExcelReportQueryHookResult = ReturnType<typeof useCustomerExcelReportQuery>;
export type CustomerExcelReportLazyQueryHookResult = ReturnType<typeof useCustomerExcelReportLazyQuery>;
export type CustomerExcelReportSuspenseQueryHookResult = ReturnType<typeof useCustomerExcelReportSuspenseQuery>;
export type CustomerExcelReportQueryResult = Apollo.QueryResult<CustomerExcelReportQuery, CustomerExcelReportQueryVariables>;
export const GetAllStateCitiesDocument = gql`
    query GetAllStateCities($uf: String!) {
  allStateCities(uf: $uf) {
    id
    nome
  }
}
    `;

/**
 * __useGetAllStateCitiesQuery__
 *
 * To run a query within a React component, call `useGetAllStateCitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStateCitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStateCitiesQuery({
 *   variables: {
 *      uf: // value for 'uf'
 *   },
 * });
 */
export function useGetAllStateCitiesQuery(baseOptions: Apollo.QueryHookOptions<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>(GetAllStateCitiesDocument, options);
      }
export function useGetAllStateCitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>(GetAllStateCitiesDocument, options);
        }
export function useGetAllStateCitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>(GetAllStateCitiesDocument, options);
        }
export type GetAllStateCitiesQueryHookResult = ReturnType<typeof useGetAllStateCitiesQuery>;
export type GetAllStateCitiesLazyQueryHookResult = ReturnType<typeof useGetAllStateCitiesLazyQuery>;
export type GetAllStateCitiesSuspenseQueryHookResult = ReturnType<typeof useGetAllStateCitiesSuspenseQuery>;
export type GetAllStateCitiesQueryResult = Apollo.QueryResult<GetAllStateCitiesQuery, GetAllStateCitiesQueryVariables>;
export const GetAllStatesDocument = gql`
    query GetAllStates {
  allStates {
    id
    sigla
    nome
  }
}
    `;

/**
 * __useGetAllStatesQuery__
 *
 * To run a query within a React component, call `useGetAllStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(GetAllStatesDocument, options);
      }
export function useGetAllStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(GetAllStatesDocument, options);
        }
export function useGetAllStatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllStatesQuery, GetAllStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllStatesQuery, GetAllStatesQueryVariables>(GetAllStatesDocument, options);
        }
export type GetAllStatesQueryHookResult = ReturnType<typeof useGetAllStatesQuery>;
export type GetAllStatesLazyQueryHookResult = ReturnType<typeof useGetAllStatesLazyQuery>;
export type GetAllStatesSuspenseQueryHookResult = ReturnType<typeof useGetAllStatesSuspenseQuery>;
export type GetAllStatesQueryResult = Apollo.QueryResult<GetAllStatesQuery, GetAllStatesQueryVariables>;
export const GetCustomerDetailsDocument = gql`
    query GetCustomerDetails($id: ID!) {
  getCustomer(id: $id) {
    id
    name
    email
    phone
    taxDocument
    importedFrom
    gender
    state
    city
    rfmClassification
    averageTicket
    integrationId
    dateOfBirth
    isActive
    tags {
      edges {
        node {
          name
          pk
        }
      }
    }
    notes
    giftbackSet(status: "ACTIVE", orderBy: "due_date") {
      totalCount
      edges {
        node {
          value
          dueDate
          code
          pk
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomerDetailsQuery__
 *
 * To run a query within a React component, call `useGetCustomerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>(GetCustomerDetailsDocument, options);
      }
export function useGetCustomerDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>(GetCustomerDetailsDocument, options);
        }
export function useGetCustomerDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>(GetCustomerDetailsDocument, options);
        }
export type GetCustomerDetailsQueryHookResult = ReturnType<typeof useGetCustomerDetailsQuery>;
export type GetCustomerDetailsLazyQueryHookResult = ReturnType<typeof useGetCustomerDetailsLazyQuery>;
export type GetCustomerDetailsSuspenseQueryHookResult = ReturnType<typeof useGetCustomerDetailsSuspenseQuery>;
export type GetCustomerDetailsQueryResult = Apollo.QueryResult<GetCustomerDetailsQuery, GetCustomerDetailsQueryVariables>;
export const ListCustomerGiftbacksDocument = gql`
    query ListCustomerGiftbacks($customer: ID) {
  allGiftbacksRelay(customer: $customer) {
    edges {
      node {
        pk
        code
        value
        dueDate
        status
        createdAt
        updatedAt
        customeractivitySet {
          edges {
            node {
              activity
              title
              description
              pk
              createdAt
              updatedAt
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCustomerGiftbacksQuery__
 *
 * To run a query within a React component, call `useListCustomerGiftbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerGiftbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerGiftbacksQuery({
 *   variables: {
 *      customer: // value for 'customer'
 *   },
 * });
 */
export function useListCustomerGiftbacksQuery(baseOptions?: Apollo.QueryHookOptions<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>(ListCustomerGiftbacksDocument, options);
      }
export function useListCustomerGiftbacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>(ListCustomerGiftbacksDocument, options);
        }
export function useListCustomerGiftbacksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>(ListCustomerGiftbacksDocument, options);
        }
export type ListCustomerGiftbacksQueryHookResult = ReturnType<typeof useListCustomerGiftbacksQuery>;
export type ListCustomerGiftbacksLazyQueryHookResult = ReturnType<typeof useListCustomerGiftbacksLazyQuery>;
export type ListCustomerGiftbacksSuspenseQueryHookResult = ReturnType<typeof useListCustomerGiftbacksSuspenseQuery>;
export type ListCustomerGiftbacksQueryResult = Apollo.QueryResult<ListCustomerGiftbacksQuery, ListCustomerGiftbacksQueryVariables>;
export const ListCustomerActivitiesDocument = gql`
    query ListCustomerActivities($customer_Id: ID) {
  allCustomerActivitiesRelay(customer_Id: $customer_Id) {
    edges {
      node {
        activity
        pk
        title
        description
        updatedAt
        user {
          pk
          email
        }
        giftback {
          value
          dueDate
          pk
          status
          createdAt
          updatedAt
          customeractivitySet {
            edges {
              node {
                title
                description
                createdAt
              }
            }
          }
        }
        purchase {
          pk
          value
          date
          canceledBy
          canceledAt
          createdAt
          updatedAt
        }
      }
    }
  }
}
    `;

/**
 * __useListCustomerActivitiesQuery__
 *
 * To run a query within a React component, call `useListCustomerActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerActivitiesQuery({
 *   variables: {
 *      customer_Id: // value for 'customer_Id'
 *   },
 * });
 */
export function useListCustomerActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>(ListCustomerActivitiesDocument, options);
      }
export function useListCustomerActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>(ListCustomerActivitiesDocument, options);
        }
export function useListCustomerActivitiesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>(ListCustomerActivitiesDocument, options);
        }
export type ListCustomerActivitiesQueryHookResult = ReturnType<typeof useListCustomerActivitiesQuery>;
export type ListCustomerActivitiesLazyQueryHookResult = ReturnType<typeof useListCustomerActivitiesLazyQuery>;
export type ListCustomerActivitiesSuspenseQueryHookResult = ReturnType<typeof useListCustomerActivitiesSuspenseQuery>;
export type ListCustomerActivitiesQueryResult = Apollo.QueryResult<ListCustomerActivitiesQuery, ListCustomerActivitiesQueryVariables>;
export const ListCustomerDocument = gql`
    query ListCustomer($before: String, $after: String, $first: Int = 12, $last: Int, $nameOrEmail: String, $dateOfBirth_Gte: Date, $dateOfBirth_Lte: Date, $taxDocument: String, $phone_Icontains: String, $rfmClassification_In: String, $purchase_Date_Gte: Date, $purchase_Date_Lte: Date, $giftback_Status: String, $createdAt_Date_Gte: Date, $createdAt_Date_Lte: Date, $importedFrom_In: String, $importedFrom: String, $tags_Name_In: String, $gender_In: String, $notes_Icontains: String, $isActive: Boolean) {
  giftbackAll: allCustomersRelay {
    totalCount
  }
  giftbackActive: allCustomersRelay(giftback_Status: "ACTIVE") {
    totalCount
  }
  giftbackExpired: allCustomersRelay(giftback_Status: "EXPIRED") {
    totalCount
  }
  giftbackRedeemed: allCustomersRelay(giftback_Status: "REDEEMED") {
    totalCount
  }
  giftbackCancelled: allCustomersRelay(giftback_Status: "CANCELLED") {
    totalCount
  }
  giftbackOverdue: allCustomersRelay(giftback_Status: "OVERDUE") {
    totalCount
  }
  allCustomersRelay(
    before: $before
    after: $after
    first: $first
    last: $last
    nameOrEmail: $nameOrEmail
    dateOfBirth_Gte: $dateOfBirth_Gte
    dateOfBirth_Lte: $dateOfBirth_Lte
    taxDocument: $taxDocument
    phone_Icontains: $phone_Icontains
    rfmClassification_In: $rfmClassification_In
    purchase_Date_Gte: $purchase_Date_Gte
    purchase_Date_Lte: $purchase_Date_Lte
    giftback_Status: $giftback_Status
    createdAt_Date_Gte: $createdAt_Date_Gte
    createdAt_Date_Lte: $createdAt_Date_Lte
    importedFrom: $importedFrom
    importedFrom_In: $importedFrom_In
    tags_Name_In: $tags_Name_In
    gender_In: $gender_In
    notes_Icontains: $notes_Icontains
    isActive: $isActive
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        pk
        rfmClassification
        name
        email
        phone
        dateOfBirth
        importedFrom
        giftbackSet(first: 1) {
          edges {
            node {
              pk
              status
            }
          }
        }
        purchaseSet {
          totalCount
        }
        averageTicket
        tags {
          edges {
            node {
              name
              pk
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListCustomerQuery__
 *
 * To run a query within a React component, call `useListCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCustomerQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      nameOrEmail: // value for 'nameOrEmail'
 *      dateOfBirth_Gte: // value for 'dateOfBirth_Gte'
 *      dateOfBirth_Lte: // value for 'dateOfBirth_Lte'
 *      taxDocument: // value for 'taxDocument'
 *      phone_Icontains: // value for 'phone_Icontains'
 *      rfmClassification_In: // value for 'rfmClassification_In'
 *      purchase_Date_Gte: // value for 'purchase_Date_Gte'
 *      purchase_Date_Lte: // value for 'purchase_Date_Lte'
 *      giftback_Status: // value for 'giftback_Status'
 *      createdAt_Date_Gte: // value for 'createdAt_Date_Gte'
 *      createdAt_Date_Lte: // value for 'createdAt_Date_Lte'
 *      importedFrom_In: // value for 'importedFrom_In'
 *      importedFrom: // value for 'importedFrom'
 *      tags_Name_In: // value for 'tags_Name_In'
 *      gender_In: // value for 'gender_In'
 *      notes_Icontains: // value for 'notes_Icontains'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useListCustomerQuery(baseOptions?: Apollo.QueryHookOptions<ListCustomerQuery, ListCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListCustomerQuery, ListCustomerQueryVariables>(ListCustomerDocument, options);
      }
export function useListCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListCustomerQuery, ListCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListCustomerQuery, ListCustomerQueryVariables>(ListCustomerDocument, options);
        }
export function useListCustomerSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListCustomerQuery, ListCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListCustomerQuery, ListCustomerQueryVariables>(ListCustomerDocument, options);
        }
export type ListCustomerQueryHookResult = ReturnType<typeof useListCustomerQuery>;
export type ListCustomerLazyQueryHookResult = ReturnType<typeof useListCustomerLazyQuery>;
export type ListCustomerSuspenseQueryHookResult = ReturnType<typeof useListCustomerSuspenseQuery>;
export type ListCustomerQueryResult = Apollo.QueryResult<ListCustomerQuery, ListCustomerQueryVariables>;
export const SetActiveCustomerDocument = gql`
    mutation SetActiveCustomer($input: SetActiveCustomerInput!) {
  setActiveCustomer(input: $input) {
    ok
  }
}
    `;
export type SetActiveCustomerMutationFn = Apollo.MutationFunction<SetActiveCustomerMutation, SetActiveCustomerMutationVariables>;

/**
 * __useSetActiveCustomerMutation__
 *
 * To run a mutation, you first call `useSetActiveCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveCustomerMutation, { data, loading, error }] = useSetActiveCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetActiveCustomerMutation(baseOptions?: Apollo.MutationHookOptions<SetActiveCustomerMutation, SetActiveCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetActiveCustomerMutation, SetActiveCustomerMutationVariables>(SetActiveCustomerDocument, options);
      }
export type SetActiveCustomerMutationHookResult = ReturnType<typeof useSetActiveCustomerMutation>;
export type SetActiveCustomerMutationResult = Apollo.MutationResult<SetActiveCustomerMutation>;
export type SetActiveCustomerMutationOptions = Apollo.BaseMutationOptions<SetActiveCustomerMutation, SetActiveCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: UpdateCustomerInput!) {
  updateCustomer(input: $input) {
    customer {
      id
      name
      email
      phone
      taxDocument
      gender
      state
      city
      dateOfBirth
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CreateCustomerNewTagsDocument = gql`
    mutation CreateCustomerNewTags($tagInput: CreateCustomerTagsInput!) {
  tagsCreate(tagInput: $tagInput) {
    ok
  }
}
    `;
export type CreateCustomerNewTagsMutationFn = Apollo.MutationFunction<CreateCustomerNewTagsMutation, CreateCustomerNewTagsMutationVariables>;

/**
 * __useCreateCustomerNewTagsMutation__
 *
 * To run a mutation, you first call `useCreateCustomerNewTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerNewTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerNewTagsMutation, { data, loading, error }] = useCreateCustomerNewTagsMutation({
 *   variables: {
 *      tagInput: // value for 'tagInput'
 *   },
 * });
 */
export function useCreateCustomerNewTagsMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerNewTagsMutation, CreateCustomerNewTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerNewTagsMutation, CreateCustomerNewTagsMutationVariables>(CreateCustomerNewTagsDocument, options);
      }
export type CreateCustomerNewTagsMutationHookResult = ReturnType<typeof useCreateCustomerNewTagsMutation>;
export type CreateCustomerNewTagsMutationResult = Apollo.MutationResult<CreateCustomerNewTagsMutation>;
export type CreateCustomerNewTagsMutationOptions = Apollo.BaseMutationOptions<CreateCustomerNewTagsMutation, CreateCustomerNewTagsMutationVariables>;
export const GetAllCustomerTagsDocument = gql`
    query GetAllCustomerTags($name_Icontains: String) {
  customerTagsList(name_Icontains: $name_Icontains) {
    edges {
      node {
        name
        pk
      }
    }
  }
}
    `;

/**
 * __useGetAllCustomerTagsQuery__
 *
 * To run a query within a React component, call `useGetAllCustomerTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCustomerTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCustomerTagsQuery({
 *   variables: {
 *      name_Icontains: // value for 'name_Icontains'
 *   },
 * });
 */
export function useGetAllCustomerTagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>(GetAllCustomerTagsDocument, options);
      }
export function useGetAllCustomerTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>(GetAllCustomerTagsDocument, options);
        }
export function useGetAllCustomerTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>(GetAllCustomerTagsDocument, options);
        }
export type GetAllCustomerTagsQueryHookResult = ReturnType<typeof useGetAllCustomerTagsQuery>;
export type GetAllCustomerTagsLazyQueryHookResult = ReturnType<typeof useGetAllCustomerTagsLazyQuery>;
export type GetAllCustomerTagsSuspenseQueryHookResult = ReturnType<typeof useGetAllCustomerTagsSuspenseQuery>;
export type GetAllCustomerTagsQueryResult = Apollo.QueryResult<GetAllCustomerTagsQuery, GetAllCustomerTagsQueryVariables>;
export const DashboardAgeRangeQueriesDocument = gql`
    query DashboardAgeRangeQueries($input: CustomerFilterSetInput) {
  dashboardAgeColumnChartValues(input: $input) {
    categories
    series {
      name
      data
    }
  }
}
    `;

/**
 * __useDashboardAgeRangeQueriesQuery__
 *
 * To run a query within a React component, call `useDashboardAgeRangeQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAgeRangeQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAgeRangeQueriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardAgeRangeQueriesQuery(baseOptions?: Apollo.QueryHookOptions<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>(DashboardAgeRangeQueriesDocument, options);
      }
export function useDashboardAgeRangeQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>(DashboardAgeRangeQueriesDocument, options);
        }
export function useDashboardAgeRangeQueriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>(DashboardAgeRangeQueriesDocument, options);
        }
export type DashboardAgeRangeQueriesQueryHookResult = ReturnType<typeof useDashboardAgeRangeQueriesQuery>;
export type DashboardAgeRangeQueriesLazyQueryHookResult = ReturnType<typeof useDashboardAgeRangeQueriesLazyQuery>;
export type DashboardAgeRangeQueriesSuspenseQueryHookResult = ReturnType<typeof useDashboardAgeRangeQueriesSuspenseQuery>;
export type DashboardAgeRangeQueriesQueryResult = Apollo.QueryResult<DashboardAgeRangeQueriesQuery, DashboardAgeRangeQueriesQueryVariables>;
export const DashboardCustomerGenderDataDocument = gql`
    query DashboardCustomerGenderData($input: CustomerFilterSetInput) {
  dashboardGenderPieChartValues(input: $input) {
    total
    labels
    series
  }
}
    `;

/**
 * __useDashboardCustomerGenderDataQuery__
 *
 * To run a query within a React component, call `useDashboardCustomerGenderDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCustomerGenderDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCustomerGenderDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCustomerGenderDataQuery(baseOptions?: Apollo.QueryHookOptions<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>(DashboardCustomerGenderDataDocument, options);
      }
export function useDashboardCustomerGenderDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>(DashboardCustomerGenderDataDocument, options);
        }
export function useDashboardCustomerGenderDataSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>(DashboardCustomerGenderDataDocument, options);
        }
export type DashboardCustomerGenderDataQueryHookResult = ReturnType<typeof useDashboardCustomerGenderDataQuery>;
export type DashboardCustomerGenderDataLazyQueryHookResult = ReturnType<typeof useDashboardCustomerGenderDataLazyQuery>;
export type DashboardCustomerGenderDataSuspenseQueryHookResult = ReturnType<typeof useDashboardCustomerGenderDataSuspenseQuery>;
export type DashboardCustomerGenderDataQueryResult = Apollo.QueryResult<DashboardCustomerGenderDataQuery, DashboardCustomerGenderDataQueryVariables>;
export const DashboardCustomerQueriesDocument = gql`
    query DashboardCustomerQueries($input: CustomerFilterSetInput) {
  cardsData: dashboardCustomerCardsValues(input: $input) {
    totalCustomers
    averageValuePerCustomer
    averagePurchaseCountPerCustomer
  }
  rfmData: dashboardRfmChartValues(input: $input) {
    x
    y
  }
}
    `;

/**
 * __useDashboardCustomerQueriesQuery__
 *
 * To run a query within a React component, call `useDashboardCustomerQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCustomerQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCustomerQueriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCustomerQueriesQuery(baseOptions?: Apollo.QueryHookOptions<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>(DashboardCustomerQueriesDocument, options);
      }
export function useDashboardCustomerQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>(DashboardCustomerQueriesDocument, options);
        }
export function useDashboardCustomerQueriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>(DashboardCustomerQueriesDocument, options);
        }
export type DashboardCustomerQueriesQueryHookResult = ReturnType<typeof useDashboardCustomerQueriesQuery>;
export type DashboardCustomerQueriesLazyQueryHookResult = ReturnType<typeof useDashboardCustomerQueriesLazyQuery>;
export type DashboardCustomerQueriesSuspenseQueryHookResult = ReturnType<typeof useDashboardCustomerQueriesSuspenseQuery>;
export type DashboardCustomerQueriesQueryResult = Apollo.QueryResult<DashboardCustomerQueriesQuery, DashboardCustomerQueriesQueryVariables>;
export const DashboardCustomerTagsDocument = gql`
    query DashboardCustomerTags($input: CustomerFilterSetInput) {
  dashboardCustomerTagsValues(input: $input) {
    series
    labels
  }
}
    `;

/**
 * __useDashboardCustomerTagsQuery__
 *
 * To run a query within a React component, call `useDashboardCustomerTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCustomerTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCustomerTagsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCustomerTagsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>(DashboardCustomerTagsDocument, options);
      }
export function useDashboardCustomerTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>(DashboardCustomerTagsDocument, options);
        }
export function useDashboardCustomerTagsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>(DashboardCustomerTagsDocument, options);
        }
export type DashboardCustomerTagsQueryHookResult = ReturnType<typeof useDashboardCustomerTagsQuery>;
export type DashboardCustomerTagsLazyQueryHookResult = ReturnType<typeof useDashboardCustomerTagsLazyQuery>;
export type DashboardCustomerTagsSuspenseQueryHookResult = ReturnType<typeof useDashboardCustomerTagsSuspenseQuery>;
export type DashboardCustomerTagsQueryResult = Apollo.QueryResult<DashboardCustomerTagsQuery, DashboardCustomerTagsQueryVariables>;
export const DashboardCustomersByCityChartsDocument = gql`
    query DashboardCustomersByCityCharts($input: CustomerFilterSetInput) {
  dashboardCustomersByCity(input: $input) {
    total
    categories
    series
  }
}
    `;

/**
 * __useDashboardCustomersByCityChartsQuery__
 *
 * To run a query within a React component, call `useDashboardCustomersByCityChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCustomersByCityChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCustomersByCityChartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCustomersByCityChartsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>(DashboardCustomersByCityChartsDocument, options);
      }
export function useDashboardCustomersByCityChartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>(DashboardCustomersByCityChartsDocument, options);
        }
export function useDashboardCustomersByCityChartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>(DashboardCustomersByCityChartsDocument, options);
        }
export type DashboardCustomersByCityChartsQueryHookResult = ReturnType<typeof useDashboardCustomersByCityChartsQuery>;
export type DashboardCustomersByCityChartsLazyQueryHookResult = ReturnType<typeof useDashboardCustomersByCityChartsLazyQuery>;
export type DashboardCustomersByCityChartsSuspenseQueryHookResult = ReturnType<typeof useDashboardCustomersByCityChartsSuspenseQuery>;
export type DashboardCustomersByCityChartsQueryResult = Apollo.QueryResult<DashboardCustomersByCityChartsQuery, DashboardCustomersByCityChartsQueryVariables>;
export const DashboardCustomersByUfChartsDocument = gql`
    query DashboardCustomersByUFCharts($input: CustomerFilterSetInput) {
  dashboardUfBarChartValues(input: $input) {
    total
    categories
    series
  }
}
    `;

/**
 * __useDashboardCustomersByUfChartsQuery__
 *
 * To run a query within a React component, call `useDashboardCustomersByUfChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardCustomersByUfChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardCustomersByUfChartsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardCustomersByUfChartsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>(DashboardCustomersByUfChartsDocument, options);
      }
export function useDashboardCustomersByUfChartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>(DashboardCustomersByUfChartsDocument, options);
        }
export function useDashboardCustomersByUfChartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>(DashboardCustomersByUfChartsDocument, options);
        }
export type DashboardCustomersByUfChartsQueryHookResult = ReturnType<typeof useDashboardCustomersByUfChartsQuery>;
export type DashboardCustomersByUfChartsLazyQueryHookResult = ReturnType<typeof useDashboardCustomersByUfChartsLazyQuery>;
export type DashboardCustomersByUfChartsSuspenseQueryHookResult = ReturnType<typeof useDashboardCustomersByUfChartsSuspenseQuery>;
export type DashboardCustomersByUfChartsQueryResult = Apollo.QueryResult<DashboardCustomersByUfChartsQuery, DashboardCustomersByUfChartsQueryVariables>;
export const DashboardGiftbackQueriesDocument = gql`
    query DashboardGiftbackQueries($input: GiftbackFilterSetInput) {
  cardsData: dashboardGiftbackCardsValues(input: $input) {
    activeGiftbacks
    redeemedGiftbacks
    expiredGiftbacks
    giftbackGains
    cancelledGiftbacks
    overdueGiftbacks
  }
  averageTicketData: dashboardGiftbackAverageTicket(input: $input) {
    activeGiftbackAverageTicket
    redeemedGiftbackAverageTicket
    expiredGiftbackAverageTicket
    cancelledGiftbackAverageTicket
    overdueGiftbackAverageTicket
  }
  radialChartData: dashboardGiftbacksRadialBarChartValues(input: $input) {
    series
    labels
    total
  }
  areaChartData: dashboardGiftbackAreaChartValues(input: $input) {
    kindOfDate
    series {
      name
      data {
        value
        date
      }
    }
    categories
  }
}
    `;

/**
 * __useDashboardGiftbackQueriesQuery__
 *
 * To run a query within a React component, call `useDashboardGiftbackQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardGiftbackQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardGiftbackQueriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardGiftbackQueriesQuery(baseOptions?: Apollo.QueryHookOptions<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>(DashboardGiftbackQueriesDocument, options);
      }
export function useDashboardGiftbackQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>(DashboardGiftbackQueriesDocument, options);
        }
export function useDashboardGiftbackQueriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>(DashboardGiftbackQueriesDocument, options);
        }
export type DashboardGiftbackQueriesQueryHookResult = ReturnType<typeof useDashboardGiftbackQueriesQuery>;
export type DashboardGiftbackQueriesLazyQueryHookResult = ReturnType<typeof useDashboardGiftbackQueriesLazyQuery>;
export type DashboardGiftbackQueriesSuspenseQueryHookResult = ReturnType<typeof useDashboardGiftbackQueriesSuspenseQuery>;
export type DashboardGiftbackQueriesQueryResult = Apollo.QueryResult<DashboardGiftbackQueriesQuery, DashboardGiftbackQueriesQueryVariables>;
export const DashboardGiftbackResumeDocument = gql`
    query DashboardGiftbackResume($input: GiftbackFilterSetInput) {
  dashboardGiftbacksRadialBarChartValues(input: $input) {
    series
    labels
    total
  }
}
    `;

/**
 * __useDashboardGiftbackResumeQuery__
 *
 * To run a query within a React component, call `useDashboardGiftbackResumeQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardGiftbackResumeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardGiftbackResumeQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDashboardGiftbackResumeQuery(baseOptions?: Apollo.QueryHookOptions<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>(DashboardGiftbackResumeDocument, options);
      }
export function useDashboardGiftbackResumeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>(DashboardGiftbackResumeDocument, options);
        }
export function useDashboardGiftbackResumeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>(DashboardGiftbackResumeDocument, options);
        }
export type DashboardGiftbackResumeQueryHookResult = ReturnType<typeof useDashboardGiftbackResumeQuery>;
export type DashboardGiftbackResumeLazyQueryHookResult = ReturnType<typeof useDashboardGiftbackResumeLazyQuery>;
export type DashboardGiftbackResumeSuspenseQueryHookResult = ReturnType<typeof useDashboardGiftbackResumeSuspenseQuery>;
export type DashboardGiftbackResumeQueryResult = Apollo.QueryResult<DashboardGiftbackResumeQuery, DashboardGiftbackResumeQueryVariables>;
export const DashboardSalesQueriesDocument = gql`
    query DashboardSalesQueries($input: PurchaseFilterSetInput, $productInput: PurchaseProductFilterSetInput) {
  cardsData: dashboardSalesCardsValues(input: $input) {
    purchasesCount
    totalValue
    purchaseAverageTicket
  }
  periodData: dashboardSalesPeriodColumnChartValues(input: $input) {
    kindOfDate
    series {
      name
      type
      data
    }
    labels
  }
  abcData: dashboardProductsListValues(input: $productInput, classification: "") {
    groups {
      title
      cols
    }
    productsList {
      productName
      productQuantity
      productTotalValue
      productIndividualPercentage
      productAccumulatedPercentage
      productAbcClassification
      productPk
    }
    total
  }
}
    `;

/**
 * __useDashboardSalesQueriesQuery__
 *
 * To run a query within a React component, call `useDashboardSalesQueriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardSalesQueriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardSalesQueriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *      productInput: // value for 'productInput'
 *   },
 * });
 */
export function useDashboardSalesQueriesQuery(baseOptions?: Apollo.QueryHookOptions<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>(DashboardSalesQueriesDocument, options);
      }
export function useDashboardSalesQueriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>(DashboardSalesQueriesDocument, options);
        }
export function useDashboardSalesQueriesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>(DashboardSalesQueriesDocument, options);
        }
export type DashboardSalesQueriesQueryHookResult = ReturnType<typeof useDashboardSalesQueriesQuery>;
export type DashboardSalesQueriesLazyQueryHookResult = ReturnType<typeof useDashboardSalesQueriesLazyQuery>;
export type DashboardSalesQueriesSuspenseQueryHookResult = ReturnType<typeof useDashboardSalesQueriesSuspenseQuery>;
export type DashboardSalesQueriesQueryResult = Apollo.QueryResult<DashboardSalesQueriesQuery, DashboardSalesQueriesQueryVariables>;
export const CancelGiftbackDocument = gql`
    mutation CancelGiftback($giftbackInput: UpdateCancelGiftbackInput!) {
  giftbackCancel(giftbackInput: $giftbackInput) {
    ok
  }
}
    `;
export type CancelGiftbackMutationFn = Apollo.MutationFunction<CancelGiftbackMutation, CancelGiftbackMutationVariables>;

/**
 * __useCancelGiftbackMutation__
 *
 * To run a mutation, you first call `useCancelGiftbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelGiftbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelGiftbackMutation, { data, loading, error }] = useCancelGiftbackMutation({
 *   variables: {
 *      giftbackInput: // value for 'giftbackInput'
 *   },
 * });
 */
export function useCancelGiftbackMutation(baseOptions?: Apollo.MutationHookOptions<CancelGiftbackMutation, CancelGiftbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelGiftbackMutation, CancelGiftbackMutationVariables>(CancelGiftbackDocument, options);
      }
export type CancelGiftbackMutationHookResult = ReturnType<typeof useCancelGiftbackMutation>;
export type CancelGiftbackMutationResult = Apollo.MutationResult<CancelGiftbackMutation>;
export type CancelGiftbackMutationOptions = Apollo.BaseMutationOptions<CancelGiftbackMutation, CancelGiftbackMutationVariables>;
export const CreateGiftbackManualDocument = gql`
    mutation CreateGiftbackManual($giftbackInput: CreateGiftbackInput!) {
  giftbackCreate(giftbackInput: $giftbackInput) {
    ok
    giftback {
      pk
    }
  }
}
    `;
export type CreateGiftbackManualMutationFn = Apollo.MutationFunction<CreateGiftbackManualMutation, CreateGiftbackManualMutationVariables>;

/**
 * __useCreateGiftbackManualMutation__
 *
 * To run a mutation, you first call `useCreateGiftbackManualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGiftbackManualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGiftbackManualMutation, { data, loading, error }] = useCreateGiftbackManualMutation({
 *   variables: {
 *      giftbackInput: // value for 'giftbackInput'
 *   },
 * });
 */
export function useCreateGiftbackManualMutation(baseOptions?: Apollo.MutationHookOptions<CreateGiftbackManualMutation, CreateGiftbackManualMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGiftbackManualMutation, CreateGiftbackManualMutationVariables>(CreateGiftbackManualDocument, options);
      }
export type CreateGiftbackManualMutationHookResult = ReturnType<typeof useCreateGiftbackManualMutation>;
export type CreateGiftbackManualMutationResult = Apollo.MutationResult<CreateGiftbackManualMutation>;
export type CreateGiftbackManualMutationOptions = Apollo.BaseMutationOptions<CreateGiftbackManualMutation, CreateGiftbackManualMutationVariables>;
export const GiftbackExcelReportDocument = gql`
    query GiftbackExcelReport($input: GiftbackFilterSetInput) {
  giftbackExcelReport(input: $input) {
    relatorio
  }
}
    `;

/**
 * __useGiftbackExcelReportQuery__
 *
 * To run a query within a React component, call `useGiftbackExcelReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGiftbackExcelReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGiftbackExcelReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiftbackExcelReportQuery(baseOptions?: Apollo.QueryHookOptions<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>(GiftbackExcelReportDocument, options);
      }
export function useGiftbackExcelReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>(GiftbackExcelReportDocument, options);
        }
export function useGiftbackExcelReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>(GiftbackExcelReportDocument, options);
        }
export type GiftbackExcelReportQueryHookResult = ReturnType<typeof useGiftbackExcelReportQuery>;
export type GiftbackExcelReportLazyQueryHookResult = ReturnType<typeof useGiftbackExcelReportLazyQuery>;
export type GiftbackExcelReportSuspenseQueryHookResult = ReturnType<typeof useGiftbackExcelReportSuspenseQuery>;
export type GiftbackExcelReportQueryResult = Apollo.QueryResult<GiftbackExcelReportQuery, GiftbackExcelReportQueryVariables>;
export const GetGiftbackDocument = gql`
    query GetGiftback($id: ID!) {
  giftback: getGiftback(id: $id) {
    code
    value
    minValueToRedeem
    dueDate
    id
    pk
    status
    redeemedAt
    importedFrom
    justification
    redeemedBy {
      pk
      name
      email
    }
    customer {
      pk
      name
      email
      phone
    }
    purchaseUsed {
      pk
      date
      value
    }
    purchase {
      pk
      date
      value
    }
  }
  historic: allCustomerActivitiesRelay(giftback_Id: $id) {
    edges {
      node {
        title
        description
        updatedAt
        pk
      }
    }
  }
}
    `;

/**
 * __useGetGiftbackQuery__
 *
 * To run a query within a React component, call `useGetGiftbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftbackQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGiftbackQuery(baseOptions: Apollo.QueryHookOptions<GetGiftbackQuery, GetGiftbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftbackQuery, GetGiftbackQueryVariables>(GetGiftbackDocument, options);
      }
export function useGetGiftbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftbackQuery, GetGiftbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftbackQuery, GetGiftbackQueryVariables>(GetGiftbackDocument, options);
        }
export function useGetGiftbackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetGiftbackQuery, GetGiftbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetGiftbackQuery, GetGiftbackQueryVariables>(GetGiftbackDocument, options);
        }
export type GetGiftbackQueryHookResult = ReturnType<typeof useGetGiftbackQuery>;
export type GetGiftbackLazyQueryHookResult = ReturnType<typeof useGetGiftbackLazyQuery>;
export type GetGiftbackSuspenseQueryHookResult = ReturnType<typeof useGetGiftbackSuspenseQuery>;
export type GetGiftbackQueryResult = Apollo.QueryResult<GetGiftbackQuery, GetGiftbackQueryVariables>;
export const ListGiftbacksDocument = gql`
    query ListGiftbacks($before: String, $after: String, $first: Int = 12, $last: Int, $code: String, $value_Gte: Float, $value_Lte: Float, $dueDate_Gte: Date, $dueDate_Lte: Date, $redeemedAt: Date, $redeemedAt_Lte: Date, $redeemedAt_Gte: Date, $purchase: ID, $customer: ID, $status: String, $customer_Name_Icontains: String, $nameOrEmailCustomer: String, $importedFrom: String, $importedFrom_In: String) {
  giftbackAll: allGiftbacksRelay {
    totalCount
  }
  giftbackActive: allGiftbacksRelay(status: "ACTIVE") {
    totalCount
  }
  giftbackExpired: allGiftbacksRelay(status: "EXPIRED") {
    totalCount
  }
  giftbackRedeemed: allGiftbacksRelay(status: "REDEEMED") {
    totalCount
  }
  giftbackCancelled: allGiftbacksRelay(status: "CANCELLED") {
    totalCount
  }
  giftbackOverdue: allGiftbacksRelay(status: "OVERDUE") {
    totalCount
  }
  allGiftbacksRelay(
    before: $before
    after: $after
    first: $first
    last: $last
    code: $code
    value_Gte: $value_Gte
    value_Lte: $value_Lte
    dueDate_Gte: $dueDate_Gte
    dueDate_Lte: $dueDate_Lte
    redeemedAt_Lte: $redeemedAt_Lte
    redeemedAt_Gte: $redeemedAt_Gte
    redeemedAt: $redeemedAt
    purchase: $purchase
    customer: $customer
    status: $status
    customer_Name_Icontains: $customer_Name_Icontains
    nameOrEmailCustomer: $nameOrEmailCustomer
    importedFrom: $importedFrom
    importedFrom_In: $importedFrom_In
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        pk
        code
        customer {
          name
          email
        }
        status
        value
        dueDate
        minValueToRedeem
        importedFrom
        purchase {
          pk
        }
        customeractivitySet {
          edges {
            node {
              activity
              title
              description
              pk
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListGiftbacksQuery__
 *
 * To run a query within a React component, call `useListGiftbacksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListGiftbacksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListGiftbacksQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      code: // value for 'code'
 *      value_Gte: // value for 'value_Gte'
 *      value_Lte: // value for 'value_Lte'
 *      dueDate_Gte: // value for 'dueDate_Gte'
 *      dueDate_Lte: // value for 'dueDate_Lte'
 *      redeemedAt: // value for 'redeemedAt'
 *      redeemedAt_Lte: // value for 'redeemedAt_Lte'
 *      redeemedAt_Gte: // value for 'redeemedAt_Gte'
 *      purchase: // value for 'purchase'
 *      customer: // value for 'customer'
 *      status: // value for 'status'
 *      customer_Name_Icontains: // value for 'customer_Name_Icontains'
 *      nameOrEmailCustomer: // value for 'nameOrEmailCustomer'
 *      importedFrom: // value for 'importedFrom'
 *      importedFrom_In: // value for 'importedFrom_In'
 *   },
 * });
 */
export function useListGiftbacksQuery(baseOptions?: Apollo.QueryHookOptions<ListGiftbacksQuery, ListGiftbacksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListGiftbacksQuery, ListGiftbacksQueryVariables>(ListGiftbacksDocument, options);
      }
export function useListGiftbacksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListGiftbacksQuery, ListGiftbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListGiftbacksQuery, ListGiftbacksQueryVariables>(ListGiftbacksDocument, options);
        }
export function useListGiftbacksSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListGiftbacksQuery, ListGiftbacksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListGiftbacksQuery, ListGiftbacksQueryVariables>(ListGiftbacksDocument, options);
        }
export type ListGiftbacksQueryHookResult = ReturnType<typeof useListGiftbacksQuery>;
export type ListGiftbacksLazyQueryHookResult = ReturnType<typeof useListGiftbacksLazyQuery>;
export type ListGiftbacksSuspenseQueryHookResult = ReturnType<typeof useListGiftbacksSuspenseQuery>;
export type ListGiftbacksQueryResult = Apollo.QueryResult<ListGiftbacksQuery, ListGiftbacksQueryVariables>;
export const RedeemGiftbackDocument = gql`
    mutation RedeemGiftback($giftbackInput: UpdateRedeemGiftbackInput!) {
  giftbackRedeem(giftbackInput: $giftbackInput) {
    ok
    giftback {
      redeemedAt
    }
  }
}
    `;
export type RedeemGiftbackMutationFn = Apollo.MutationFunction<RedeemGiftbackMutation, RedeemGiftbackMutationVariables>;

/**
 * __useRedeemGiftbackMutation__
 *
 * To run a mutation, you first call `useRedeemGiftbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRedeemGiftbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [redeemGiftbackMutation, { data, loading, error }] = useRedeemGiftbackMutation({
 *   variables: {
 *      giftbackInput: // value for 'giftbackInput'
 *   },
 * });
 */
export function useRedeemGiftbackMutation(baseOptions?: Apollo.MutationHookOptions<RedeemGiftbackMutation, RedeemGiftbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RedeemGiftbackMutation, RedeemGiftbackMutationVariables>(RedeemGiftbackDocument, options);
      }
export type RedeemGiftbackMutationHookResult = ReturnType<typeof useRedeemGiftbackMutation>;
export type RedeemGiftbackMutationResult = Apollo.MutationResult<RedeemGiftbackMutation>;
export type RedeemGiftbackMutationOptions = Apollo.BaseMutationOptions<RedeemGiftbackMutation, RedeemGiftbackMutationVariables>;
export const GetHomeCardsValuesDocument = gql`
    query GetHomeCardsValues {
  homepageCardsValues {
    totalCustomers
    totalPurchase
    totalActiveGiftbacks
    purchaseAverageTicket
    totalValuePurchase
    totalValueRedeemedGiftbacks
  }
}
    `;

/**
 * __useGetHomeCardsValuesQuery__
 *
 * To run a query within a React component, call `useGetHomeCardsValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeCardsValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeCardsValuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHomeCardsValuesQuery(baseOptions?: Apollo.QueryHookOptions<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>(GetHomeCardsValuesDocument, options);
      }
export function useGetHomeCardsValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>(GetHomeCardsValuesDocument, options);
        }
export function useGetHomeCardsValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>(GetHomeCardsValuesDocument, options);
        }
export type GetHomeCardsValuesQueryHookResult = ReturnType<typeof useGetHomeCardsValuesQuery>;
export type GetHomeCardsValuesLazyQueryHookResult = ReturnType<typeof useGetHomeCardsValuesLazyQuery>;
export type GetHomeCardsValuesSuspenseQueryHookResult = ReturnType<typeof useGetHomeCardsValuesSuspenseQuery>;
export type GetHomeCardsValuesQueryResult = Apollo.QueryResult<GetHomeCardsValuesQuery, GetHomeCardsValuesQueryVariables>;
export const HomepageTopCustomersDocument = gql`
    query HomepageTopCustomers {
  homepageTopCustomers {
    customerName
    customerGender
    purchaseTotalQuantity
    purchaseTotalValue
    customerPk
  }
}
    `;

/**
 * __useHomepageTopCustomersQuery__
 *
 * To run a query within a React component, call `useHomepageTopCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageTopCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageTopCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageTopCustomersQuery(baseOptions?: Apollo.QueryHookOptions<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>(HomepageTopCustomersDocument, options);
      }
export function useHomepageTopCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>(HomepageTopCustomersDocument, options);
        }
export function useHomepageTopCustomersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>(HomepageTopCustomersDocument, options);
        }
export type HomepageTopCustomersQueryHookResult = ReturnType<typeof useHomepageTopCustomersQuery>;
export type HomepageTopCustomersLazyQueryHookResult = ReturnType<typeof useHomepageTopCustomersLazyQuery>;
export type HomepageTopCustomersSuspenseQueryHookResult = ReturnType<typeof useHomepageTopCustomersSuspenseQuery>;
export type HomepageTopCustomersQueryResult = Apollo.QueryResult<HomepageTopCustomersQuery, HomepageTopCustomersQueryVariables>;
export const HomepageTopProductsDocument = gql`
    query HomepageTopProducts {
  homepageTopProducts {
    productName
    totalValue
    totalSold
    productPk
  }
}
    `;

/**
 * __useHomepageTopProductsQuery__
 *
 * To run a query within a React component, call `useHomepageTopProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageTopProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageTopProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomepageTopProductsQuery(baseOptions?: Apollo.QueryHookOptions<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>(HomepageTopProductsDocument, options);
      }
export function useHomepageTopProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>(HomepageTopProductsDocument, options);
        }
export function useHomepageTopProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>(HomepageTopProductsDocument, options);
        }
export type HomepageTopProductsQueryHookResult = ReturnType<typeof useHomepageTopProductsQuery>;
export type HomepageTopProductsLazyQueryHookResult = ReturnType<typeof useHomepageTopProductsLazyQuery>;
export type HomepageTopProductsSuspenseQueryHookResult = ReturnType<typeof useHomepageTopProductsSuspenseQuery>;
export type HomepageTopProductsQueryResult = Apollo.QueryResult<HomepageTopProductsQuery, HomepageTopProductsQueryVariables>;
export const GetRfmChartValuesDocument = gql`
    query GetRFMChartValues($input: CustomerFilterSetInput) {
  rfmData: dashboardRfmChartValues(input: $input) {
    x
    y
  }
}
    `;

/**
 * __useGetRfmChartValuesQuery__
 *
 * To run a query within a React component, call `useGetRfmChartValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfmChartValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfmChartValuesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRfmChartValuesQuery(baseOptions?: Apollo.QueryHookOptions<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>(GetRfmChartValuesDocument, options);
      }
export function useGetRfmChartValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>(GetRfmChartValuesDocument, options);
        }
export function useGetRfmChartValuesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>(GetRfmChartValuesDocument, options);
        }
export type GetRfmChartValuesQueryHookResult = ReturnType<typeof useGetRfmChartValuesQuery>;
export type GetRfmChartValuesLazyQueryHookResult = ReturnType<typeof useGetRfmChartValuesLazyQuery>;
export type GetRfmChartValuesSuspenseQueryHookResult = ReturnType<typeof useGetRfmChartValuesSuspenseQuery>;
export type GetRfmChartValuesQueryResult = Apollo.QueryResult<GetRfmChartValuesQuery, GetRfmChartValuesQueryVariables>;
export const CreatePurchasePdvDocument = gql`
    mutation CreatePurchasePDV($purchaseInput: CreatePurchaseInput!) {
  purchaseCreate(purchaseInput: $purchaseInput) {
    success
    error
    purchase {
      pk
    }
  }
}
    `;
export type CreatePurchasePdvMutationFn = Apollo.MutationFunction<CreatePurchasePdvMutation, CreatePurchasePdvMutationVariables>;

/**
 * __useCreatePurchasePdvMutation__
 *
 * To run a mutation, you first call `useCreatePurchasePdvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchasePdvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchasePdvMutation, { data, loading, error }] = useCreatePurchasePdvMutation({
 *   variables: {
 *      purchaseInput: // value for 'purchaseInput'
 *   },
 * });
 */
export function useCreatePurchasePdvMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchasePdvMutation, CreatePurchasePdvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePurchasePdvMutation, CreatePurchasePdvMutationVariables>(CreatePurchasePdvDocument, options);
      }
export type CreatePurchasePdvMutationHookResult = ReturnType<typeof useCreatePurchasePdvMutation>;
export type CreatePurchasePdvMutationResult = Apollo.MutationResult<CreatePurchasePdvMutation>;
export type CreatePurchasePdvMutationOptions = Apollo.BaseMutationOptions<CreatePurchasePdvMutation, CreatePurchasePdvMutationVariables>;
export const GetCustomerStepDocument = gql`
    query GetCustomerStep($phone: String, $phoneOrTaxDocument: String, $taxDocument: String, $id: Float) {
  allCustomersRelay(
    phone: $phone
    phoneOrTaxDocument: $phoneOrTaxDocument
    taxDocument: $taxDocument
    id: $id
  ) {
    edges {
      node {
        pk
        name
        email
        phone
        taxDocument
        gender
        state
        city
        id
        dateOfBirth
        isActive
        tags {
          edges {
            node {
              pk
              name
            }
          }
        }
        giftbackSet(status: "ACTIVE") {
          edges {
            node {
              value
              code
              dueDate
              minValueToRedeem
              status
              store {
                id
                giftbackconfig {
                  minPurchaseValueForRedeem
                  minimumValueType
                  maximumDiscountPercentage
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetCustomerStepQuery__
 *
 * To run a query within a React component, call `useGetCustomerStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerStepQuery({
 *   variables: {
 *      phone: // value for 'phone'
 *      phoneOrTaxDocument: // value for 'phoneOrTaxDocument'
 *      taxDocument: // value for 'taxDocument'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCustomerStepQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomerStepQuery, GetCustomerStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerStepQuery, GetCustomerStepQueryVariables>(GetCustomerStepDocument, options);
      }
export function useGetCustomerStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerStepQuery, GetCustomerStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerStepQuery, GetCustomerStepQueryVariables>(GetCustomerStepDocument, options);
        }
export function useGetCustomerStepSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCustomerStepQuery, GetCustomerStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCustomerStepQuery, GetCustomerStepQueryVariables>(GetCustomerStepDocument, options);
        }
export type GetCustomerStepQueryHookResult = ReturnType<typeof useGetCustomerStepQuery>;
export type GetCustomerStepLazyQueryHookResult = ReturnType<typeof useGetCustomerStepLazyQuery>;
export type GetCustomerStepSuspenseQueryHookResult = ReturnType<typeof useGetCustomerStepSuspenseQuery>;
export type GetCustomerStepQueryResult = Apollo.QueryResult<GetCustomerStepQuery, GetCustomerStepQueryVariables>;
export const ProductCreateDocument = gql`
    mutation ProductCreate($productsInput: [CreateProductBulkInput]) {
  productBulkCreate(productsInput: $productsInput) {
    ok
    error
    product {
      id
      name
    }
  }
}
    `;
export type ProductCreateMutationFn = Apollo.MutationFunction<ProductCreateMutation, ProductCreateMutationVariables>;

/**
 * __useProductCreateMutation__
 *
 * To run a mutation, you first call `useProductCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productCreateMutation, { data, loading, error }] = useProductCreateMutation({
 *   variables: {
 *      productsInput: // value for 'productsInput'
 *   },
 * });
 */
export function useProductCreateMutation(baseOptions?: Apollo.MutationHookOptions<ProductCreateMutation, ProductCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductCreateMutation, ProductCreateMutationVariables>(ProductCreateDocument, options);
      }
export type ProductCreateMutationHookResult = ReturnType<typeof useProductCreateMutation>;
export type ProductCreateMutationResult = Apollo.MutationResult<ProductCreateMutation>;
export type ProductCreateMutationOptions = Apollo.BaseMutationOptions<ProductCreateMutation, ProductCreateMutationVariables>;
export const CategoryListDocument = gql`
    query CategoryList($name_Icontains: String) {
  categoryList(name_Icontains: $name_Icontains) {
    edges {
      node {
        id
        pk
        name
      }
    }
  }
}
    `;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *      name_Icontains: // value for 'name_Icontains'
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
      }
export function useCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
        }
export function useCategoryListSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, options);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListSuspenseQueryHookResult = ReturnType<typeof useCategoryListSuspenseQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const GetAllProductsDocument = gql`
    query GetAllProducts($first: Int = 20, $name_Icontains: String) {
  allProductsRelay(first: $first, name_Icontains: $name_Icontains) {
    edges {
      node {
        pk
        name
        value
      }
    }
  }
}
    `;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      name_Icontains: // value for 'name_Icontains'
 *   },
 * });
 */
export function useGetAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
      }
export function useGetAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
        }
export function useGetAllProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(GetAllProductsDocument, options);
        }
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsSuspenseQueryHookResult = ReturnType<typeof useGetAllProductsSuspenseQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<GetAllProductsQuery, GetAllProductsQueryVariables>;
export const GetProductDocument = gql`
    query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    name
    value
    integrationId
    importedFrom
    categories {
      edges {
        node {
          id
          pk
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export function useGetProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductSuspenseQueryHookResult = ReturnType<typeof useGetProductSuspenseQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetReportProductDocument = gql`
    query GetReportProduct($input: ProductFilterSetInput!) {
  productExcelReport(input: $input) {
    relatorio
    background
  }
}
    `;

/**
 * __useGetReportProductQuery__
 *
 * To run a query within a React component, call `useGetReportProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportProductQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReportProductQuery(baseOptions: Apollo.QueryHookOptions<GetReportProductQuery, GetReportProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportProductQuery, GetReportProductQueryVariables>(GetReportProductDocument, options);
      }
export function useGetReportProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportProductQuery, GetReportProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportProductQuery, GetReportProductQueryVariables>(GetReportProductDocument, options);
        }
export function useGetReportProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetReportProductQuery, GetReportProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetReportProductQuery, GetReportProductQueryVariables>(GetReportProductDocument, options);
        }
export type GetReportProductQueryHookResult = ReturnType<typeof useGetReportProductQuery>;
export type GetReportProductLazyQueryHookResult = ReturnType<typeof useGetReportProductLazyQuery>;
export type GetReportProductSuspenseQueryHookResult = ReturnType<typeof useGetReportProductSuspenseQuery>;
export type GetReportProductQueryResult = Apollo.QueryResult<GetReportProductQuery, GetReportProductQueryVariables>;
export const ListAllProductsDocument = gql`
    query ListAllProducts($before: String, $after: String, $first: Int, $last: Int, $id: Float, $name_Icontains: String, $categoriesNames: String, $importedFrom: String, $importedFrom_In: String) {
  allProductsRelay(
    before: $before
    after: $after
    first: $first
    last: $last
    id: $id
    name_Icontains: $name_Icontains
    categoriesNames: $categoriesNames
    importedFrom: $importedFrom
    importedFrom_In: $importedFrom_In
  ) {
    edgeCount
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        pk
        name
        value
        categories {
          edges {
            node {
              name
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListAllProductsQuery__
 *
 * To run a query within a React component, call `useListAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllProductsQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      id: // value for 'id'
 *      name_Icontains: // value for 'name_Icontains'
 *      categoriesNames: // value for 'categoriesNames'
 *      importedFrom: // value for 'importedFrom'
 *      importedFrom_In: // value for 'importedFrom_In'
 *   },
 * });
 */
export function useListAllProductsQuery(baseOptions?: Apollo.QueryHookOptions<ListAllProductsQuery, ListAllProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllProductsQuery, ListAllProductsQueryVariables>(ListAllProductsDocument, options);
      }
export function useListAllProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllProductsQuery, ListAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllProductsQuery, ListAllProductsQueryVariables>(ListAllProductsDocument, options);
        }
export function useListAllProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAllProductsQuery, ListAllProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAllProductsQuery, ListAllProductsQueryVariables>(ListAllProductsDocument, options);
        }
export type ListAllProductsQueryHookResult = ReturnType<typeof useListAllProductsQuery>;
export type ListAllProductsLazyQueryHookResult = ReturnType<typeof useListAllProductsLazyQuery>;
export type ListAllProductsSuspenseQueryHookResult = ReturnType<typeof useListAllProductsSuspenseQuery>;
export type ListAllProductsQueryResult = Apollo.QueryResult<ListAllProductsQuery, ListAllProductsQueryVariables>;
export const ProductUpdateDocument = gql`
    mutation ProductUpdate($productInput: UpdateProductInput!) {
  productUpdate(productInput: $productInput) {
    ok
    product {
      id
      value
    }
  }
}
    `;
export type ProductUpdateMutationFn = Apollo.MutationFunction<ProductUpdateMutation, ProductUpdateMutationVariables>;

/**
 * __useProductUpdateMutation__
 *
 * To run a mutation, you first call `useProductUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productUpdateMutation, { data, loading, error }] = useProductUpdateMutation({
 *   variables: {
 *      productInput: // value for 'productInput'
 *   },
 * });
 */
export function useProductUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ProductUpdateMutation, ProductUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProductUpdateMutation, ProductUpdateMutationVariables>(ProductUpdateDocument, options);
      }
export type ProductUpdateMutationHookResult = ReturnType<typeof useProductUpdateMutation>;
export type ProductUpdateMutationResult = Apollo.MutationResult<ProductUpdateMutation>;
export type ProductUpdateMutationOptions = Apollo.BaseMutationOptions<ProductUpdateMutation, ProductUpdateMutationVariables>;
export const AcceptTermsMutationDocument = gql`
    mutation AcceptTermsMutation($termsId: [ID]) {
  acceptTerms(termsId: $termsId) {
    ok
  }
}
    `;
export type AcceptTermsMutationMutationFn = Apollo.MutationFunction<AcceptTermsMutationMutation, AcceptTermsMutationMutationVariables>;

/**
 * __useAcceptTermsMutationMutation__
 *
 * To run a mutation, you first call `useAcceptTermsMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTermsMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTermsMutationMutation, { data, loading, error }] = useAcceptTermsMutationMutation({
 *   variables: {
 *      termsId: // value for 'termsId'
 *   },
 * });
 */
export function useAcceptTermsMutationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptTermsMutationMutation, AcceptTermsMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptTermsMutationMutation, AcceptTermsMutationMutationVariables>(AcceptTermsMutationDocument, options);
      }
export type AcceptTermsMutationMutationHookResult = ReturnType<typeof useAcceptTermsMutationMutation>;
export type AcceptTermsMutationMutationResult = Apollo.MutationResult<AcceptTermsMutationMutation>;
export type AcceptTermsMutationMutationOptions = Apollo.BaseMutationOptions<AcceptTermsMutationMutation, AcceptTermsMutationMutationVariables>;
export const GetConnectedStoreChartsConfigDocument = gql`
    query GetConnectedStoreChartsConfig {
  me {
    connectedStore {
      customerSet {
        edgeCount
      }
      chartsconfig {
        tags
        gender
        yearRange
        city
        state
      }
    }
  }
}
    `;

/**
 * __useGetConnectedStoreChartsConfigQuery__
 *
 * To run a query within a React component, call `useGetConnectedStoreChartsConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConnectedStoreChartsConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConnectedStoreChartsConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConnectedStoreChartsConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>(GetConnectedStoreChartsConfigDocument, options);
      }
export function useGetConnectedStoreChartsConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>(GetConnectedStoreChartsConfigDocument, options);
        }
export function useGetConnectedStoreChartsConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>(GetConnectedStoreChartsConfigDocument, options);
        }
export type GetConnectedStoreChartsConfigQueryHookResult = ReturnType<typeof useGetConnectedStoreChartsConfigQuery>;
export type GetConnectedStoreChartsConfigLazyQueryHookResult = ReturnType<typeof useGetConnectedStoreChartsConfigLazyQuery>;
export type GetConnectedStoreChartsConfigSuspenseQueryHookResult = ReturnType<typeof useGetConnectedStoreChartsConfigSuspenseQuery>;
export type GetConnectedStoreChartsConfigQueryResult = Apollo.QueryResult<GetConnectedStoreChartsConfigQuery, GetConnectedStoreChartsConfigQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile {
  me {
    email
    firstName
    lastName
    pk
    name
    gender
    connectedStore {
      id
      name
      plan
      isActive
      currencyType
      profileImage
    }
    permissions {
      id
      store {
        name
        id
        plan
        isActive
        profileImage
      }
      userType
    }
    acceptedTerm
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export function useGetProfileSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileSuspenseQueryHookResult = ReturnType<typeof useGetProfileSuspenseQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const NotAcceptedTermsDocument = gql`
    query NotAcceptedTerms {
  allTerms {
    pk
    termType
    content
  }
}
    `;

/**
 * __useNotAcceptedTermsQuery__
 *
 * To run a query within a React component, call `useNotAcceptedTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotAcceptedTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotAcceptedTermsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotAcceptedTermsQuery(baseOptions?: Apollo.QueryHookOptions<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>(NotAcceptedTermsDocument, options);
      }
export function useNotAcceptedTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>(NotAcceptedTermsDocument, options);
        }
export function useNotAcceptedTermsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>(NotAcceptedTermsDocument, options);
        }
export type NotAcceptedTermsQueryHookResult = ReturnType<typeof useNotAcceptedTermsQuery>;
export type NotAcceptedTermsLazyQueryHookResult = ReturnType<typeof useNotAcceptedTermsLazyQuery>;
export type NotAcceptedTermsSuspenseQueryHookResult = ReturnType<typeof useNotAcceptedTermsSuspenseQuery>;
export type NotAcceptedTermsQueryResult = Apollo.QueryResult<NotAcceptedTermsQuery, NotAcceptedTermsQueryVariables>;
export const UpdateMyConnectedStoreDocument = gql`
    mutation UpdateMyConnectedStore($storeId: ID!) {
  updateUserConnectedStore(storeId: $storeId) {
    ok
  }
}
    `;
export type UpdateMyConnectedStoreMutationFn = Apollo.MutationFunction<UpdateMyConnectedStoreMutation, UpdateMyConnectedStoreMutationVariables>;

/**
 * __useUpdateMyConnectedStoreMutation__
 *
 * To run a mutation, you first call `useUpdateMyConnectedStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyConnectedStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyConnectedStoreMutation, { data, loading, error }] = useUpdateMyConnectedStoreMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *   },
 * });
 */
export function useUpdateMyConnectedStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyConnectedStoreMutation, UpdateMyConnectedStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyConnectedStoreMutation, UpdateMyConnectedStoreMutationVariables>(UpdateMyConnectedStoreDocument, options);
      }
export type UpdateMyConnectedStoreMutationHookResult = ReturnType<typeof useUpdateMyConnectedStoreMutation>;
export type UpdateMyConnectedStoreMutationResult = Apollo.MutationResult<UpdateMyConnectedStoreMutation>;
export type UpdateMyConnectedStoreMutationOptions = Apollo.BaseMutationOptions<UpdateMyConnectedStoreMutation, UpdateMyConnectedStoreMutationVariables>;
export const CancelPurchaseActionDocument = gql`
    mutation CancelPurchaseAction($purchaseInput: CancelPurchaseInput!) {
  cancelPurchase(purchaseInput: $purchaseInput) {
    purchase {
      id
      value
      canceledBy
    }
  }
}
    `;
export type CancelPurchaseActionMutationFn = Apollo.MutationFunction<CancelPurchaseActionMutation, CancelPurchaseActionMutationVariables>;

/**
 * __useCancelPurchaseActionMutation__
 *
 * To run a mutation, you first call `useCancelPurchaseActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPurchaseActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPurchaseActionMutation, { data, loading, error }] = useCancelPurchaseActionMutation({
 *   variables: {
 *      purchaseInput: // value for 'purchaseInput'
 *   },
 * });
 */
export function useCancelPurchaseActionMutation(baseOptions?: Apollo.MutationHookOptions<CancelPurchaseActionMutation, CancelPurchaseActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPurchaseActionMutation, CancelPurchaseActionMutationVariables>(CancelPurchaseActionDocument, options);
      }
export type CancelPurchaseActionMutationHookResult = ReturnType<typeof useCancelPurchaseActionMutation>;
export type CancelPurchaseActionMutationResult = Apollo.MutationResult<CancelPurchaseActionMutation>;
export type CancelPurchaseActionMutationOptions = Apollo.BaseMutationOptions<CancelPurchaseActionMutation, CancelPurchaseActionMutationVariables>;
export const GetDetailPurchaseDocument = gql`
    query GetDetailPurchase($id: ID!) {
  getPurchase(id: $id) {
    integrationId
    canceledBy
    canceledAt
    date
    value
    paymentMethod
    importedFrom
    discountType
    discountValue
    sellerName
    justification
    store {
      name
    }
    user {
      name
    }
    pk
    customer {
      pk
      name
      email
      phone
      taxDocument
      state
      city
    }
    purchaseproductSet {
      edges {
        node {
          quantity
          subtotal
          value
          product {
            pk
            name
          }
        }
      }
    }
    giftback {
      pk
      status
      value
      dueDate
      code
    }
    giftbackRedeemed {
      pk
      code
      id
    }
    giftbackRedeemedValue
    credithousepurchase {
      installments
      tax
      pk
    }
  }
}
    `;

/**
 * __useGetDetailPurchaseQuery__
 *
 * To run a query within a React component, call `useGetDetailPurchaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailPurchaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailPurchaseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDetailPurchaseQuery(baseOptions: Apollo.QueryHookOptions<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>(GetDetailPurchaseDocument, options);
      }
export function useGetDetailPurchaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>(GetDetailPurchaseDocument, options);
        }
export function useGetDetailPurchaseSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>(GetDetailPurchaseDocument, options);
        }
export type GetDetailPurchaseQueryHookResult = ReturnType<typeof useGetDetailPurchaseQuery>;
export type GetDetailPurchaseLazyQueryHookResult = ReturnType<typeof useGetDetailPurchaseLazyQuery>;
export type GetDetailPurchaseSuspenseQueryHookResult = ReturnType<typeof useGetDetailPurchaseSuspenseQuery>;
export type GetDetailPurchaseQueryResult = Apollo.QueryResult<GetDetailPurchaseQuery, GetDetailPurchaseQueryVariables>;
export const ListPurchasesPageDocument = gql`
    query ListPurchasesPage($before: String, $after: String, $first: Int = 12, $last: Int, $customer_Phone_Icontains: String, $id: Float, $integrationId_In: String, $usernameOrEmailUser: String, $nameOrEmailCustomer: String, $value_Gte: Float, $value_Lte: Float, $date_Gte: Date, $date_Lte: Date, $importedFrom_In: String, $approvedOrCanceledSales: String, $sellerName_Icontains: String, $createdRedeemedGiftback: String) {
  purchaseAll: allPurchasesRelay {
    totalCount
  }
  purchaseApproved: allPurchasesRelay(approvedOrCanceledSales: "approved") {
    totalCount
  }
  purchaseCanceled: allPurchasesRelay(approvedOrCanceledSales: "canceled") {
    totalCount
  }
  allPurchasesRelay(
    before: $before
    after: $after
    first: $first
    last: $last
    customer_Phone_Icontains: $customer_Phone_Icontains
    id: $id
    integrationId_In: $integrationId_In
    usernameOrEmailUser: $usernameOrEmailUser
    nameOrEmailCustomer: $nameOrEmailCustomer
    value_Gte: $value_Gte
    value_Lte: $value_Lte
    date_Gte: $date_Gte
    date_Lte: $date_Lte
    importedFrom_In: $importedFrom_In
    approvedOrCanceledSales: $approvedOrCanceledSales
    sellerName_Icontains: $sellerName_Icontains
    createdRedeemedGiftback: $createdRedeemedGiftback
  ) {
    edgeCount
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        pk
        value
        date
        canceledBy
        canceledAt
        customer {
          pk
          name
          email
        }
        purchaseproductSet {
          edges {
            node {
              quantity
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListPurchasesPageQuery__
 *
 * To run a query within a React component, call `useListPurchasesPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPurchasesPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPurchasesPageQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      customer_Phone_Icontains: // value for 'customer_Phone_Icontains'
 *      id: // value for 'id'
 *      integrationId_In: // value for 'integrationId_In'
 *      usernameOrEmailUser: // value for 'usernameOrEmailUser'
 *      nameOrEmailCustomer: // value for 'nameOrEmailCustomer'
 *      value_Gte: // value for 'value_Gte'
 *      value_Lte: // value for 'value_Lte'
 *      date_Gte: // value for 'date_Gte'
 *      date_Lte: // value for 'date_Lte'
 *      importedFrom_In: // value for 'importedFrom_In'
 *      approvedOrCanceledSales: // value for 'approvedOrCanceledSales'
 *      sellerName_Icontains: // value for 'sellerName_Icontains'
 *      createdRedeemedGiftback: // value for 'createdRedeemedGiftback'
 *   },
 * });
 */
export function useListPurchasesPageQuery(baseOptions?: Apollo.QueryHookOptions<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>(ListPurchasesPageDocument, options);
      }
export function useListPurchasesPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>(ListPurchasesPageDocument, options);
        }
export function useListPurchasesPageSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>(ListPurchasesPageDocument, options);
        }
export type ListPurchasesPageQueryHookResult = ReturnType<typeof useListPurchasesPageQuery>;
export type ListPurchasesPageLazyQueryHookResult = ReturnType<typeof useListPurchasesPageLazyQuery>;
export type ListPurchasesPageSuspenseQueryHookResult = ReturnType<typeof useListPurchasesPageSuspenseQuery>;
export type ListPurchasesPageQueryResult = Apollo.QueryResult<ListPurchasesPageQuery, ListPurchasesPageQueryVariables>;
export const ListAllPurchasesDocument = gql`
    query ListAllPurchases($customer_Id: ID) {
  allPurchasesRelay(customer_Id: $customer_Id) {
    edges {
      node {
        pk
        value
        date
        importedFrom
        canceledBy
        canceledAt
        store {
          name
        }
        customeractivitySet {
          edges {
            node {
              activity
              title
              description
              pk
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListAllPurchasesQuery__
 *
 * To run a query within a React component, call `useListAllPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAllPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAllPurchasesQuery({
 *   variables: {
 *      customer_Id: // value for 'customer_Id'
 *   },
 * });
 */
export function useListAllPurchasesQuery(baseOptions?: Apollo.QueryHookOptions<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>(ListAllPurchasesDocument, options);
      }
export function useListAllPurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>(ListAllPurchasesDocument, options);
        }
export function useListAllPurchasesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>(ListAllPurchasesDocument, options);
        }
export type ListAllPurchasesQueryHookResult = ReturnType<typeof useListAllPurchasesQuery>;
export type ListAllPurchasesLazyQueryHookResult = ReturnType<typeof useListAllPurchasesLazyQuery>;
export type ListAllPurchasesSuspenseQueryHookResult = ReturnType<typeof useListAllPurchasesSuspenseQuery>;
export type ListAllPurchasesQueryResult = Apollo.QueryResult<ListAllPurchasesQuery, ListAllPurchasesQueryVariables>;
export const PurchaseExcelReportDocument = gql`
    query PurchaseExcelReport($input: PurchaseFilterSetInput) {
  purchaseExcelReport(input: $input) {
    relatorio
    background
  }
}
    `;

/**
 * __usePurchaseExcelReportQuery__
 *
 * To run a query within a React component, call `usePurchaseExcelReportQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseExcelReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseExcelReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseExcelReportQuery(baseOptions?: Apollo.QueryHookOptions<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>(PurchaseExcelReportDocument, options);
      }
export function usePurchaseExcelReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>(PurchaseExcelReportDocument, options);
        }
export function usePurchaseExcelReportSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>(PurchaseExcelReportDocument, options);
        }
export type PurchaseExcelReportQueryHookResult = ReturnType<typeof usePurchaseExcelReportQuery>;
export type PurchaseExcelReportLazyQueryHookResult = ReturnType<typeof usePurchaseExcelReportLazyQuery>;
export type PurchaseExcelReportSuspenseQueryHookResult = ReturnType<typeof usePurchaseExcelReportSuspenseQuery>;
export type PurchaseExcelReportQueryResult = Apollo.QueryResult<PurchaseExcelReportQuery, PurchaseExcelReportQueryVariables>;
export const GetStoreDocument = gql`
    query GetStore {
  getStore {
    id
    name
    telephone
    email
    cnpj
    plan
    isActive
    url
    profileImage
    currencyType
  }
}
    `;

/**
 * __useGetStoreQuery__
 *
 * To run a query within a React component, call `useGetStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStoreQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
      }
export function useGetStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
        }
export function useGetStoreSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
        }
export type GetStoreQueryHookResult = ReturnType<typeof useGetStoreQuery>;
export type GetStoreLazyQueryHookResult = ReturnType<typeof useGetStoreLazyQuery>;
export type GetStoreSuspenseQueryHookResult = ReturnType<typeof useGetStoreSuspenseQuery>;
export type GetStoreQueryResult = Apollo.QueryResult<GetStoreQuery, GetStoreQueryVariables>;
export const StoreUpdateDocument = gql`
    mutation StoreUpdate($input: UpdateStoreInput) {
  storeUpdate(input: $input) {
    ok
    store {
      id
      name
      telephone
      email
      cnpj
      plan
      isActive
    }
  }
}
    `;
export type StoreUpdateMutationFn = Apollo.MutationFunction<StoreUpdateMutation, StoreUpdateMutationVariables>;

/**
 * __useStoreUpdateMutation__
 *
 * To run a mutation, you first call `useStoreUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStoreUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [storeUpdateMutation, { data, loading, error }] = useStoreUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStoreUpdateMutation(baseOptions?: Apollo.MutationHookOptions<StoreUpdateMutation, StoreUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StoreUpdateMutation, StoreUpdateMutationVariables>(StoreUpdateDocument, options);
      }
export type StoreUpdateMutationHookResult = ReturnType<typeof useStoreUpdateMutation>;
export type StoreUpdateMutationResult = Apollo.MutationResult<StoreUpdateMutation>;
export type StoreUpdateMutationOptions = Apollo.BaseMutationOptions<StoreUpdateMutation, StoreUpdateMutationVariables>;
export const CreatePurchaseViaTotemDocument = gql`
    mutation CreatePurchaseViaTotem($purchaseInput: CreatePurchaseTotemInput!) {
  purchaseTotemCreate(purchaseInput: $purchaseInput) {
    success
    error
  }
}
    `;
export type CreatePurchaseViaTotemMutationFn = Apollo.MutationFunction<CreatePurchaseViaTotemMutation, CreatePurchaseViaTotemMutationVariables>;

/**
 * __useCreatePurchaseViaTotemMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseViaTotemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseViaTotemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseViaTotemMutation, { data, loading, error }] = useCreatePurchaseViaTotemMutation({
 *   variables: {
 *      purchaseInput: // value for 'purchaseInput'
 *   },
 * });
 */
export function useCreatePurchaseViaTotemMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseViaTotemMutation, CreatePurchaseViaTotemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePurchaseViaTotemMutation, CreatePurchaseViaTotemMutationVariables>(CreatePurchaseViaTotemDocument, options);
      }
export type CreatePurchaseViaTotemMutationHookResult = ReturnType<typeof useCreatePurchaseViaTotemMutation>;
export type CreatePurchaseViaTotemMutationResult = Apollo.MutationResult<CreatePurchaseViaTotemMutation>;
export type CreatePurchaseViaTotemMutationOptions = Apollo.BaseMutationOptions<CreatePurchaseViaTotemMutation, CreatePurchaseViaTotemMutationVariables>;
export const GetCreditHouseGiftbackConfigDocument = gql`
    query GetCreditHouseGiftbackConfig {
  getStore {
    credithouseconfig {
      credithousegiftbackconfigSet {
        id
        pk
        loanMinValue
        loanMaxValue
        taxMinValue
        taxMaxValue
        giftbackTypeValue
        giftbackValue
      }
    }
  }
}
    `;

/**
 * __useGetCreditHouseGiftbackConfigQuery__
 *
 * To run a query within a React component, call `useGetCreditHouseGiftbackConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditHouseGiftbackConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditHouseGiftbackConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCreditHouseGiftbackConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>(GetCreditHouseGiftbackConfigDocument, options);
      }
export function useGetCreditHouseGiftbackConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>(GetCreditHouseGiftbackConfigDocument, options);
        }
export function useGetCreditHouseGiftbackConfigSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>(GetCreditHouseGiftbackConfigDocument, options);
        }
export type GetCreditHouseGiftbackConfigQueryHookResult = ReturnType<typeof useGetCreditHouseGiftbackConfigQuery>;
export type GetCreditHouseGiftbackConfigLazyQueryHookResult = ReturnType<typeof useGetCreditHouseGiftbackConfigLazyQuery>;
export type GetCreditHouseGiftbackConfigSuspenseQueryHookResult = ReturnType<typeof useGetCreditHouseGiftbackConfigSuspenseQuery>;
export type GetCreditHouseGiftbackConfigQueryResult = Apollo.QueryResult<GetCreditHouseGiftbackConfigQuery, GetCreditHouseGiftbackConfigQueryVariables>;
export const GetDetailGiftbackDocument = gql`
    query GetDetailGiftback($input: String!) {
  getGiftbackByCodeCpfEmailPhone(input: $input) {
    pk
    code
    status
    dueDate
    value
    customer {
      id
      pk
    }
    minValueToRedeem
    store {
      giftbackconfig {
        minPurchaseValueForRedeem
        minimumValueType
        maximumDiscountPercentage
      }
    }
  }
}
    `;

/**
 * __useGetDetailGiftbackQuery__
 *
 * To run a query within a React component, call `useGetDetailGiftbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDetailGiftbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDetailGiftbackQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDetailGiftbackQuery(baseOptions: Apollo.QueryHookOptions<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>(GetDetailGiftbackDocument, options);
      }
export function useGetDetailGiftbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>(GetDetailGiftbackDocument, options);
        }
export function useGetDetailGiftbackSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>(GetDetailGiftbackDocument, options);
        }
export type GetDetailGiftbackQueryHookResult = ReturnType<typeof useGetDetailGiftbackQuery>;
export type GetDetailGiftbackLazyQueryHookResult = ReturnType<typeof useGetDetailGiftbackLazyQuery>;
export type GetDetailGiftbackSuspenseQueryHookResult = ReturnType<typeof useGetDetailGiftbackSuspenseQuery>;
export type GetDetailGiftbackQueryResult = Apollo.QueryResult<GetDetailGiftbackQuery, GetDetailGiftbackQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($userInput: CreateUserInput!) {
  createUserCustom(userInput: $userInput) {
    ok
    customUser {
      id
      name
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserCustomDocument = gql`
    mutation UpdateUserCustom($input: UpdateUserInput) {
  updateUserCustom(input: $input) {
    ok
  }
}
    `;
export type UpdateUserCustomMutationFn = Apollo.MutationFunction<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>;

/**
 * __useUpdateUserCustomMutation__
 *
 * To run a mutation, you first call `useUpdateUserCustomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserCustomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserCustomMutation, { data, loading, error }] = useUpdateUserCustomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserCustomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>(UpdateUserCustomDocument, options);
      }
export type UpdateUserCustomMutationHookResult = ReturnType<typeof useUpdateUserCustomMutation>;
export type UpdateUserCustomMutationResult = Apollo.MutationResult<UpdateUserCustomMutation>;
export type UpdateUserCustomMutationOptions = Apollo.BaseMutationOptions<UpdateUserCustomMutation, UpdateUserCustomMutationVariables>;
export const ForgotEmailDocument = gql`
    mutation ForgotEmail($input: SendPasswordResetEmailInput!) {
  sendPasswordResetEmail(input: $input) {
    success
    errors
  }
}
    `;
export type ForgotEmailMutationFn = Apollo.MutationFunction<ForgotEmailMutation, ForgotEmailMutationVariables>;

/**
 * __useForgotEmailMutation__
 *
 * To run a mutation, you first call `useForgotEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotEmailMutation, { data, loading, error }] = useForgotEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotEmailMutation(baseOptions?: Apollo.MutationHookOptions<ForgotEmailMutation, ForgotEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotEmailMutation, ForgotEmailMutationVariables>(ForgotEmailDocument, options);
      }
export type ForgotEmailMutationHookResult = ReturnType<typeof useForgotEmailMutation>;
export type ForgotEmailMutationResult = Apollo.MutationResult<ForgotEmailMutation>;
export type ForgotEmailMutationOptions = Apollo.BaseMutationOptions<ForgotEmailMutation, ForgotEmailMutationVariables>;
export const ListUserDocument = gql`
    query ListUser($before: String, $after: String, $first: Int = 12, $last: Int, $nameOrEmail: String, $isActive: Boolean, $cpf_Icontains: String, $phone_Icontains: String, $permissions_UserType_In: String) {
  usersAll: allUsersRelay {
    totalCount
  }
  userActive: allUsersRelay(isActive: true) {
    totalCount
  }
  userInactive: allUsersRelay(isActive: false) {
    totalCount
  }
  allUsersRelay(
    before: $before
    after: $after
    first: $first
    last: $last
    nameOrEmail: $nameOrEmail
    isActive: $isActive
    cpf_Icontains: $cpf_Icontains
    phone_Icontains: $phone_Icontains
    permissions_UserType_In: $permissions_UserType_In
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        id
        pk
        cpf
        name
        email
        phone
        isActive
        permissionInStore {
          userType
        }
        permissions {
          userType
          store {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useListUserQuery__
 *
 * To run a query within a React component, call `useListUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUserQuery({
 *   variables: {
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      nameOrEmail: // value for 'nameOrEmail'
 *      isActive: // value for 'isActive'
 *      cpf_Icontains: // value for 'cpf_Icontains'
 *      phone_Icontains: // value for 'phone_Icontains'
 *      permissions_UserType_In: // value for 'permissions_UserType_In'
 *   },
 * });
 */
export function useListUserQuery(baseOptions?: Apollo.QueryHookOptions<ListUserQuery, ListUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListUserQuery, ListUserQueryVariables>(ListUserDocument, options);
      }
export function useListUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListUserQuery, ListUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListUserQuery, ListUserQueryVariables>(ListUserDocument, options);
        }
export function useListUserSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListUserQuery, ListUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListUserQuery, ListUserQueryVariables>(ListUserDocument, options);
        }
export type ListUserQueryHookResult = ReturnType<typeof useListUserQuery>;
export type ListUserLazyQueryHookResult = ReturnType<typeof useListUserLazyQuery>;
export type ListUserSuspenseQueryHookResult = ReturnType<typeof useListUserSuspenseQuery>;
export type ListUserQueryResult = Apollo.QueryResult<ListUserQuery, ListUserQueryVariables>;
export const PasswordResetDocument = gql`
    mutation PasswordReset($input: PasswordResetInput!) {
  passwordReset(input: $input) {
    success
    errors
  }
}
    `;
export type PasswordResetMutationFn = Apollo.MutationFunction<PasswordResetMutation, PasswordResetMutationVariables>;

/**
 * __usePasswordResetMutation__
 *
 * To run a mutation, you first call `usePasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordResetMutation, { data, loading, error }] = usePasswordResetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<PasswordResetMutation, PasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument, options);
      }
export type PasswordResetMutationHookResult = ReturnType<typeof usePasswordResetMutation>;
export type PasswordResetMutationResult = Apollo.MutationResult<PasswordResetMutation>;
export type PasswordResetMutationOptions = Apollo.BaseMutationOptions<PasswordResetMutation, PasswordResetMutationVariables>;
export const UpdateUserPasswordDocument = gql`
    mutation UpdateUserPassword($input: RedefinePasswordInput!) {
  redefinePassword(input: $input) {
    ok
  }
}
    `;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(UpdateUserPasswordDocument, options);
      }
export type UpdateUserPasswordMutationHookResult = ReturnType<typeof useUpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($id: ID!) {
  getUser(id: $id) {
    id
    pk
    username
    email
    name
    cpf
    gender
    phone
    birthdate
    isActive
    connectedStore {
      id
    }
    permissions {
      userType
      store {
        id
      }
    }
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export function useGetUserDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsSuspenseQueryHookResult = ReturnType<typeof useGetUserDetailsSuspenseQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: ObtainJSONWebTokenInput!) {
  tokenAuth(input: $input) {
    success
    errors
    unarchiving
    token
    unarchiving
    user {
      id
      username
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;